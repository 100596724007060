/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  useRest.js
Author:    Dean B. Leggo
Version:   0.03
Description:
Hook to communicate with the Omnis server and to update login on stale sessions.

History:
0.03  24-11-22 DBL   ld0011774 don't have data and error both set at the same time
0.02  15-03-22 DBL   Add session token to every call
0.01  02-03-22 DBL   loading was never set to true
0.00	22-02-22 DBL   Created.
----------------------------------- */

import { useState, useCallback } from 'react';
import { requestTimeout, omnisUrl } from '../Configuration/Config';
import useAuthenticate from './useAuthenticate';

const header = { "Accept": "application/json" };

/*
 * Hook to talk to the Omnis Server
 * IN: type - the type of fetch call to make
 * IN: call - the rest path to query
 * OUT: array containing the states for:
 *      fetch(object to transfer) - make the actual fetch to the server,
 *      data    - the object returned from the server,
 *      errors  - string containing any error messages,
 *      loading - boolean to say we are waiting for a respose.
 */
function useRest(type, call) {
  const { customer, onLogout, onActivity } = useAuthenticate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Setup
  if (!(type === "POST" || type === "GET" || type === "PUT" ||
        type === "PDF" || type === "DELETE")
  ) {
    setError("Developer issue: Type not supported.");
    type = null;
  }
  const first_uri = call ? `${omnisUrl}/${call}` : omnisUrl;

  // fetch function
  const summon = useCallback((object) => {
    let isMounted = true; // used to make sure the calling function is still in scope.
    let init = { method: type, headers: header };
    let uri = first_uri;
    setLoading(true);
    setError(null);
    setData(null); // ld0011774 don't have data and error both set at the same time

    if (customer) {// add the session token to every request
      if (object)  
        object.token = customer.token;
      else
        object = {token: customer.token}
    }

    switch (type) {
      case "PDF":
        init = {...init, "Content-Type": "application/pdf", method: "GET" };
        // fall through

      case "GET":
      case "DELETE":
        const keys = Object.keys(object);
        if (keys.length > 0) {
          uri += "?"; // eslint-disable-line
          keys.forEach(key => uri += key + "=" + encodeURIComponent(object[key]) + "&");
          uri = uri.slice(0,-1); // remove the last &
        }
        break;

      default:
        init = {...init, "Content-Type": "application/json", body: JSON.stringify(object) };
    }


    // If the request is a PDF, just return the uri
    if (type === 'PDF') {
      setData(uri);
      setLoading(false);
      return;
    }

    // console.log('fetching', uri, init)
    Promise.race([ // If the query takes too long, send a timeout
      fetch(uri, init),
      new Promise(function(_resolve, reject) {
        setTimeout(() => reject(new Error('Request timed out')), requestTimeout)
      })
    ])
    .then(response => {
      // console.log('useRest response:', response);
      if (response.status < 200 || response.status > 299) { // not a success message
        if (isMounted && response.status === 401 && call !== 'login' && response.statusText === 'Session expired') {
          onLogout('Session expired');
          throw new Error('Session expired');
        }
        else if (response.headers.hasOwnProperty('Omnis-error'))
          throw new Error(response.headers['Omnis-error']);
        else if (response.headers.hasOwnProperty('omnis-error'))
          throw new Error(response.headers['omnis-error']);
        else
          throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(responseData => {
      if (isMounted) {
        onActivity(); // update the customer details with the new lastActive
        setData(responseData); // success - return the data
      }
    })
    .catch(error => {
      if (isMounted) {
        if (error.message) setError(error.message);
        setData(null);
      }
    })
    .finally(() => {
      clearTimeout();
      if (isMounted) setLoading(false);
    });

    // return the 'cleanup' function
    return () => (isMounted = false);
  }, [ type, call, first_uri, customer, onLogout, onActivity ]); // end of summon/fetch useCallback

  return [ summon, data, error, loading ];
}


/*
 * Log the customer in or out
 * token - from customer.token <- useAuthenticate
 * onLogin - from onLogin <- useAuthenticate
 * onLogout - from onLogout <- useAuthenticate
 * note: react does not like recursive methods to useRest
 */
function plainCheck(token, onLogin, onLogout) {
  Promise.race([ // If the query takes too long, send a timeout
    fetch(
      `${omnisUrl}/login?token=${token}`, 
      {"Content-Type": "application/json", method: "GET", headers: header}
    ),
    new Promise(function(_resolve, reject) {
      setTimeout(() => reject(new Error('Request timed out')), requestTimeout / 2)
    })
  ])
  .then(response => {
    if (response.status < 200 || response.status > 299) // not a success message
      throw new Error('Session has expired');
    return response.json();
  })
  .then(responseData => onLogin(responseData))
  .catch(error => onLogout(error.message))
  .finally(() => clearTimeout());
}

export default useRest
export { plainCheck }