/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  DropFreightSchedule.js
Author:    Jordan R. Bock
Version:   0.03
Description:
The Schedule page for the New Drop Freight workflow.

History:
0.03  12-03-24 JRB   Added validation for schduling drop in if today is the booked day.
0.02  08-03-24 JRB   Removed unused parameters from constructor
0.01	18-01-24 JRB   Created.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import CardForm from '../Common/CardForm';
import useAuthenticate from '../Session/useAuthenticate';
import { dateConstruct, displayDelivMsg } from '../Common/utils';

class init {
  constructor () {
    this.readyAt = {value: '', error: null};      // ISO String
    this._readyAtDate = {value: '', error: null}; // YYYY-MM-DD
    this.bookedBy = {value: '', error: null};
    this.scheduleMsg = {value: '', error: null};
  }
}

// Receives the store property from the NewQuote store and the action
function reducer(store, action) {
  // console.log('Schedule Reducer: store, action', store, action);
  let error = null;
  let newValue = action.value;
  let timeMsg = {scheduleMsg: {value: '', error: null}};
  switch (action.name) {
    // case '_readyAtDate':
    case 'readyAt': {
      if (newValue !== '') {
        const date = new Date(newValue) // Allow the date object to validate the string
        date.setHours(0,0,0,0)
        let today = new Date()
        today.setHours(0,0,0,0)
        return {
          _readyAtDate: { value: dateConstruct('date', date), error: null },
          readyAt: {
            value: date.toISOString(),
            error: date < today ? 'Cannot be ready in the past.' : null // Set for a day that is not today.
          }
        }
      }
      else return {} // loading from storage and not set.
    }

    case '_readyAtDate': {
      // newValue is in YYYY-MM-DD
      const dateParts = newValue.split('-', 3)
      if (dateParts.length !== 3) {
        newValue = ''
        error = 'Invalid ready at date'
      } else {
        const year = dateParts[0]
        const month = dateParts[1] - 1
        const day = dateParts[2]
        
        let date = new Date(store.readyAt.value)
        date.setFullYear(year, month, day) // Allow the date object to validate the string
        if (date.valueOf())
          newValue = date.toISOString()
        else {
          newValue = ''
          error = 'Invalid ready at date'
        }
      }

      // Get the target date and current date. Set the time to midnight (because we don't care about time) and then find the numerical seconds since Jan 1 1970 to compare.
      // const date = new Date(new Date(newValue).setHours(0,0,0,0)).getTime();
      let date = new Date(newValue)
      date.setHours(0,0,0,0)
      // const today = new Date(new Date().setHours(0,0,0,0)).getTime();
      let today = new Date()
      today.setHours(0,0,0,0)
      error = date < today ? 'Cannot be ready in the past.' : error // Set for any day before today.
      action.name = 'readyAt'
      // the _readyAtDate field will be correctly populated at the end of the reducer
    } break;
    
    case 'bookedBy':
      if (newValue === '') error = '"Freight booked by" field cannot be empty.';
      break;

    case 'disclaimer':
    case 'scheduleMsg':
      break;

    case 'loadStore':
      return {
        ...(new init()), // blank store
        ...reducer(store, {name: 'scheduleMsg', value: newValue.scheduleMsg}),
        ...reducer(store, {name: 'readyAt', value: newValue.readyAt})
      }

    default: {
      throw new Error(`Schedule Pickup Reducer (${action.name}) is not supported`);
    }
  }

  switch (action.name) {
    case 'readyAt':
      // midnight 14 days from today
      let future = new Date();
      future.setHours(0,0,0,0)
      future.setDate(future.getDate() + 15)
      if (new Date(newValue) > future)
        error = 'Drop Freight cannot be scheduled beyond 2 weeks'
      else {
        timeMsg = displayDelivMsg(newValue, store.freightList.value[0].type.value, error); 
      }
      return {
        readyAt: { value: newValue, error: error },
        _readyAtDate: { value: dateConstruct('date', newValue), error: null },
        ...timeMsg  
      }
    default:
      timeMsg = displayDelivMsg(store.readyAt.value, store.freightList.value[0].type.value, error);
  }
  
  return { [action.name]: { value: newValue, error: error }, ...timeMsg };
}


function DropFreightSchedule({store, dispatcher, controls}) {
  const { loggedIn, customer } = useAuthenticate();
  const [ error, setError ] = useState(null);

  useEffect(() => {
    let error = [];
    ['readyAt','bookedBy','scheduleMsg']
      .forEach(key => {
        if(store[key].error) {
          error.push(store[key].error);
          error.push(<br />);
        }
    });
    if (error.length === 0) error = null;
    setError(error);
  }, [store, dispatcher]);

  useEffect(() => {
    if (loggedIn) {
      dispatcher({page: 'Schedule', name: 'readyAt', value: store.readyAt.value});
      if (store.bookedBy.value === '') 
        dispatcher({page: 'Schedule', name: 'bookedBy', value: customer.name ? customer.name : ''});
    }
  }, [loggedIn]) // eslint-disable-line

  const handleInput = (e) => {
    dispatcher({page: 'Schedule', name: e.target.id, value: e.target.value});
  }

  return (
    loggedIn &&
    <CardForm
      title='Schedule Your Freight Drop In'
      className="workflow"
      controls={{ ...controls, next: {...controls.next, enable: (error) ? false : true} }}
      store={store}
      error={error}
      warning={store.scheduleMsg.value}
    >
      <section className='stack'>
        <div className="label-input__align">
          <div className='label-input__align'>
            <label htmlFor='yes'>Drop in date</label>
          </div>
            {<div className='cluster' style={{width:'100%'}}>
              {/* The datetime-local input type is not supported very well */}
              <input
                type="date"
                id='_readyAtDate'
                onChange={handleInput}
                placeholder='YYYY-MM-DD'
                value={store._readyAtDate.value}
                className = {store.readyAt.error ? 'error-outline ' : ''}
                style={{width:'min(100%, 17rem)'}}
                required
              />
            </div>}
        </div>
        <div className="stack">
          <label htmlFor='bookedBy'>Freight Booked By: </label>
          <input type="text" id='bookedBy' onChange={handleInput} value={store.bookedBy.value} maxLength='20' required />
        </div>
      </section>
    </CardForm>
  )
}


export default DropFreightSchedule;
export { init, reducer };