/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageSummary.js
Author:    Jordan R. Bock
Version:   0.16
Description:
Summarise the information input for the quote for the user to accept or edit.

History:
0.16  29-08-24 JRB   (ld0012951) Added validation import. When navigating to AcceptQuote.js, remove all freight IDs.
0.15  17-07-24 JRB   (ld0012843) Added Print Label Button when a consignment is linked.
0.14  21-06-23 DBL   (ld0012111) If you cannot edit the quote disable the stepper
0.13  15-06-23 DBL   (ld0011936) Do not display the price if they don't have permission
0.12  21-03-23 DBL   (ld0011930) Display the customer reference
0.11  13-09-22 JRB   Changed sender and receiver to being a concat of the first 3 sections of the related store string.
0.10  14-07-22 JRB   Added connote and pickup to the store and added check for accepted, consigned or pickup exists.
0.09  03-05-22 JRB   Added store to the accept quote call
0.08  26-04-22 DBL   Moved the freight summary in to FreightCards
0.07  04-04-22 JRB   Added edit navigation
0.06  30-03-22 JRB   Special instructions only show header when have, added QTY replaced total calulations with total field in store.
0.05  30-03-22 JRB   Changed box to correct Div
0.04  28-03-22 JRB   Added AcceptQuote
0.03  24-03-22 JRB   
0.02	23-03-22 JRB   Added data displaying.
0.01	22-03-22 JRB   Made page structure.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import useAuthenticate from '../Session/useAuthenticate';
import AcceptQuote from './AcceptQuote';
import CardForm from '../Common/CardForm';
import { FreightSummary } from '../Common/FreightCards';
import { legal } from '../Configuration/Config';
import useNavigate from '../Navigation/useNavigate';
import useRest from '../Session/useRest';
import { useNavigate as useRouterNavigate } from 'react-router-dom';
import { freightCardValidation as validation } from "../Configuration/Config";

class init {
  constructor () {
    this.accepted = {value: false, error: null};
    this.connote = {value: '', error: null};
    this.pickup = {value: '', error: null};
  }
}


function reducer(store, action) {
  //console.log('Summary Reducer: store, action'); console.log(store); console.log(action);
  let error = null;
  let newValue = action.value;

  // validate the action
  switch (action.name) {
    case 'accepted':
      break; //No validation required
    case 'connote':
      break;
    case 'pickup':
      break;
    case 'loadStore':
      return {
        ...reducer(store, {name: 'accepted', value: newValue.accepted}),
        ...reducer(store, {name: 'connote', value: newValue.connote}),
        ...reducer(store, {name: 'pickup', value: newValue.pickup})
      }

    default:
      throw new Error(`Summary Reducer (${action.name}) is not supported`);
  };

  return { [action.name]: {value: newValue, error: error} };
}


function PageSummary({store, controls, dispatcher, existingQuote, options}) {
  const { loggedIn, customer } = useAuthenticate();
  const [ changePage, setChangePage ] = useState(false);
  const [ error, setError ] = useState(null);
  const { navState, changeState } = useNavigate();
  const [ fetch, data, fetchError, loading ] = useRest('POST', 'quote')
  const navigate = useRouterNavigate();

  const canEdit = store && loggedIn && store.accepted.value === false &&    // can edit if, not yet accepted and
    (customer.permissions.Quotations.edit === true ||                           // allowed to edit or
      (existingQuote === false && customer.permissions.Quotations.add === true) // currently creating a quote
    );

  // If the user cannot edit the quote disable the navigation
  useEffect(() => {
     changeState(prevState => ({
      ...prevState,
      stepArray: prevState.stepArray.map((item, index) => ({...item, click: canEdit && index + 1 <= prevState.stepCurrent}))
    }))
  }, [canEdit, changeState])
    
  // navigate to AcceptQuote when the quote is accepted on the server
  useEffect(() => {
    if (data) {
      if (data.success === true) {
        dispatcher({page: 'Summary', name: 'accepted', value: true})
        gotoAccept()
      }
      else setError(`Not able to accept the quote: ${data.message}`)
    }
  }, [data]); // eslint-disable-line

  // display any fetch errors
  useEffect(() => { if (fetchError) setError(fetchError) }, [fetchError, setError])

  const next = {
    name: store.accepted.value === true ? 'Next' : (loading ? 'Saving' : 'Accept Quote'),
    enable: store.accepted.value === true || canEdit,
    call: (enabled) => { if (enabled) {
      store.accepted.value === true ?
        gotoAccept() :
        fetch({string: store.quoteNumber.value})
    }}
  };
  
  const doLabel = (enabled) => {
    if (enabled) {
      navigate('/consignments/generatelabel', {state: {conNumber: store.connote.value}})
    }
  }
  const gotoDetails = (pageNum) => {
    if (canEdit) changeState({ ...navState, stepCurrent: pageNum});
  }

  const gotoAccept = () => {
    let freightItemIDs = []
    store.freightList.value.forEach((arrayItem => { // Clear our IDs. Once we have gone past this screen, the quote can no longer be edited so we can be confident we can safely clear the IDs now.
      freightItemIDs.push({oldID: arrayItem.id.value, newID: '0'})
    }))
    // console.log('Freight item ids', freightItemIDs)
    dispatcher({page: 'Details', name: 'freightList', value: { name: 'updateIDs', value: freightItemIDs, validation: validation }}); // Update the ids of our items
    setChangePage(true); // Now load the AcceptQuote page.
  }

  return (<>{changePage
    ? <AcceptQuote quote={store} options={options} />
    : <CardForm title='Summary' className="workflow"
        controls={{
          next: (store.connote.value === '' && store.pickup.value === '')
            ? next
            : (
              store.connote.value === '' 
              ? undefined 
              : {
                ...controls.next,
                name: 'Print Label',
                call: doLabel,
                enable: true
              }
            )
          ,
          previous: canEdit
            ? controls.previous
            : undefined
        }}
        error={error}
      >
        <div className='summary-row'>
          <div className='stack'>
            <div className='cluster cluster-outer'>
              <div>
                <p><span
                  className="h3"
                  style={{fontSize: 'var(--s1)', lineHeight: 'normal'}} /* Match spacing with Edit buttons */
                >Quote Number:</span> {store.quoteNumber.value}</p>
              </div>
              <button
                className='btn__link'
                onClick={() => gotoDetails(1)}
                style={{visibility: canEdit ? 'visible' : 'hidden'}}
              >Edit</button>
            </div>
            <div className='box' style={{blockSize: "100%"}}>
                <h3 style={{marginBlockEnd: "1rem"}}>Location details</h3>
                <p className="h3">Sender Address</p>
                <p>
                  {store.sender.value
                    .split(', ')
                    .filter((_, i) => i < 3)
                    .reduce((p, c, i) => i === 0 ? c : `${p}, ${c}`) /* Remove depot from the address string */
                  }
                </p>
                <br/>
                <p className="h3">Receiver Address</p>
                <p>
                  {store.receiver.value
                    .split(', ')
                    .filter((_, i) => i < 3)
                    .reduce((p, c, i) => i === 0 ? c : `${p}, ${c}`) /* Remove depot from the address string */
                  }
                </p>
                {store.customerRef.value !== null && store.customerRef.value !== ''
                  ? <><br/>
                      <p className='h3'>Customer Reference</p>
                      <p>{store.customerRef.value}</p>
                    </>
                  : undefined}
            </div>
          </div>

          <div className='stack'>
            <div style={{alignSelf: "flex-end"}}>
              <button
                className='btn__link'
                onClick={() => gotoDetails(2)}
                style={{visibility: canEdit ? 'visible' : 'hidden'}}
              >Edit</button>
            </div>
            <div className='box' style={{blockSize: "100%"}}>
              <h3 style={{marginBlockEnd: "1rem"}}>Your quote includes</h3>
              <p>{store.pickupRequired.value > 1 ? 'Pickup' : ''}</p>
              <p>{store.deliveryRequired.value > 1 ? 'Delivery' : ''}</p>
              <p>{store.pickupRequired.value === 3 || store.deliveryRequired.value === 3 ? 'Forklift' : ''}</p>
              <p>{store._dgHas.value === true ? 'Dangerous Goods' : ''}</p>
              {customer.permissions.QuotationFinancials.view === true
                ? <div className='cluster cluster-outer' style={{marginBlockStart: "auto"}}>
                    <p>Total Cost (excl. GST):</p>
                    <p>${Number(store.quotePrice.value)}</p>
                  </div>
                : undefined}
            </div>
          </div>
        </div>

        <FreightSummary store={store.freightList} title={<h3>Freight Details</h3>} edit={canEdit ? () => gotoDetails(1) : null} />
        {store.instructions.value ? <h3 className='accent'>Special Instructions</h3> : ''}
        <p>{store.instructions.value.split(/\r?\n/).flatMap((line, index) => [line, <br key={index} />])}</p>
        <section className='accent stack'>
          {legal.body}{store.accepted.value === false ? legal.quotation : '' }
        </section>
      </CardForm>
    }</>)
}

export default PageSummary;
export { init, reducer };