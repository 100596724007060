/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  PageDG.js
Author:    John D. Kohl, Dean B. Leggo, Aaron Muilne
Version:   0.15
Description:
The Dangerous Goods page for the New Quote workflow.

History:
0.17  09-10-23 JRB   Display warning when quoted.
0.16  29-09-23 JRB   Prevent changes when quoted.
0.15  16-11-22 DBL   Set errors on load
0.14  28-06-22 DBL   Create myControls because it does not correctly set controls on first load.
0.13  14-06-22 JDK   Bug fixes, error handling, clear DG members when selecting no DG
0.12  18-05-22 ADM   Addresses "Tech Name" and "Package Type" wrapping issue, added class lable-bg to DGCards in the appropriate places
0.11  17-05-22 JDK   Completely redesigned DG store in response to Bishop's requests on DG handling
0.10  03-05-22 JDK   Overhauled how the store handles dangerous goods information
0.09  28-04-22 JDK   Added store & reducer for DG to New Pickup workflow.
0.08  27-04-22 JDK   Added warning messages, updated store, bug fixes
0.07  21-04-22 ADM   Updated classes to match new styles.
0.06  13-04-22 DBL
0.05  11-04-22 DBL
0.04  05-04-22 JDK
0.03  04-04-22 JDK
0.02  28-03-22 DBL   
0.01	23-03-22 JDK   Created.
----------------------------------- */

import React, { useEffect, useCallback, useState } from 'react';
import CardForm, { CardButton } from '../Common/CardForm';
import { callError, dgValidation } from '../Configuration/Config'
import useNavigate from '../Navigation/useNavigate';
import DGInfo from './DGInfo';

class init {
  constructor () { 
    this._dgHas = {value: null, error: null};
    this.dgClasses = {value: [], error: null};
  }
}

const images = dgValidation.images;
const err_disallowed_dg_class = 'The selected dangerous goods classes cannot be booked online.';
const err_more_dg_than_freight = 'Number of Dangerous Goods classes cannot exceed number of line items.';


// Receives the store property from the NewQuote store and the action
function reducer(store, action) {
  // console.log('DG Reducer: store, action', store, action);
  let error = null;
  let newValue = null;
  const oldArray = store.dgClasses ? store.dgClasses.value : null;

  // process the action and validate
  switch (action.name) {
    case '_dgHas':
      newValue = action.value;
      if(!newValue)
        return { freightList: { ...store.freightList, value: clearDG(store.freightList.value), error: null }, _dgHas: { value: newValue, error: error} };
      break;

    case 'dgClasses':
      if (action.value === 'clear') {
        newValue = [];
      } else if (action.value === 'verify') {
        newValue = oldArray;
      } else {
        // add or remove the dgClasses from the array
        if (oldArray.includes(action.value)) {
          newValue = oldArray.filter(item => item !== action.value)
        }
        else {
          newValue = oldArray;
          newValue.push(action.value);
        }

        // check all classes are allowed
        newValue.forEach(item => {
          // console.log('validating item ', newValue);
          if (!dgValidation.allowed.includes(item)) error = err_disallowed_dg_class;
        });
      }

      if (error === null && newValue.length > store.freightList.value.length)
        error = err_more_dg_than_freight;
      break;

    case 'loadStore':
      newValue = action.value.dgClasses;
      let newError = (() => {
        let messages = []; // array of messages
        let disallowed = newValue.filter(item => !dgValidation.allowed.includes(item));
        if (disallowed.length)
          messages.push(err_disallowed_dg_class);
        if (action.value.freightList && newValue.length > action.value.freightList.length)
          messages.push(err_more_dg_than_freight);
        return messages.length ? messages : null;
      })();

      // dgClasses: {value: newValue, error: 
      //   newValue.find(item => !dgValidation.allowed.includes(item)) === undefined
      //   ? (action.value.freightList && newValue.length > action.value.freightList.length
      //     ? 'Number of Dangerous Goods classes cannot exceed number of line items.'
      //     : null)
      //   : callError
      // }

        // newArray: {value: inputArray, error: 
        //   inputArray.find(item => !validItems.includes(item)) === undefined
        //   ? (action.value.freightList && inputArray.length > action.value.freightList.length
        //     ? 'Number of Dangerous Goods classes cannot exceed number of line items.'
        //     : null)
        //   : callError
        // }

      return {
        _dgHas: {value: newValue.length > 0 ? true : false, error: null},
        dgClasses: {
          value: newValue,
          error: newError
        }
      }

    default:
      throw new Error(`DG Reducer (${action.name}) is not supported`);
  };

  return { [action.name]: { value: newValue, error: error } };
}


function PageDG({store, dispatcher, controls}) {
  const { navState, changeState } = useNavigate();
  const [ dgNavState, setDGNavState ] = useState('hasDG'); // 'hasDG', 'selectDG', 'infoDG'
  const [ myControls, setMyControls ] = useState(controls);
  const [ error, setError ] = useState(null); // error handling
  const [ warning, setWarning ] = useState(null); // error handling

  useEffect(() => { // error message handling
    // console.log(store.dgClasses.error);
    if (store.dgClasses.error)
      setError([
        store.dgClasses.error, // only one error to display at this level.
        <br/>,
        `Fix the issue, or ${callError}`
      ]);
    else
      setError(null); // clear error
  }, [store.dgClasses.error])

  useEffect(() => { // Warning Message Handling
    if (store.quoteNumber.value !== '') {
      let messages = [];
      if (store._dgHas.value === true) {
        messages.push("Dangerous Goods can’t be amended as it was selected as part of the quoting process.");
      } else {
        messages.push("Dangerous Goods can’t be selected as it wasn’t part of the quoting process.")
      }
      setWarning([
        messages,
        <br/>,
        `To amend, ${callError}`
      ]);
    } else
      setWarning(null); // Do not show warning (There should never be a case to clear.)
  }, [store.quoteNumber.value,store._dgHas.value])

  useEffect(() => {
    if (store.dgClasses.value.length > store.freightList.value.length) {
      dispatcher({page: 'DG', name: 'dgClasses', value: 'verify'})
    }
  }, [store.freightList.value]) // eslint-disable-line

  useEffect(() => {
    const newControls = {...myControls}
    switch (dgNavState) {
      case 'hasDG':
        // always redefine the 'previous' call to the default
        newControls.previous.call = (enable) => {
          if (enable) {
            let newStep = navState.stepCurrent - 1;
            changeState({...navState, stepCurrent: newStep});
          };
        }
        // enable next if no DG, disable and show selection page if has DG.
        if (store._dgHas.value === false) {
          newControls.next.call = (enable) => { enable && changeState({...navState, stepCurrent: (navState.stepCurrent + 1)})};
          newControls.next.enable = true;
        } else {
          newControls.next.enable = false;
          if (store._dgHas.value === true) {
            setDGNavState('selectDG');
          }
        }
        break;
      
      case 'selectDG':
        // in case we change our minds, re-enable next and reset the 'next' call to default
        if (store._dgHas.value === false) {
          setDGNavState('hasDG');
        } else { // but otherwise, continue as normal -> make the next button go to the next state instead.
          newControls.next.call = (enable) => {
            if (enable) {
              setDGNavState('infoDG');
            }
          } // only allow next if everything is valid.
          if (store._dgHas.value === true && store.dgClasses.value.length > 0 && store.dgClasses.error === null) {
            newControls.next.enable = true;
          } else {
            newControls.next.enable = false;
          }
        }
        break;
      
      case 'infoDG': // behold, my final form!! previous takes us to the normal state, next resumes it's default function
        newControls.previous.call = (enable) => {
          if (enable) {
            setDGNavState('hasDG');
          }
        }
        newControls.next.call = (enable) => {
          if (enable) {
            let newStep = navState.stepCurrent + 1;
            changeState({...navState, stepCurrent: newStep});
          }
        }
        break;
      
      default: // we should never hit this point, but in case we do, everything is set to default behaviour (defined explicitly here)
        // despite the optimism above, we hit this point all the time.
        newControls.next.call = (enable) => { enable && changeState({...navState, stepCurrent: (navState.stepCurrent + 1)})};
        newControls.previous.call = (enable) => { enable && changeState({...navState, stepCurrent: (navState.stepCurrent - 1)})};
        newControls.next.enable = false;//store._dgHas.value ? ((store.dgClasses.value.length > 0 && store.dgClasses.error === null && store.freightList.error === null) ? true : false) : true;
        newControls.previous.enable = true;
    }

    setMyControls(newControls)
  }, [store, dgNavState, navState, changeState]) // eslint-disable-line

  return (
    <> {dgNavState === 'infoDG'
      ? <DGInfo store={{ freightList: store['freightList'], dgHas: store['_dgHas'], dgClasses: store['dgClasses'] }} dispatcher={dispatcher} controls={controls} />
      : <DGHas store={store} dispatcher={dispatcher} controls={myControls} error={error} warning={warning}/>
    }</>
  )
}


function DGHas({store,dispatcher,controls,error,warning}) {
  return (
    <CardForm 
      title='Dangerous Goods' 
      className="workflow DGContainerMax" 
      controls={controls} 
      error={error} 
      dispatcher={dispatcher} 
      warning={warning}
    >
      <section className='stack'>
        <p>Does your freight contain dangerous goods?</p>
        <div className="label-input__align">
          <div className='label-input__align'>
            <input type="radio" name="dg-toggle" id="yes"
              checked={store._dgHas.value === true}
              disabled={store.quoteNumber.value !== ''}
              className={store.quoteNumber.value !== '' ? 'disabled' : ''}
              onChange={() => dispatcher({page: 'DG', name: '_dgHas', value: true})}
            />
            <label htmlFor='yes'>Yes</label>
          </div>
          <div className="label-input__align">
            <input type="radio" name="dg-toggle" id="no"
              checked={store._dgHas.value === false}
              disabled={store.quoteNumber.value !== ''}
              className={store.quoteNumber.value !== '' ? 'disabled' : ''}
              onChange={() => {
                dispatcher({page: 'DG', name: '_dgHas', value: false});
                dispatcher({page: 'DG', name: 'dgClasses', value: 'clear'});
              }}
            />
            <label htmlFor='no'>No</label>
          </div>
        </div>
      </section>
      {store._dgHas.value === true && <DGSelect store={store} dispatcher={dispatcher}/>}  
    </CardForm>
  )
}

function DGSelect({store, dispatcher}) {
  const handleChange = useCallback((e) => {
    if (store.quoteNumber.value === '')
    dispatcher({page: 'DG', name: 'dgClasses', value: e.target.name})
  }, [dispatcher]);

  return (
    <section className='stack accent'>
      <p>Please select the types of Dangerous Goods.</p>
        <div className='grid DG'>
          {dgValidation.classes?.map((item, index) => (
            <CardButton
              name={item.name}
              title={
                <div className='stack btn__DG-info'>
                  <h3>{item.title}</h3>
                  <sub>{item.sub}</sub>
                </div>
              }
              icon={<img src={images[item.icon]} alt='' aria-hidden='true' focusable='false'></img>}
              className={(store.dgClasses.value.includes(item.name) && !dgValidation.allowed.includes(item.name))
                ? 'cluster cluster-no-wrap error-outline'
                : 'cluster cluster-no-wrap'}
              disabled={!(store.quoteNumber.value === '' || (store.dgClasses.value.includes(item.name) && store.quoteNumber.value !== ''))}
              onChange={handleChange}
              checked={store.dgClasses.value.includes(item.name)}
              key={`dg${index}`}
            />
          ))}
        </div>
    </section>
  )
}

function clearDG(list) { // initial attempts to fit this on one line/one function call may have been over optimistic
  const myRegex = /^dg/;
  const nullifyEntry = (obj) => Object.getOwnPropertyNames(obj)
    .filter(name => name.match(myRegex))
    .forEach(name => obj[name] = {value: '', error: null});
  let newFreightList = [];

  list.forEach(entry => {
    nullifyEntry(entry);
    newFreightList.push({...entry, error: null});
  });

  return newFreightList;
}

export default PageDG;
export { init, reducer };