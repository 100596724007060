/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageInstructions.js
Author:    Jordan R. Bock
Version:   0.03
Description:
The Special Instructions page for the Drop Freight workflow.

History:
0.03 29-08-24 JRB   Added validation import, Dispatch to freight list to update our freight IDs. Removed unnecessary useEffect() dependancies.
0.02 05-06-24 JRB   Removed force set of empty status to 2. It should be what it is.
0.01 23-01-24 JRB   Copied from New pickup workflow.
----------------------------------- */

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CardForm from '../Common/CardForm';
import { compactStore } from '../Common/utils';
import useRest from '../Session/useRest';
import { freightCardValidation as validation } from "../Configuration/Config";

class init {
  constructor (defaultInstructions) {
    this.instructions = {value: defaultInstructions ? defaultInstructions : '', error: null};
    this.status = {value: 2, error: null};
  }
}

function reducer(store, action) {
  // console.log('Instructions Reducer: store, action'); console.log(store); console.log(action);
  let error = null;
  let newValue = action.value;

  // validate the action
  switch (action.name) {
    case 'instructions':
      if (newValue.length > 850) // 150 characters are reserved from the schedule
        error = "Special instructions are too long"
      break;

    case 'status':
      // if (!newValue) newValue = 2
      break; //No validation required

    case 'loadStore':
      // const consignment = newValue.hasOwnProperty('conNumber')
      //   ? reducer(store, {name: 'conNumber', value: newValue.conNumber})
      //   : { conNumber: {value: '', error: null} };

      const status = newValue.hasOwnProperty('status')
        ? reducer(store, {name: 'status', value: newValue.status})
        : { status: {value: '', error: null} };

      return {
        // ...reducer(store, {name: '_oldStore', value: newValue}),
        ...reducer(store, {name: 'instructions', value: newValue.instructions}),
        // ...consignment,   
        ...status
      }

    default:
      throw new Error(`Instructions Reducer (${action.name}) is not supported`);
  };

  return { [action.name]: {value: newValue, error: error} };
}

function PageInstructions({store, dispatcher, controls}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [ create, createSuccess, createError, createLoading ] = useRest('POST', 'directdropfreight/new');
  const [ update, updateSuccess, updateError, updateLoading ] = useRest('POST', 'directdropfreight/edit');
  const [ error, setError ] = useState(null);

  // when the pickup is saved, move to the next screen
  useEffect(() => {
    if (createSuccess || updateSuccess) {
      const data = createSuccess ? createSuccess : updateSuccess;
      if (data.success) {
        dispatcher({page: 'dfConsignment', name: 'conNumber', value: data.recordNumber});
        // dispatcher({page: 'Review', name: 'scheduleDay', value: data.scheduleDay});
        dispatcher({page: 'Freight', name: 'freightList', value: { name: 'updateIDs', value: data.freightItemIDs, validation: validation }});
        if (createSuccess) dispatcher({page: 'Instructions', name:'status', value: 2});
        if(location.pathname === '/directdropfreight/new') navigate("/directdropfreight/existing");
        controls.next.call(true);
      } else if (!data.success && data.message) {
        setError(data.message);
      }
    }
  }, [createSuccess, updateSuccess]); // eslint-disable-line
  

  // Display any errors on the screen
  useEffect(() => {
    let error = [];
    store.instructions.error && error.push(store.instructions.error);
    createError && error.push(createError);
    updateError && error.push(updateError);
    error.length ? setError(error) : setError(null);
  }, [createError, updateError, store])


  // When next is clicked save the pickup
  const save = (enabled) => {
    if (enabled) {
      if (location.pathname === '/directdropfreight/existing') {
        update(compactStore(store)); // edit;
      } else if (location.pathname === '/directdropfreight/new') {
        create(compactStore(store)); // new
      } else {
        setError('Bad Request'); // User must have edited the path in some way!
      }
    }
  };


  const handleInput = (inputName, classes) => {
    return ({
      name: inputName,
      value: store[inputName].value,
      className: store[inputName].error ? classes + ' error-outline' : classes,
      onChange: (e) => dispatcher({page: 'Instructions', name: e.target.name, value: e.target.value})
    });
  };

  return (
    <CardForm title='Special Instructions' className="workflow" error={error}
      controls={{
        ...controls,
        next: {
          call: save,
          enable: !error && !createLoading && !updateLoading,
          name: createLoading || updateLoading ? 'Saving' : 'Next'
        }
      }}
    >
      <div className="stack">
        <label htmlFor="instructions">Are there any special instructions?</label>
        <textarea {...handleInput('instructions')} />
      </div>
    </CardForm>
  )
}

export default PageInstructions;
export { init, reducer };