/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  useNavigate.js
Author:    Dean B. Leggo
Version:   0.11
Description:
The navigation components and related state, including the header and footer.
 
useNavigate - Grab the global Navigation Context
 The returned object contains the following items:
 navType - name of the current navigation state
 navState - the current navigation state. Object depends on the type of state
 changeType(newType, initialState) - change the current type of navigation
 changeState(type, newState) - change the state of the current navigation type

History:
0.11  01-04-22 PWM   Use HashRouter instead of BrowseRouter
0.01  28-02022 DBL   Moved functions into a useCallback
0.00	24-02-22 DBL   Created.
----------------------------------- */

import React, { useCallback, useContext, useState } from 'react';
import { Route, HashRouter, Routes } from 'react-router-dom';
import Layout from './Layout';

const NavContext = React.createContext();

/* Grab the global Navigation Context
 * The returned object contains the following items:
 * navType - name of the current navigation state
 * navState - the current navigation state. Object depends on the type of state
 * changeType(newType, initialState) - change the current type of navigation
 * changeState(type, newState) - change the state of the current navigation type
 */
function useNavigate() {
  const context = useContext(NavContext);
  if (context === undefined)
    throw new Error('useNavigate must be used within an Navigate Provider');
  return context;
}


function Navigator({children}) {
  const [ navType, setType ] = useState('dashboard');
  const [ navState, setState ] = useState(null);

  const handleType = useCallback((newType, initialState) => {
    if (newType === 'default' ||
        newType === 'dashboard' ||
        newType === 'stepper') {
      setType(newType);
      setState(initialState);
    }
    else {
      throw new Error(`Navigation Type (${newType}) not supported`);
    }
  }, [setType, setState]);

  const handleState = useCallback((newState) => {
    // we should call a valiator for each navType of state
    setState(newState);
  }, [setState]);

  const value = {
    navType,
    navState,
    changeType: handleType,
    changeState: handleState,
  };

  return (
    <NavContext.Provider value={value}>
      <HashRouter>
        <Routes>
          <Route element={<Layout />}>
            {children}
          </Route>
        </Routes>
      </HashRouter>
    </NavContext.Provider>
  );
}

export default useNavigate;
export { Navigator, NavContext };