/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  FreightCards.js
Author:    Dean B. Leggo
Version:   0.15
Description:
All functionality to handle and validate customer input of a number of freight details

History:
0.15  29-08-24 JRB   Set id to '0' and make sure we have a lineItem before assigning to it.
0.14  27-08-24 JDK   Added reducer action "updateIDs" to return the consignment item IDs to the freight list.
0.13  12-07-23 JRB   added this._warn and handling to prevent going over a max number of lines defined in config
0.12  03-03-23 DBL   Changed the dimension labels to say dimension per item
0.11  23-01-23 JDK   Added field "unitWeight" to allow "weight" to refer to total weight for an item. This allows seamless interaction between desktop and portal.
0.10  18-01-23 JDK   Prevent floating point errors in summary display.
0.09  06-01-23 JRB   Set totals to true if we delete a freight item so we can update to correct totals
0.08  22-11-22 DBL   For select use '' and not -1 as the browser counts that as a selected value
0.07  01-11-22 JRB   (ld0011671) Fixed issue where blank option was not correctly set initially.
0.06  31-10-22 JRB   (ld0011671) Selecting type now also sets the forklift property.
0.05  04-07-22 DBL   Moved number validation to utils.
0.04  28-06-22 JRB   Added a check for numbers ending with . as safari triggers number fields on .
0.03  23-06-22 JRB   Added id to constructor
0.02  15-06-22 JRB   Added dgReducer to loadStore in cardReducer
0.01  11-04-22 DBL   Added support to clear the freight type
0.00	22-03-22 DBL   Created.
----------------------------------- */

import React, { useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { freightCardValidation } from '../Configuration/Config';
import * as DG from './DGCards';
import { checkNumber } from './utils';

import Bugsnag from '@bugsnag/js'

class cardInit extends DG.cardInit {
  constructor() {
    super(); // don't add a properity that starts with dg.
    this.key = {value: '', error: null};
    this.quantity = {value: '', error: null};
    this.type = {value: '', error: null};
    this.description = {value: '', error: null};
    this.weight = {value: '', error: null};
    this.unitWeight = {value: '', error: null};
    this.length = {value: '', error: null};
    this.width = {value: '', error: null};
    this.height = {value: '', error: null};
    this.id = {value: '0', error: null}; // Default to 0 so we can correctly handle new items
    this.error = null;
  }
}

class init extends DG.init {
  constructor() {
    super();
    let card =  new cardInit();
    this.value = [card];
    // this._pkForklift = {value: false, error: null}; // client side only
    // this._dlForklift = {value: false, error: null}; // client side only
    this._totalItems = {value: NaN, error: null}; // client side only
    this._totalWeight = {value: NaN, error: null}; // client side only
    this._totalCubicVolume = {value: NaN, error: null}; // client side only
    this.error = null;
    this._warn = null;
  }
}


/*
 * The first reducer for Freight Cards
 * The store contains the array of freight cards
 * Action's page - the index to update
 */
function reducer(store, action) {
  const validation = action.validation;
  const maxItems = freightCardValidation.maxItems;
  // console.log(validation);
  // console.log('FreightCards Reducer: store, action', store, action);

  let totals = false;
  let list = store.value.map(card => ({ ...card })); // clone the entire contents of the array and
                                                     // not just the reference to each object
  let error = []; // not using a string because we want line breaks

  switch (action.name) {
    case 'key':
      return {store}; // do not permit changing key values (sequence numbers)
      
    case 'add':
      if (list.length < maxItems || list.length === 0) 
      {
        list.push(new cardInit());
        if (list.length === maxItems) // If adding this line causes us to have 10 items. Warn the user
          store._warn = `The maximum ${maxItems} line items has been reached.`
        else // Should not be triggered but if we somehow add and are below the number of items then clear the warning.
          store._warn = null
      }
      else {
        store.error = `There are more than ${maxItems} line items`
      }
      break;

    case 'delete':
      list.splice(action.page, 1);
      totals = true;
      if (list.length === 0)
        list.push(new cardInit());
      if (list.length < maxItems)
        store._warn = null
      break;
      
    case 'clearFreight':
      list.forEach((card, index, array) =>
        array[index] = cardReducer(card, {page: index, name: 'type', value: '', validation})
      );
      break;

    case 'id':
      break;
    
    case 'updateIDs': // LD0012951
      try {
        let freightItemIds = action.value; // Array of objects: { oldID, newID }
        freightItemIds && freightItemIds.forEach((idSet => {
          let lineItem = list.find((lineItem) => lineItem.id.value === idSet.oldID);
          if (lineItem && lineItem.id) lineItem.id.value = idSet.newID;
        }));
      } catch (error) {
        Bugsnag.notify(error.message);
      }
      break;

    case 'loadStore':
      list = action.value.map((card, index) =>
        cardReducer(new cardInit(), {page: index, name: 'loadStore', value: card, validation})
      );
      totals = true;
      break;

    case 'dgPackCount': case 'dgPackType': case 'dgTechName': case 'dgClass': case 'dgUNCode':
    case 'dgQty': case 'dgPackGroup': case 'dgSubRisk': case 'dgError': case 'dgDesc': case 'dgClear':
      // dgReducer will only return the dg items
      list[action.page] = {...list[action.page], ...DG.reducer(list[action.page], action)};
      break;

    // case 'pkForklift': 
    //   store._pkForklift.value = action.value;
    //   break;

    // case 'dlForklift':
    //   store._dlForklift.value = action.value;
    //   break;

    case 'type':
      // fall through

    case 'quantity': case 'weight': case 'length': case 'width': case 'height': 
      totals = true;
      // fall through
      
    default: // replace the row with the new actioned object
      list[action.page] = cardReducer(list[action.page], action);
  }
  
  // If any of the totals have changed, update all
  if (totals) {
    store._totalItems.value = list.reduce((total, row) =>
      row.quantity.value === ''
        ? total
        : total + Number(row.quantity.value), 0);
  
    store._totalWeight.value = list.reduce((total, row) =>
      row.weight.value === '' || row.quantity.value === ''
        ? total
        : total + Number(row.weight.value), 0);
    
    store._totalCubicVolume.value = list.reduce((total, row) =>
      row.height.value === '' || row.length.value === '' || row.width.value === '' || row.quantity.value === ''
        ? NaN
        : total + (Number(row.height.value) * Number(row.length.value) *
          Number(row.width.value) * Number(row.quantity.value)), 0);

    store._totalWeight.value = parseFloat(store._totalWeight.value.toFixed(2));
    store._totalCubicVolume.value = parseFloat(store._totalCubicVolume.value.toFixed(2));

    store._totalWeight.error = store._totalWeight.value > validation.maxTotalWeight
      ? `The total weight exceeds ${validation.maxTotalWeight.toLocaleString()} kg` : null;
    store._totalCubicVolume.error = store._totalCubicVolume.value > validation.maxTotalCubicVolume
      ? `The total cubic volume exceeds ${validation.maxTotalCubicVolume.toLocaleString()} m³`
      : (store._totalCubicVolume.value < 0.01 ? 'The total cubic volume must be greater than 0.01 m³' : null);
  }

  // Check if all the freight types match
  if (list.some((row, _, array) =>
    row.type.value !== '' && array[0].type.value !== '' && row.type.value !== array[0].type.value
  )) {
    error.push('All freight types must match');
    error.push(<br />);
  }

  // Bubble up any errors in the totals
  ['_totalItems','_totalWeight','_totalCubicVolume'].forEach(name => {
    if (store[name].error) {
      error.push(store[name].error);
      error.push(<br />);
    }
  });

  // Bubble up any errors from the freight cards into the parents error
  list.forEach((key, index) => {
    if (key.error !== null) {
      error.push(`Item ${index + 1}: ${key.error}`);
      error.push(<br />);
    }
  });
  
  if (error.length === 0) error = null; // clear string if no errors were found
  //else error.push(`Fix the issue or ${callError}`); The parent element should determine this

  // Do any super init processing
  const supers = DG.initProcess(list);

  return { ...store, ...supers, value: list, error: error };
}


/*
 * A Single reducer for each card
 * Receive the array (store property) from the reducer above and the action
 */
function cardReducer(store, action) {
  const validation = action.validation;
  // console.log('FreightCards card Reducer: store, action', store, action);

  let error = null;
  let freightError = '';
  let newValue = action.value;

  switch (action.name) {
    case 'quantity':
      [newValue, error] = checkNumber(newValue, 'Quantity', {lower: 1})
      break;

    case 'type':
      // no validation done at this level
      break;
    
    case 'id':
      break;

    case 'description':
      if (newValue.length > 200)
        error = `Description can not be greater than 200 characters`;
      break;
      
    case 'unitWeight':
      if (newValue) [newValue, error] = checkNumber(newValue, 'Unit weight', {lower: 0.01, upper: validation.maxTotalWeight, digits: 2}, 'kg');
      break;

    case 'weight':
      if (newValue) [newValue, error] = checkNumber(newValue, 'Weight', {lower: 0.01, upper: validation.maxTotalWeight, digits: 2}, 'kg');
      break;

    case 'length':
      if (newValue) [newValue, error] = checkNumber(newValue, 'Length', {lower: 0.01, upper: validation.maxLength, digits: 2}, 'm');
      break;

    case 'width':
      if (newValue) [newValue, error] = checkNumber(newValue, 'Width', {lower: 0.01, upper: validation.maxWidth, digits: 2}, 'm');
      break;

    case 'height':
      if (newValue) [newValue, error] = checkNumber(newValue, 'Height', {lower: 0.01, upper: validation.maxHeight, digits: 2}, 'm');
      break;

    case 'loadStore':
      let unitWeight = newValue.unitWeight
        ? newValue.unitWeight
        : ((newValue.weight && newValue.quantity)
          ? newValue.weight/newValue.quantity
          : ''); // calculate the unit weight - weight per item - as this is no longer the value sent to omnis.
      store = {
        ...cardReducer({}, {page: action.page, name: 'quantity', value: newValue.quantity, validation}),
        ...cardReducer({}, {page: action.page, name: 'type', value: newValue.type, validation}),
        ...cardReducer({}, {page: action.page, name: 'description', value: newValue.description, validation}),
        ...cardReducer({}, {page: action.page, name: 'weight', value: newValue.weight, validation}),
        ...cardReducer({}, {page: action.page, name: 'unitWeight', value: unitWeight, validation}),
        ...cardReducer({}, {page: action.page, name: 'length', value: newValue.length, validation}),
        ...cardReducer({}, {page: action.page, name: 'width', value: newValue.width, validation}),
        ...cardReducer({}, {page: action.page, name: 'height', value: newValue.height, validation}),
        ...cardReducer({}, {page: action.page, name: 'id', value: newValue.id, validation}),
        ...DG.reducer({}, {name: 'loadStore', value: newValue})
      }
      break; // allow the errors to bubble up

    default:
      throw new Error(`Freight Card (${action.name}) is not supported`);
  }

  // Save all values as strings to prevent ugly number entry
  newValue = newValue ? '' + newValue : '';

  // Bubble up any errors from the store into the freightCard, including the actioned error
  Object.keys(store).forEach(key => {
    if (key === action.name) {
      if (error) freightError += error + ', ';
    }
    else if (key !== 'error' && key !== '_dgError' && store[key].error !== null && !key.match(/^dg/))
      freightError += store[key].error + ', ';
  });
  if (freightError === '')
    freightError = null; // clear string if no errors were bubbled
  else
    freightError = freightError.slice(0, -2); // remove the last ', '


  // return the entire row with the updated action
  if (action.name === 'loadStore')
    return { ...store, error: freightError }
  else
    return { ...store, [action.name]: { value: newValue, error: error }, error: freightError }
}


/*
 * A single Freight Card
 */
function FreightCard({freightItem, index, dispatcher, dispatcherAction, options, validation}) {
  // const valid = validation ? validation : freightCardValidation;

  useEffect(() => {
    if(freightItem.quantity && freightItem.unitWeight) dispatcher({ ...dispatcherAction, value: {
      page: index, name: 'weight', value: (freightItem.quantity.value*freightItem.unitWeight.value), validation
    }});
  },[freightItem.quantity,freightItem.unitWeight]) // eslint-disable-line

  const handleChange = useCallback((e) => {
    dispatcher({ ...dispatcherAction, value: {page: index, name: e.target.name, value: e.target.value, validation}})
  }, [dispatcher, dispatcherAction, index, validation]);

  const handleInput = (inputName, classes) => {
    return ({
      id: index + inputName,
      name: inputName,
      value: freightItem[inputName].value,
      className: freightItem[inputName].error ? classes + ' error-outline' : classes,
      onChange: handleChange
    });
  };

  const handleSelect = useCallback((inputName, classes) => {
    if (options) {
      let idx = options.type.findIndex(t => t.freight === freightItem[inputName].value); // Use the index to work out which value to display.
      if (idx === -1) idx = '';
      return ({
        id: index + inputName,
        name: inputName,
        value: idx,
        className: freightItem[inputName].error ? classes + ' error-outline' : classes,
        onChange: e => {
          dispatcher({ ...dispatcherAction, value: {page: index, name: e.target.name, value: options.type[e.target.value].freight, validation}});
          // dispatcher({ ...dispatcherAction, value: {page: index, name: 'pkForklift', value: options.type[e.target.value].pkForklift, validation}});
          // dispatcher({ ...dispatcherAction, value: {page: index, name: 'dlForklift', value: options.type[e.target.value].dlForklift, validation}});
        }
      });
    }
  }, [freightItem, index, dispatcher, dispatcherAction, options, validation])

  const handleDelete = () => dispatcher({...dispatcherAction, value: {page: index, name: 'delete', value: null, validation}});

  return (
    <div id={`freightCard${index}`} className='freightCard stack'>
      <h3>Line Item {index + 1}</h3>
      <div className="cluster cluster-no-wrap">
        <div className="stack">
          <button
            type='button'
            title={`Delete freight item ${index + 1}`}
            className="btn btn__delete"
            onClick={handleDelete}
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <div className="error-msg" style={{visibility: freightItem.error ? '' : 'hidden'}}>
            <FontAwesomeIcon icon={faTriangleExclamation} size="2x" />
          </div>
        </div>
        <div className="grid four-col flex-grow">
          <div className="stack">
            <label htmlFor={index + 'quantity'}><abbr title='Quantity'>No.</abbr> of Items</label>
            <input type='number' step='1' required min={1}
              {...handleInput('quantity')}
            />
          </div>
          <div className="stack">
            <label htmlFor={index + 'type'}>Freight Type</label>
            <select id='type' required
              {...handleSelect('type')}
            >
              <option hidden disabled value=''></option>
              {options && options !== false && options.type.map((item, i) =>
                (<option value={i} key={`R${index}-${i}`}>{item.freight}</option>))
              }
            </select>
          </div>
          <div className="stack" style={{gridColumn: "span 2"}}>
            <label htmlFor={index + 'description'}>Description</label>
            <input type='text' required maxLength={200}
              {...handleInput('description')}
            />
          </div>
          <div className="stack">
            <label htmlFor={index + 'unitWeight'}>Weight/item (kg)</label>
            <input type='number' step='0.01' required min={0.01} max={validation.maxTotalWeight}
              {...handleInput('unitWeight')}
            />
          </div>
          <div className="stack">
            <label htmlFor={index + 'length'}>Length/item (m)</label>
            <input type='number' step='0.01' required min={0.01} max={validation.maxLength}
              {...handleInput('length')}
            />
          </div>
          <div className="stack">
            <label htmlFor={index + 'width'}>Width/item (m)</label>
            <input type='number' step='0.01' required min={0.01} max={validation.maxWidth}
              {...handleInput('width')}
            />
          </div>
          <div className="stack">
            <label htmlFor={index + 'height'}>Height/item (m)</label>
            <input type='number' step='0.01' required min={0.01} max={validation.maxHeight}
              {...handleInput('height')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}


/*
 * The Freight Details section containg the list of freight cards
 * options - is an object with the parameters:
 * type - an array of strings that are the available freight types  
 */
function FreightCards({store, dispatcher, dispatcherAction, title, options, validation}) {
  
  const handleAdd = useCallback(() => {
    dispatcher({ ...dispatcherAction, value: {page: 0, name: 'add', value: null, validation}})
  }, [dispatcher, dispatcherAction, validation]);

  return (
    <section className="freightDetails accent stack">
      {title}
      <div className='stack'>
        <div className="cluster cluster-no-wrap">
          <button
            type='button'
            id='btnAddFreight'
            title='Add a new freight item'
            className={`btn btn__add margin-block-end${store.value.length >= freightCardValidation.maxItems ? ' disabled' : ''}`}
            onClick={handleAdd}
            disabled={store.value.length >= freightCardValidation.maxItems}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
          <div className="stack flex-grow">
            {store.value.map((card, index) => 
              <FreightCard freightItem={card} index={index} dispatcher={dispatcher}
                dispatcherAction={dispatcherAction} options={options} key={`FC${index}`} validation={validation}
              />
            )}
          </div>
        </div>
      </div>
      <div className="stack">
        <div className="cluster cluster-end">
          <p className='h3' htmlFor="totalWeight">Total Number of Items:</p>
          <p id="totalWeight">{isNaN(store._totalItems.value) ? '' : store._totalItems.value}</p>
        </div>
        <div className="cluster cluster-outer">
          <div className="error-msg margin-inline-start-auto"
            style={{visibility: store._totalWeight.error ? '' : 'hidden'}}
          >
            <FontAwesomeIcon icon={faTriangleExclamation} size="2x" />
          </div>
          <div className="cluster cluster-end">
            <p className='h3' htmlFor="totalWeight">Total Weight:</p>
            <p id="totalWeight">{isNaN(store._totalWeight.value) ? '' : `${store._totalWeight.value} kg`}</p>
          </div>
        </div>
        <div className="cluster cluster-outer">
          <div className="error-msg margin-inline-start-auto"
            style={{visibility: store._totalCubicVolume.error ? '' : 'hidden'}}
          >
            <FontAwesomeIcon icon={faTriangleExclamation} size="2x" />
          </div>
          <div className='cluster cluster-end'>
            <p className='h3' htmlFor="totalCubicVolume">Total Cubic Volume:</p>
            <p id="totalCubicVolume">
              {isNaN(store._totalCubicVolume.value) ? '' : `${store._totalCubicVolume.value} m³`}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}


/*
 * The Freight Summary section containing the list of freight items
 * store - the freight list from FreightCards.js
 * title - optional title to display
 * edit - optional callback function for an edit button 
 */
function FreightSummary({store, title, edit}) {
  const cubicSummary = store.value.reduce((total, row) =>
  row.height.value === '' || row.length.value === '' || row.width.value === '' || row.quantity.value === ''
    ? 0
    : total + (Number(row.height.value) * Number(row.length.value) *
      Number(row.width.value) * Number(row.quantity.value)), 0).toFixed(2);

  return (
    <section className='stack accent'>
      <div className='cluster cluster-outer'>    
        {title}
        {edit && <button type='button' className='btn__link' onClick={edit}>Edit</button>}
      </div>
      <table>
        <thead>
          <tr>
            <th>QTY</th>
            <th>Freight Type</th>
            <th>Description</th>
            <th>Weight</th>
            <th>Length</th>
            <th>Width</th>
            <th>Height</th>
          </tr>
        </thead>
        <tbody>
          {store.value.map((freight, index) =>
            <tr key={`FS${index}`}>
              <td>{freight.quantity.value}</td>
              <td>{freight.type.value}</td>
              <td>{freight.description.value}</td>
              <td>{freight.unitWeight.value}</td>
              <td>{freight.length.value}</td>
              <td>{freight.width.value}</td>
              <td>{freight.height.value}</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className='cluster cluster-end'>
        <p>Total <abbr title='number'>No.</abbr> Items:</p>
        <p>{store._totalItems.value.toString()}</p>
      </div>
      <div className='cluster cluster-end'>
        <p>Total Weight:</p>
        <p>{store._totalWeight.value.toString()} kg</p>
      </div>
      <div className='cluster cluster-end'>
        <p>Total Cubic Volume:</p>
        <p>{cubicSummary.toString()} m³</p>
      </div>
    </section>
  );
}

export default FreightCards;
export { init, reducer, FreightSummary };