/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  PageDetails.js
Author:    Dean B. Leggo
Version:   0.21
Description:
The Details page for the New Quote workflow.

History:
0.21  28-08-23 JDK   (LD0012210) Initialise new quotes with customer specific defaults.
0.20  12-07-23 JRB   (ld0012171) Added warning when above maximum line items
0.19  12-05-23 DBL   (ld0012035) Changed to support Multiple destinations
0.18  12-04-23 DBL   (ld0012033) When the From cityState changes it clears the To cityState
0.17  29-03-23 DBL   (ld0012009) Allow the two suburb lists be selected by depotFrom and depotTo
0.16  21-03-23 DBL   (ld0011930) Aded customer reference field
0.15  09-03-23 JRB   (ld0011487) Add warning when we select 2 destinations that don't have a rate.
0.14  03-03-23 JRB   (ld0011487) Added filter for from Depot if we have a value.
0.13  17-01-23 JDK   Changed Select component to WindowedSelect. This provides a responsive experience, only rendering 20 options at a time.
0.12  22-11-22 DBL   Added useEffect and useMemo to reduce redraws
0.11  21-11-22 JRB   Removed an unneeded console log
0.10  15-11-22 DBL   (ld0011715) Run validation on the React Select as it does not respect the 'required' keyword
0.09  31-10-22 JRB   (ld0011671) Added forklift fields
0.08  20-10-22 DBL   LD0011670 Allow intra freight 
0.07  11-10-22 DBL   LD0011669 Allow the reducer to validate the suburbs and depots
0.06  26-05-22 JRB   Placeholder text now determines based on if store has value or not.
0.05  25-05-22 JRB   Changed to store the label of the complex box fields
0.04  22-03-22 DBL   Moved Freight Card to its own file
0.03  15-03-22 ADM   Imported the warning symbol. Added the warning symbol to freightCard. Wrapped freightCard and the Add Item in various nested fl-row/fl-cols to achieve desired layout
0.02  09-03-22 ADM   Added btn btn__add/delete to the add and delete buttons
0.01  08-03-22 ADM   Markup tweaks (restructure + classNames)
0.00	08-03-22 DBL   Created.
----------------------------------- */

import React, { useEffect, useMemo, useState } from 'react';
import WindowedSelect from 'react-windowed-select';
import CardForm from '../Common/CardForm';
import FreightCards, * as FreightCard from '../Common/FreightCards';
import { customSelect, titleCase, depotExists, getRates, matchDestinations } from '../Common/utils';
import { callError, fallbackPriceListWarning as warnMsg } from '../Configuration/Config';
import "./newQuote.css"

class init {
  constructor (defaultSender) {
    this.sender = {value: defaultSender ? defaultSender.cityState.value : '', error: null};
    this._senderSuburb = {value: defaultSender ? defaultSender.cityState : '', error: null}; // should only be populated when we have a sender
    this.receiver = {value: '', error: null};
    this._rates = {value: '', error: null}; // should only be populated when we have sender and receiver
    this.customerRef = {value: '', error: null};
    this.freightList = new FreightCard.init();
  }
}

// Receives the ENTIRE store from the NewQuote store and the action
function reducer(store, action) {
  // console.log('Details Reducer: store, action', store, action);
  let error = null;
  let newValue = action.value;

  // validate the action
  switch (action.name) {
    case 'sender':
      if (newValue.value) {
        // Extract the depots from the action
        const depots = newValue.depots

        if (newValue.depot.length > 0 && depotExists(newValue, depots, 'depotFrom'))// check the selected depot matches a from depot
          return {
            sender: {value: newValue.value, error: error},
            _senderSuburb: {value: newValue, error: null},
            receiver: {value: '', error: null},
            _rates: {value: '', error: null}
          }
        else // From depot not found
          return {
            sender: {value: newValue.value, error: `${titleCase(newValue.label)} is not available!`},
            _senderSuburb: {value: '', error: null},
            receiver: {value: '', error: null},
            _rates: {value: '', error: null}
          }
      }
      // Potential error
      else if (typeof newValue === 'string' && newValue !== '') {
        if (newValue.includes('null')) // error from Omnis
          // keep newValue because the string is from the check in nextCall() below
          return {
            sender: { value: newValue, error: `${titleCase(newValue.slice(0, -6))} is not available.` },
            _senderSuburb: {value: '', error: null},
            receiver: {value: '', error: null},
            _rates: {value: '', error: null}
          }
        // else all fine and keep the new value
      }
      else // the field was cleared
        return {
          sender: { value: '', error: `${titleCase(action.name)} suburb is required.` },
          _senderSuburb: {value: '', error: null},
          receiver: {value: '', error: null},
          _rates: {value: '', error: null}
        }
      break;

    case 'receiver':
      if (newValue.value) { // a receiver was selected
        const depots = newValue.depots // extract the depots from the action

        if (store._senderSuburb.value === '')
          return {
            receiver: {value: '', error: 'Need to select a sending suburb before the receiving suburb'},
            _rates: {value: '', error: null}
          }

        const rate = getRates(store._senderSuburb.value, newValue, depots) // sender THEN receiver.
        if (rate !== null) //newValue.depots.find(d => store._senderDepot.value === d.depotFrom && newValue.depot === d.depotTo)) // the sender and receiver pair matches
          return {
            receiver: {value: newValue.value, error: null},
            _rates: {value: rate, error: null}
          }
        else
          return {
            receiver: {value: newValue.value, error: `${titleCase(newValue.value)} is not available.`},
            _rates: {value: '', error: null}
          }
      }
      // Potential error
      else if (typeof newValue === 'string' && newValue !== '') {
        if (newValue.includes('null'))
          // keep newValue because the string is from the check in nextCall() below
          return {
            receiver: {value: newValue, error: `${titleCase(newValue.slice(0, -6))} is not available.`},
            _rates: {value: '', error: null}
          }
        // else all fine and keep the new value
      }
      else
        return {
          receiver: {value: '', error: `${titleCase(action.name)} suburb is required.`},
          _rates: {value: '', error: null}
        }
      break;

    case 'customerRef':
      break;

    case 'freightList':
      //  Pass work down to the Freight Card extracting the nested action
      return { freightList: FreightCard.reducer(store['freightList'], newValue) };

    case 'loadStore':
      return {
        // cannot set the suburb details until the options arrive
        sender: {value: '', error: null},
        receiver: {value: '', error: null},
        _senderSuburb: {value: '', error: null},
        _rates: {value: '', error: null},
        customerRef: {value: newValue.customerRef, error: null},
        freightList: FreightCard.reducer(store['freightList'], {name: 'loadStore', value: newValue.freightList, validation: action.validation})
      }

    case 'loadAddresses': // React does not like my options.depots in NewQuote.js
      // it will set options.depots to null on the second run, so load both at once
      // console.log('loadaddresses', action);
      let newStore = reducer(store, {name: 'sender', value: {...newValue.sender, depots: newValue.depots}})
      return {
        ...newStore,
        ...reducer(newStore, {name: 'receiver', value: {...newValue.receiver, depots: newValue.depots}}),
      }

    default:
      throw new Error(`Details Reducer (${action.name}) is not supported`);
  }

  return { [action.name]: { value: newValue, error: error } };
}



function PageDetails({store, dispatcher, controls, options, validation}) {
  const [ error, setError ] = useState(null);
  const [ warning, setWarning ] = useState(null);
  const [ stopNext, setStopNext ] = useState(false); // used so we can validate the store before going to the next page
  const [ freightTypes, setFreightTypes ] = useState(null);
  const [ toSuburbs, setToSuburbs ] = useState(null);
  const dispatcherAction = useMemo(() => ({ page: 'Details', name: 'freightList', validation}), [validation]); // stop FreightCards re-calculating on every redraw

  // Display any errors
  useEffect(() => {
    let msg = [];
    // three potential errors
    ['sender', 'receiver', 'freightList', '_rates', '_senderSuburb'].forEach(name => {
      if (store[name].error) {
        msg.push(store[name].error);
        msg.push(<br />);
      }
    });
    if (freightTypes !== null && store.sender.value !== '' && store.receiver.value !== '' && freightTypes.type.length === 0 ) {
      msg.push('Cannot ship between this destination pair.');
      msg.push(<br />);
    }        
    if (msg.length === 0) msg = null;
    else msg.push(`Fix the issue, or ${callError}`);
    setError(msg);

    if (stopNext) { // the customer clicked on next and we validated the store
      setStopNext(false);
      controls.next.call(msg === null); // go to the next page
    }
  }, [store, controls.next, stopNext, freightTypes]);

  useEffect(() => { // Warn if default pricelist is used when a customer has a bespoke pricelist
    // console.log(store && store._rates && store._rates.value && store._rates.value[0] && store._rates.value[0], warnMsg);
    if (warnMsg && store._rates.value[0] && store._rates.value[0].default)
      setWarning(<>
        <p>{store.freightList._warn}</p>
        {warnMsg}
      </>);
    else if (store.freightList._warn)
      setWarning(store.freightList._warn)
    else
      setWarning(null);
  }, [options, store._rates.value, store.freightList._warn])


  // Get a list of all freight types used between the 2 suburbs' depots
  useEffect(()=> {
    if (store._rates.value !== '')
      setFreightTypes({ type: store._rates.value.sort((a, b) => a.freight > b.freight)})
    else
      setFreightTypes(null)
  }, [store._rates.value])


  // When a sender suburb is selected populate the receiver suburbs, filtering by the from depot LD0012009
  useEffect(() => {
    if (options && store._senderSuburb.value !== '')
      setToSuburbs(matchDestinations(store._senderSuburb.value, options.suburbs, options.depots))
    else
      setToSuburbs(null)
  }, [store._senderSuburb.value, options])


  const handleSelect = (name) => ({
    ...customSelect,
    inputId: name,
    isLoading: options === null,
    value: options && options.suburbs.find(s => s.value === store[name].value)
      ? options.suburbs.find(s => s.value === store[name].value)
      : '',
    options: options ? options.suburbs : undefined,
    className: `${customSelect.classNamePrefix}-outline${store[name].error ? ' error-outline' : ''}`,
    required: true, // this fails
    windowThreshold: 20,
    onChange: item => dispatcher({page: 'Details', name, value: {...item, depots: options.depots}, validation }), // Pass the full object and depots for validation LD001166
  })


  const handleSelectSecondary = (name) => ({ // LD0012009
    ...customSelect,
    inputId: name,
    isLoading: options === null,
    value: toSuburbs && toSuburbs.find(i => i.value === store[name].value)
      ? toSuburbs.find(i => i.value === store[name].value)
      : '',
    options: toSuburbs ? toSuburbs : undefined,
    className: `${customSelect.classNamePrefix}-outline ${store[name].error ? ' error-outline' : ''}`,
    required: true, // this fails
    windowThreshold: 20,
    onChange: item => dispatcher({page: 'Details', name, value: {...item, depots: options.depots}, validation }), // Pass the full object and depots for validation LD001166
  })


  const handleInput = (inputName, classes) => {
    return ({
      id: inputName,
      value: store[inputName].value,
      className: store[inputName].error ? classes + ' error-outline' : classes,
      onChange: (e) => dispatcher({page: 'Details', name: e.target.id, value: e.target.value})
      //onBlur: (e) => dispatcher({page: 'Details', name: e.target.id, value: store[inputName].value.trim()}),
    });
  };

  const nextCall = (enabled) => {
    if (enabled) {
      // run validation on the problematic fields. i.e. the 'required' field fails to work
      dispatcher({page: 'Details', name: 'sender', value: store.sender.value});
      dispatcher({page: 'Details', name: 'receiver', value: store.receiver.value})
      setStopNext(true); // wait for the errors to propagate from the store
    }
  }
  
  return (
    <CardForm
      title='Location Details'
      className="workflow"
      controls={{next: {...controls.next, call: nextCall, enable: !error} }}
      error={error}
      warning={warning}
    >
      <div className="stack">
        <label htmlFor='sender' className='h3'>Sender Suburb and Post Code</label>
        <WindowedSelect {...handleSelect('sender')} />
        <label htmlFor='receiver' className='h3'>Receivers Suburb and Post Code</label>
        <WindowedSelect {...handleSelectSecondary('receiver')} />
      </div>

      <section className='accent'>
        <div className='stack'>
          <p className = 'h3'>Customer Reference (Optional)</p>
          <p>If you have a Customer Reference Number, enter it below. This reference will appear on the quote summary.</p>
          <label htmlFor='quoteRef'>Customer Reference Number</label>
          <input type='text' {...handleInput('customerRef', 'width-50')} maxLength='100' />
        </div>
      </section>

      <FreightCards
        store={store.freightList}
        dispatcher={dispatcher}
        dispatcherAction={dispatcherAction}
        options={freightTypes}  // pass in the array of available freight types that match the receiver depot
        validation={validation}
        title = {<>
          <h3>Freight Details</h3>
          <p>Your quote will be more accurate with correct information, such as dimensions and weight.</p>
        </>}
      />
    </CardForm>
  );
}

export default PageDetails;
export { init, reducer };