/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  index.js
Author:    Dean B. Leggo
Version:   0.06
Description:
Entry point for the portal and routing for different URLs.

History:
0.06  17-07-24 JRB   Added Consignment Label route.
0.05  17-01-24 JRB   Added existing drop freight.
0.04  21-03-23 DBL   Removed comments
0.03  06-10-22 PWM   (ld0011661) Removed Contact.js from route - now links to external site instead
0.02  15-08-22 DBL   Added Modality for displaying Modals
0.01  02-05-22 PWM   (ld0011330) Added Change Password, MyUsers
0.00	22-02-22 DBL   Created.
----------------------------------- */

import React from 'react';
import { render } from 'react-dom';
import { Route } from 'react-router-dom';
import './index.css';

import { Authenticate } from './Session/useAuthenticate';
import { Navigator } from './Navigation/useNavigate';
import { Modality } from './Navigation/Modal';
import Dashboard from './Dashboard';
import Settings from './Settings/Settings';
import MyUsers from './Settings/MyUsers';
import ChangePassword from './Settings/ChangePassword';
import NewPickup from './NewPickup/NewPickup';
import NewQuote from './NewQuote/NewQuote';
import ConvertQuote from './ConvertQuote/ConvertQuote';
import Consignments from './Documents/Consignments'
import ProofOfDelivery from './Documents/ProofOfDelivery';
import ExistingQuote from './ExistingQuote';
import ExistingPickup from './ExistingPickup';
import DropFreight from './DropFreight/DropFreight';
import ExistingDropFreight from './ExistingDropFreight';
import DirectDropFreight from './DirectDropFreight/DirectDropFreight';
import PageConsignmentLabel from './DirectDropFreight/PageConsignmentLabel';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
  apiKey: '249167ff40ff22677c427a68c0f9eb80',
  plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: '249167ff40ff22677c427a68c0f9eb80' })

function App() {
  Bugsnag.notify(new Error('Test error'))
  return (
    <Modality>
      <Authenticate>
        <Navigator>
          <Route path="*" element={<Dashboard />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/changepassword" element={<ChangePassword />} />
          <Route path="/settings/myusers" element={<MyUsers />} />
          <Route path="/quote" element={<ExistingQuote />} />
          <Route path="/quote/new" element={<NewQuote />} />
          <Route path="/quote/existing" element={<NewQuote />} />
          <Route path="/quote/convert" element={<ConvertQuote />} />
          <Route path="/quote/dropfreight" element={<DropFreight />} />
          <Route path="/pickup" element={<ExistingPickup />} />
          <Route path="/pickup/new" element={<NewPickup />} />
          <Route path="/pickup/existing" element={<NewPickup />} />
          <Route path="/pod" element={<ProofOfDelivery />} />
          <Route path="/consignment" element={<Consignments />} />
          <Route path="/directdropfreight/existing" element={<DirectDropFreight />} />
          <Route path="/directdropfreight/new" element={<DirectDropFreight />} />
          <Route path="/dropfreight" element={<ExistingDropFreight />} />
          <Route path="/consignments/generatelabel" element={<PageConsignmentLabel />} />
        </Navigator>
      </Authenticate>
    </Modality>
  );
}

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
  , document.getElementById('root')
)

// render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );