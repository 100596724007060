/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  ExistingQuote.js
Author:    Jordan R. Bock
Version:   0.09
Description:
The existing quotes page.

History:
0.00 02-03-22 JRB  Created.
0.01 10-03-22 JRB  added use effect and quotesItem row
0.02 11-03-22 JRB  LD0011235 Fixed quote loading to use correct notation
0.03 15-03-22 JRB  LD0011235 Added date and buttons for navigation
0.04 16-03-22 JRB  LD0011235 Added Navigation, converted buttons to links and removed btn class
0.05 29-03-22 JRB  LD0011235 Added authentication
0.06 04-05-22 DBL  Changed links to use navigate an named the quote columns
0.07 16-05-22 JRB  Removed weight, cubic weight
0.08 11-07-22 JRB  Added scanned check and changed consignment link to only apply when scanned.
0.09 21-03-23 DBL  (ld0011930) Added Customer reference and handles an array of objects
----------------------------------- */

import React, { useEffect, useState }  from 'react';
import useNavigate from './Navigation/useNavigate';
import CardForm from './Common/CardForm';
import useRest from './Session/useRest';
import useAuthenticate from './Session/useAuthenticate';
import { useNavigate as useRouterNavigate } from 'react-router-dom';

function ExistingQuote() {
  const { changeType } = useNavigate();
  const { loggedIn } = useAuthenticate();
  const [ fetch, data, fetchError ] = useRest('GET', 'quotes');
  const [ fetchQuote, quoteData, quoteError, quoteLoading ] = useRest('GET', 'quote');
  const [ fetchPickup, pickupData, pickupError, pickupLoading ] = useRest('GET', 'pickups/edit');
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const navigate = useRouterNavigate();

  let curDate = new Date();
  let preDate = new Date();
  preDate.setDate(curDate.getDate() - 30); // Need to calculate the date diff before the HTML block
  
  // on first load, set the navigation type and initial state
  useEffect(() => { 
    if(loggedIn) {
      changeType('default', 'Existing Quotes');
      fetch();
    }
  }, [loggedIn]); // eslint-disable-line

  // switch page when the data arrives
  useEffect(() => { if (quoteData) navigate('./existing', {state: {quotation: quoteData}}) }, [quoteData, navigate]);
  useEffect(() => { if (pickupData) navigate('/pickup/existing', {state: {pickup: pickupData}}) }, [pickupData, navigate]);

  // Display any errors fetching data
  useEffect(() => {
    if (fetchError) setError(fetchError);
    if (quoteError) setError(quoteError);
    if (pickupError) setError(pickupError);
  }, [fetchError, quoteError, pickupError]);

  // Lock buttons when something is loading
  useEffect(() => {
    if (quoteLoading || pickupLoading) setLoading(true)
    else setLoading(false)
  }, [setLoading, quoteLoading, pickupLoading] )

  const cellButton = (item, link) => (<>
    {item &&
      <button type="button" className='btn__link' disabled={loading} onClick={link}>
        {item}
      </button>
    }
  </>);

  return (
    <CardForm title="Existing Quotes" className="margin-block-start no-measure" error={error}>
      { data === null ?
        <p className="h1">Loading Data...</p>
      :
        <div>
          <p className="h3">Quotes from {
            preDate.toLocaleDateString(undefined, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})
          }</p>
          <table>
            <thead>
              <tr>
                <th>Quote Number</th>
                <th>Date Created</th>
                <th>Sender <br/> (Suburb)</th>
                <th>Receiver <br/> (Suburb)</th>
                <th>Customer <br/> Reference</th>
                {/* <th>Dead Weight</th>
                <th>Cubic Weight</th> */}
                <th>Pickup #</th>
                <th>Consignment #</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data && data.quotes.map((quote) => (
                <tr key={quote.quoteNum}>
                  <td>
                    {cellButton(quote.quoteNum, () => fetchQuote({quote: quote.quoteNum}))}
                  </td>
                  <td>{quote.date}</td>
                  <td>{quote.sender}</td>
                  <td>{quote.receiver}</td>
                  <td>{quote.customerRef}</td>
                  {/* <td>{quote.weight}</td>
                  <td>{quote.volumne}</td> */}
                  <td>
                    {cellButton(quote.pickupNum, () => fetchPickup({pickupNumber: quote.pickupNum}))}
                  </td>
                  <td>
                    {quote.scanned === true ? cellButton(quote.conNum,
                      () => navigate('/consignment', {state: {consignmentNumber: quote.conNum}})
                    ) : quote.conNum}
                  </td>
                  <td>{quote.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    </CardForm>
  );
}

export default ExistingQuote;