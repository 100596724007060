/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  PageDG.js
Author:    Dean B. Leggo
Version:   0.03
Description:
Entering in Dangerous Goods.

History:
0.03  16-11-22 DBL   Set error message the same as NewPickup
0.02  15-11-22 DBL   Check we don't select more dg classes then available freight items
0.01  30-03-22 JRB   Fixed validation
0.00	08-03-22 DBL   Created.
----------------------------------- */

import React, { useEffect, useCallback, useState } from 'react';
import CardForm, { CardButton } from '../Common/CardForm';
import { callError, dgValidation } from '../Configuration/Config';

class init {
  constructor () {
    this._dgHas = {value: null, error: null};
    this.dgClasses = {value: [], error: null};
  }
}

const images = dgValidation.images;
const err_disallowed_dg_class = 'The selected dangerous goods classes cannot be booked online.';
const err_more_dg_than_freight = 'Number of Dangerous Goods classes cannot exceed number of line items.';

function reducer(store, action) {
  //console.log('DG Reducer: store, action'); console.log(store); console.log(action);
  let error = null;
  let newValue = null;
  const oldArray = store.dgClasses.value;

  // process the action and validate
  switch (action.name) {
    case '_dgHas':
      newValue = action.value;
      break;

    case 'dgClasses':
      if (action.value === 'clear') {
        newValue = [];
      } else if (action.value === 'verify') {
        newValue = oldArray;
      } else {
        // add or remove the dgClasses from the array
        if (oldArray.includes(action.value)) {
          newValue = oldArray.filter(item => item !== action.value)
        }
        else {
          newValue = oldArray;
          newValue.push(action.value);
        }

        // check all classes are allowed
        newValue.forEach(item => {
          if (!dgValidation.allowed.includes(item)) error = err_disallowed_dg_class;
        });
      }

      if (error === null && newValue.length > store.freightList.value.length )
        error = err_more_dg_than_freight;
      break;

    case 'loadStore':
      newValue = action.value.dgClasses;
      let newError = (() => {
        let messages = []; // array of messages
        let disallowed = newValue.filter(item => !dgValidation.allowed.includes(item));
        if (disallowed.length)
          messages.push(err_disallowed_dg_class);
        if (action.value.freightList && newValue.length > action.value.freightList.length)
          messages.push(err_more_dg_than_freight);
        return messages.length ? messages : null;
      })();
      return {
        _dgHas: {value: newValue.length > 0 ? true : false, error: null},
        dgClasses: {
          value: newValue,
          error: newError
        }
      }
    // case 'loadStore':
    //   newValue = action.value.dgClasses;
    //   return {
    //     _dgHas: {value: newValue.length > 0 ? true : false, error: null},
    //     dgClasses: {value: newValue, error: 
    //       newValue.find(item => !dgValidation.allowed.includes(item)) === undefined
    //         ? (action.value.freightList && newValue.length > action.value.freightList.length
    //           ? err_more_dg_than_freight
    //           : null)
    //         : callError
    //     }
    //   }

    default:
      throw new Error(`DG Reducer (${action.name}) is not supported`);
  };

  return { [action.name]: { value: newValue, error: error } };
}


function PageDG({store, dispatcher, controls}) {
  const [ error, setError ] = useState(null); // error handling
  const handleChange = useCallback((e) => {
    dispatcher({page: 'DG', name: 'dgClasses', value: e.target.name})
  }, [dispatcher]);

  useEffect(() => { // error message handling
    // console.log(store.dgClasses.error);
    if (store.dgClasses.error)
      setError([
        store.dgClasses.error, // only one error to display at this level.
        <br/>,
        `Fix the issue, or ${callError}`
      ]);
    else
      setError(null); // clear error
  }, [store.dgClasses.error])

  useEffect(() => {
    if (store.dgClasses.value.length > store.freightList.value.length) {
      dispatcher({page: 'DG', name: 'dgClasses', value: 'verify'})
    }
  }, [store.freightList.value]) // eslint-disable-line

  return (
    <CardForm title='Dangerous Goods' className="workflow DGContainerMax" error={error}
      controls={{ ...controls, next: {...controls.next, enable: store._dgHas.value === false ? true :
        (store._dgHas.value === true && store.dgClasses.value.length > 0 && store.dgClasses.error === null ? true : false)} }}
    >
      <section className='stack'>
        <p>Does your freight contain dangerous goods?</p>
          <div className="label-input__align">
            <div className='label-input__align'>
                <input type="radio" name="dg-toggle" id="yes"
                  checked={store._dgHas.value === true}
                  onChange={() => dispatcher({page: 'DG', name: '_dgHas', value: true})}
                />
                <label htmlFor='yes'>Yes</label>
            </div>
            <div className="label-input__align">
                <input type="radio" name="dg-toggle" id="no"
                  checked={store._dgHas.value === false}
                  onChange={() => {
                    dispatcher({page: 'DG', name: '_dgHas', value: false});
                    dispatcher({page: 'DG', name: 'dgClasses', value: 'clear'});
                  }}
                />
                <label htmlFor='no'>No</label>
            </div>
          </div>
      </section>
      {store._dgHas.value === true &&
        <section className='stack accent'>
          <p>Please select the types of Dangerous Goods.</p>
          <div className='grid DG'>
            {dgValidation.classes.map(item => (
              <CardButton
                name={item.name}
                title={
                  <div className='stack btn__DG-info'>
                    <h3>{item.title}</h3>
                    <sub>{item.sub}</sub>
                  </div>
                }
                icon={<img src={images[item.icon]} alt='' aria-hidden='true' focusable='false'></img>}
                className={(store.dgClasses.value.includes(item.name) && !dgValidation.allowed.includes(item.name)) ?
                  'cluster cluster-no-wrap error-outline' :
                  'cluster cluster-no-wrap'}
                onChange={handleChange}
                checked={store.dgClasses.value.includes(item.name)}
                key={item.name}
              />
            ))}
          </div>
        </section>
      }
    </CardForm>
  )
}

export default PageDG;
export { init, reducer };