/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageAccounts.js
Author:    John D. Kohl
Version:   0.08
Description:
At this point, the portal only accepts pickups from paying accounts.

History:
0.08  17-06-24 JRB   (ld0012770) Do not allow modifying freight if quoted.
0.07  12-07-23 JRB   (ld0012171) Added warning when above maximum line items
0.06  12-05-23 DBL   (ld0012035) Changed to support Multiple destinations
0.05  29-03-23 DBL   (ld0012009) Freight list now filters on depotFrom and depotTo
0.04  03-03-23 JRB   (ld0011487) Added filter for from Depot if we have a value.
0.03  22-11-22 DBL   Added useEffect and useMemo to reduce redraws
0.02  01-11-22 JRB   (ld0011671) Added forklift fields to match quotes
0.01  12-10-22 DBL   Append call error to the error message
0.00	21-03-22 JDK   Created.
----------------------------------- */

import React, { useEffect, useState, useMemo } from 'react';
import CardForm from '../Common/CardForm';
import FreightCards, * as FreightCard from '../Common/FreightCards';
import { callError } from '../Configuration/Config';

class init {
  constructor () {
    this.freightList = new FreightCard.init();
  }
}

// Receives the store property from the NewQuote store and the action
function reducer(store, action) {
  // console.log('NewPickup Freight Reducer: store, action', store, action);
  let newValue = action.value;

  // validate the action
  switch (action.name) {
    case 'freightList':
      //  Pass work down to the Freight Card
      return { freightList: FreightCard.reducer(store.freightList, newValue) };

    case 'loadStore':
      return {
        freightList: FreightCard.reducer(store.freightList, {name: 'loadStore', value: newValue.freightList, validation: action.validation})
      }

    default:
      throw new Error(`NewPickup Freight Reducer (${action.name}) is not supported`);
  }
}

function PageFreight({store, dispatcher, controls, options, validation}) {
  const [ error, setError ] = useState(null);
  const [ warning, setWarning ] = useState(null);
  const [ freightTypes, setFreightTypes ] = useState(null);
  const dispatcherAction = useMemo(() => ({ page: 'Freight', name: 'freightList'}), []); // stop FreightCards re-calculating on every redraw

  useEffect(() => {
    let msg = store.freightList.error
    if (msg) msg.push(`Fix the issue, or ${callError}`)
    setError(msg);
  }, [store.freightList.error]);

  useEffect(() => {
    if (store.freightList._warn)
      setWarning(store.freightList._warn);
    else if (store.quoteNumber.value)
      setWarning('Freight may not be modified when quoted.')
    else
      setWarning(null);
  }, [store.freightList._warn])

  // Stop FreightCards from re-calculating option on ever redraw
  useEffect(()=> {
    if (options && store.receiver._rates.value)
      setFreightTypes({type: store.receiver._rates.value.sort((a, b) => a.freight > b.freight)})
    else
      setFreightTypes(null)
  }, [store.receiver._rates.value, options])


  return (
    <CardForm
      title='Enter Freight'
      className="workflow"
      controls={{ ...controls, next: {...controls.next, enable: error ? false : true} }}
      error={error}
      warning={warning}
    >
      {store.quoteNumber.value ?
      <FreightCard.FreightSummary
      store={store.freightList}
      title={<p>Please review your freight before proceeding.</p>}
      />
      :
      <FreightCards
      store={store.freightList}
      dispatcher={dispatcher}
      dispatcherAction={dispatcherAction}
      options={freightTypes}
      title={<h3>Freight Details</h3>}
      validation={validation}
      />
    }
      
    </CardForm>
  );
}

export default PageFreight
export { init, reducer}