/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  Pickups.js
Author:    Dean B. Leggo, John D. Kohl
Version:   0.13
Description:
The existing pickups page.

History:
0.13  28-08-23 JDK   (ld0012210) Initialise new quotes with customer specific defaults.
0.12  12-05-23 DBL   (ld0012035) Changed to support Multiple destinations
0.11  29-03-23 DBL   (ld0012009) options suburbs now makes the from depots available
0.10  18-01-23 JDK   (ld0011885) Added location pathname as condition for the 'reload' modal. Changed initial state of 'progress' to 1.
0.09  17-01-23 JDK   (ld0011887) Added behaviour to reload quote record from Omnis when selecting that option in the modal.
0.08  17-01-23 JDK   (ld0011887) Added state tracking for quotes.
0.07  02-11-22 JRB   (ld0011807) Added available to suburbs list.
0.06  25-11-22 DBL   (ld0011792) Go home and not back
0.05  15-11-22 DBL   LD0011715 Fixed the loading of an existing store
0.04  18-10-22 DBL   Switched the order of the options.suburbs.value from [postCode, state] to [state, postCode]
0.03  11-10-22 DBL   LD0011669 Do not filter the suburb list
0.02  23-08-22 DBL   Change setModel to use authModal
0.01  26-05-22 JRB   Removed use of sender receiver id on existing. Replaced with just the value.
0.00	25-02-22 DBL   Created.
----------------------------------- */

import React, { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate as useRouterNavigate } from 'react-router-dom';
import useNavigate from '../Navigation/useNavigate';
import useAuthenticate from '../Session/useAuthenticate';
import { getStore, removeStore, setStore } from '../Session/Storage';
import useRest from '../Session/useRest';
import { compactStore, mergeDestinations } from '../Common/utils';
import PageDetails, * as Details from './PageDetails';
import PageServices, * as Services from'./PageServices';
import PageDG, * as DG from'./PageDG';
import PageInstructions, * as Instructions from'./PageInstructions';
import PageSummary, * as Summary from'./PageSummary';
import { freightCardValidation as validation } from '../Configuration/Config';


const menu = [{name: 'Details', click: true},
              {name: 'Additional Services', click: false},
              {name: 'Dangerous Goods', click: false},
              {name: 'Special Instructions', click: false},
              {name: 'Summary', click: false}];

const getOptions = () => {
  let options = getStore('options');
  // console.log('quote options loading:',options)
  return (options !== null && Object.keys(options).length) ? options : null;
}

class init {
  constructor(options) {
    let progress = { progress: 1 };
    let details = new Details.init(options ? options.customerDefaults.defaultSender : null);
    let services = new Services.init();
    let dg = new DG.init();
    let instructions = new Instructions.init(options ? options.customerDefaults.defaultInstructions : null);
    let summary = new Summary.init();
    return { ...progress, ...details, ...services, ...dg, ...instructions, ...summary };
  }
}

// When you call dispatcher with your action, it'll call reducer.
// The reducer is a function that'll take the current store and action,
// and return the newly updated store.
// Action - page - the page the dispatcher was called from
//          name - the store property to update
//         value - the new property value, can also be a nested action!
function reducer(store, action) {
  // console.log('New Quote Reducer: store, action', store, action);
  if (action.name === 'progress') return {...store, progress: action.value};
  // if (!store.hasOwnProperty(action.name) && !['loadStore','reset'.includes(action.name))
  //   throw new Error(`The property (${action.name}) does not exist in the store. From ${action.page} page`);

  // pass down the entry to update and the action, adding the updated entry into the store
  switch (action.page) {
    case 'Details':
      return {...store, ...Details.reducer(store, action)}; // needs the entire store to compare

    case 'Services':
      return {...store, ...Services.reducer(store, action)};

    case 'DG':
      return {...store, ...DG.reducer(store, action)};

    case 'Instructions':
      return {...store, ...Instructions.reducer(store[action.name], action)};

    case 'Summary':
      return {...store, ...Summary.reducer(store[action.name], action)};

    case 'loadStore':
      return { // load an existing quote or load from the local storage
        progress: action.progress,
        ...Details.reducer(store, action),
        ...Services.reducer(store, action),
        ...DG.reducer(store, action),
        ...Instructions.reducer(store, action),
        ...Summary.reducer(store, action),
      }

    case 'reset':
      return new init(getOptions())
    
    default:
      throw new Error(`New Quote Page (${action.page}) is not supported`);
  }
}


function NewQuote() {
  const { navState, changeType, changeState } = useNavigate();
  const navigate = useRouterNavigate();
  const location = useLocation();
  const { loggedIn, customer, authModal } = useAuthenticate();
  const [ store, dispatcher ] = useReducer(reducer, new init(getOptions()));
  const [ fetchOptions, optionsFetched, optionsError ] = useRest('GET', 'quoteNew');
  const [ fetchQuote, quoteData, quoteError ] = useRest('GET', 'quote');
  
  const [ options, setOptions ] = useState(null);
  const [ existingQuote, setExistingQuote ] = useState(false);
  const [ storeLoaded, setStoreLoaded ] = useState(false);

  // useEffect(() => { console.log('Store:', store) }, [store]); // debugging the Store
  // useEffect(() => { console.log('Options:', options) }, [options]); // debugging the options

  // on first load, check permissions and load the initial state
  useEffect(() => {
    if (loggedIn) {
      if (location.state !== null &&
          location.state.hasOwnProperty('quotation')
      ) { // we were passed an existing quote to view
        if (customer.permissions.Quotations.edit === true && location.state.quotation.accepted === false)
          loadExistingQuote(location.state.quotation, true);
        else if (customer.permissions.Quotations.view === true)
          loadExistingQuote(location.state.quotation, false);
        else
          permissionDenied('You do not have access to view quotations');
      }
      else { // new quote
        changeType('stepper', { stepArray: menu, stepCurrent: 1 });
        if (customer.permissions.Quotations.add === true) {
          fetchOptions();
          let loadedStore = getStore(`Quote-${customer.company}`);
          if (loadedStore
            && loadedStore.accepted === false
            && JSON.stringify(compactStore(new init(getOptions()))) !== JSON.stringify(loadedStore)
          ) {
            askAboutDraft(loadedStore);
          } else {
            setStoreLoaded(loadedStore);
          }
        }
        else
          permissionDenied('You do not have access to create a quotation.');
      }
    }
  }, [loggedIn]); // eslint-disable-line


  const loadExistingQuote = (quote, canEdit) => {
    const goto = (quote && quote.progress) ? quote.progress : 5;
    let newMenu = menu.map(item => canEdit ? { ...item, click: true } : { ...item, click: false });
    changeType('stepper', { stepArray: newMenu, stepCurrent: goto });

    setExistingQuote(true);
    //if (canEdit) TODO: we want to block these requests but currently it needs the options
    // to show the sender and receiver address.
    fetchOptions({quote: quote.quoteNumber});

    let loadedStore = getStore(`Quote-${customer.company}`);
    if (loadedStore
      && loadedStore.quoteNumber === quote.quoteNumber
      && quote.accepted === false
      && JSON.stringify(compactStore(new init(getOptions()))) !== JSON.stringify(loadedStore)
    )
        askAboutDraft(loadedStore, quote);
    else {
      removeStore(`Quote-${customer.company}`)
      dispatchQuote(quote);
      setStoreLoaded(quote);
    }
  };


  const askAboutDraft = (loadedStore, existingQuote) => {
    let quoteNumber = existingQuote ? existingQuote.quoteNumber : loadedStore.quoteNumber;
    if (existingQuote || (quoteNumber && location.pathname !== '/quote/new')) {
      authModal({
        title: 'Quote in Progress',
        description: `Quote ${quoteNumber} has a local copy, would you like to pickup where you left off?`,
        yes: {name: 'Yes, open local copy', call: () => {
          dispatchQuote(loadedStore);
          const goto = loadedStore.progress ? loadedStore.progress : 1;
          changeState({ ...navState, stepCurrent: goto, stepArray: menu});
        }},
        no: {name: 'No, reload quote', call: () => {
          removeStore(`Quote-${customer.company}`);
          fetchQuote({quote: quoteNumber})
        }}
      });
    }
    else {
      authModal({
        title: 'Quote in Progress',
        description: 'This account has an outstanding quote, would you like to complete the quote?',
        yes: {name: 'Yes', call: () => {
          dispatchQuote(loadedStore);
          const goto = loadedStore.progress ? loadedStore.progress : 1;
          changeState({ ...navState, stepCurrent: goto, stepArray: menu});
        }},
        no: {name: 'No, new quote', call: () => {
          removeStore(`Quote-${customer.company}`);
          dispatchQuote();
        }}
      });
    }
  };


  const permissionDenied = (description) => {
    authModal({
      title: 'Permission Denied',
      description: description,
      yes: {name: 'Go Back', call: () => navigate('/')} // go home (ld0011792)
    }); // go back
  };
  
  // When reloading a quote, we get data back from Omnis; once we do, send dipatch that data and flag the store as loaded.
  useEffect(() => {
    if (quoteData && !quoteError) {
      dispatchQuote(quoteData);
      setStoreLoaded(quoteData);
    }
  }, [quoteData, quoteError])

  
  // modify the options when they arrive by rewritting suburbs list
  useEffect(() => {
    if (optionsFetched) {
      setOptions({
        ...optionsFetched,
        suburbs: mergeDestinations(optionsFetched.suburbs, optionsFetched.depots)
      });
    }
  }, [optionsFetched, setOptions]);

  useEffect(() => {
    if (optionsError) permissionDenied('Failed to load the quote options.');
  }, [optionsError]) // eslint-disable-line

  const dispatchQuote = quote => {
    if (quote) dispatcher({page: 'loadStore', name: 'loadStore', value: { ...quote }, validation});
    else dispatcher({page: 'reset', name: 'loadStore', value: {}, validation});
    setStoreLoaded(quote);
  };

  // once the options has loaded and the quote is dispatched finsih loading the store
  useEffect(() => {
    // console.log('I am here', storeLoaded, options);
    if (storeLoaded && options ) {
      // console.log('I am here');
      dispatcher({page: 'Details', name: 'loadAddresses', value: {
        sender: options.suburbs.find(s => s.value === storeLoaded.sender),
        receiver: options.suburbs.find(s => s.value === storeLoaded.receiver),
        depots: options.depots
      }})
    }
  }, [options, storeLoaded, dispatcher])

  // Save the store to local storage whenever there is a change
  useEffect(() => {
    if (loggedIn && storeLoaded) // don't override the storage with the empty initialised store
      setStore(`Quote-${customer.company}`, compactStore(store)) // was an if-else with exactly the same behaviour regardless of condition - JDK 22-11-2022
  }, [store, storeLoaded, existingQuote, loggedIn, customer]);


  // default previous click
  const previousClick = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent - 1;
      const newMenu = navState.stepArray.map((item, index) =>
        index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  };


  // default next click and enable the next menu item
  const nextClick = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent + 1;
      const newMenu = navState.stepArray.map((item, index) =>
        index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  }


  // send all controls to each page and they can decide what to use
  let controls = {
    next: {call: nextClick, enable: false},
    previous: {call: previousClick, enable: true}
  };


  // Select the correct page
  if (navState) {
    if (store && (navState.stepCurrent !== store.progress)) dispatcher({name:'progress', value: navState.stepCurrent})
    switch (navState.stepCurrent) {
      case 1:
        return (
          <div className='hideOverflow-x'>
            <PageDetails store={store} dispatcher={dispatcher} controls={controls} options={options} validation={validation} />
          </div>
        );
      case 2:
        return (
          <div className='hideOverflow-x'>
            <PageServices store={store} dispatcher={dispatcher} controls={controls} options={options} />
          </div>
        );
      case 3:
        return (
          <div className='hideOverflow-x'>
            <PageDG store={store} dispatcher={dispatcher} controls={controls} options={options} />
          </div>
        );
      case 4:
        return (
          <div className='hideOverflow-x'>
            <PageInstructions store={store} dispatcher={dispatcher} controls={controls} options={options} />
          </div>
        );
      case 5:
        return (
          <div className='hideOverflow-x'>
            <PageSummary store={store} dispatcher={dispatcher} controls={controls} options={options}
              existingQuote={existingQuote} />
          </div>
        );
      default: return null;
    }
  }
  else
    return null;
}

export default NewQuote;