/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  Login.js
Author:    Dean B. Leggo
Version:   0.06
Description:
Setup the Login Overlay and update the Authenticate Context on the login state

History:
0.06  27-03-23 DBL   Clear the password so it cannot be stolen, refactored old technique
0.05  23-08-22 DBL   Change setModel to use authModal
0.04  11-08-22 JRB   Added handling for password resetting.
0.03  05-04-22 ADM   Added the content for the reset login screen,
0.02  02-03-22 DBL   CardForm - moved error from control to main line and changed for to htmlFor
0.01  01-03-22 ADM   Restructured Login Screen - importing and using CardForm.js
0.00	22-02-22 DBL   Created.
----------------------------------- */

import React, { useState, useRef, useEffect } from 'react';
import useAuthenticate from './useAuthenticate';
import { getSession } from './Storage';
import useRest, { plainCheck } from './useRest';
import { companyLogo, portalLogo, companyName, loginLogo } from '../Configuration/Config';
import CardForm from '../Common/CardForm'
import './Login.css';
import Modal from '../Navigation/Modal';

function Login() {
  const { customer, onLogin, onLogout, reason } = useAuthenticate();
  const [ displayError, setError ] = useState(null);
  const [ changePwd, setChangePwd ] = useState(false); // used to switch screens
  const [ username, setUsername] = useState('');
  const [ password, setPassword ] = useState('');
  const [ fetch, data, error, loading ] = useRest('POST', 'login');
  const unameRef = useRef(null); // set to null initially

  // on initial load of the app check for a stored customer session
  useEffect(() => {
    let storedCustomer = getSession();
    if (storedCustomer && storedCustomer.lastActive !== null) {
      let lastActive = new Date(storedCustomer.lastActive);
      if (lastActive < Date.now() - storedCustomer.timeout)
        onLogout('Session has expired');
      else
        plainCheck(storedCustomer.token, onLogin, onLogout); // check if the customer is still active on the server
    }
    unameRef.current.focus();
    // eslint-disable-next-line
  }, []);

  // when the data from fetch is returned, update the login
  useEffect(() => {
    if (data) {
      onLogin(data);
      setPassword(''); // do not keep the password in memory
    }
  }, [ data, onLogin ]);

  // If the user was logged out or there was an error, display the reason why
  useEffect(() => {
    let msg = []
    if (reason) msg.push(reason)
    if (reason && error) msg.push(<br/>)
    if (error) msg.push(error)
    if (msg.length === 0) msg = null
    setError(msg)
  }, [ reason, error ]);

  // submit handler for the login form
  const handleLogin = (enabled) => {
    if (enabled) fetch({ username: username, password: password })
  };

  // handler to toggle display the change password screen
  const toggleChangePwd = () => setChangePwd(!changePwd);

  return (
    <>
      {customer === null &&
        <>
          { changePwd === true ?
            <ChangePassword  toggleChangePwd={toggleChangePwd} /> :
            <div className='stack'>
              <img src={loginLogo ? loginLogo : companyLogo}
                alt={`Shipping with ${companyName}`}
                className='companyLogo login-img'
              />
              {/* <img src={portalLogo}
                alt={`${companyName} Customer Portal`}
                className='portalLogo'
              /> */}
              <CardForm title='Login'
                error={displayError}
                controls={{next: {call: handleLogin, id: 'login', name: loading ? 'Loading...' : 'Login', enable: !loading}}}
                className="login box"
              >
                <div className='stack'>
                  <label htmlFor='username'>Username *</label>
                  <input type='text'
                    name='username'
                    id='username'
                    ref={unameRef}
                    autoComplete='username'
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    required
                  />
                  <label htmlFor='password' >Password *</label>
                  <input type='password'
                    name='password'
                    id='password'
                    autoComplete='current-password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                  />
                  <input type="submit" style={{display: 'none'}} />
                  <button type="button" className='btn__link' style={{width: 'max-content'}} onClick={toggleChangePwd}>
                    I forgot my password
                  </button>
                </div>
              </CardForm>
            </div>
          }
        </>
      }
      { customer && customer.company === null && <SelectCompany /> }
    </>
  )
}


/* If the customer is part of multiple companies */
function SelectCompany() {
  const { customer, onLogin, onLogout } = useAuthenticate();
  const [ fetch, data, error, loading ] = useRest('PUT', 'login'); // PUT does not work in HTML forms
  const [ selectedCompany, setSelectedCompany ] = useState();

  useEffect(() => { if (data) onLogin(data) }, [ data, onLogin ]);

  useEffect(() => { if (error) onLogout(error) }, [ error, onLogout ]);

  const handleLogin = (enabled) => { if (enabled) fetch({id: selectedCompany}) };

  const handleSelect = (event) => setSelectedCompany(event.target.value);

  return (
    <div className='stack'>
      <img src={companyLogo} alt={'Shipping with ' + companyName} className='companyLogo login-img' />
      <CardForm title='Login' error={error}
        controls={{next: {call: handleLogin, name: loading ? 'Loading...' : 'Login', enable: !loading}}} className="login box"
      >
        <p>This user is associated with multiple Business accounts, please select which account you would like to use.</p>
        <div className="stack" onChange={handleSelect}>
          {customer.companies.map((company, index) =>
            <div className="label-input__align" key={`SC${index}`}>
              <input type="radio" name="selectCompany" id={company.id} value={company.id} />
              <label htmlFor={company.id}>{company.name}</label>
            </div>
          )}
        </div>
      </CardForm>
    </div>
  )
};


function ChangePassword({toggleChangePwd}) {
  const [ fetch, data, error, loading ] = useRest('POST', 'password/reset');
  const [ displayError, setError ] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [ displayModal, setModal ] = useState(false);

  // Switch back to the login screen when the password was successfuly changed
  useEffect(() => {
    if (data) {
      if (data.success)
        setModal(true);
      else
        setError(data.message)
    }
  }, [ data, toggleChangePwd ]);

  useEffect(() => {
    if (error)
      setError(error)
  }, [ error ]);

  return (
    <div className='stack'>
      {displayModal && <Modal
        title='Password Reset'
        description='An email has been sent with the new password. If you do not receive one please check your email and username is correct.'
        yes={{name: 'Okay', call: toggleChangePwd}}
        removeModal={() => setModal(false)}
      />}
      <img src={companyLogo} alt={'Shipping with ' + companyName} className='companyLogo login-img' />
      <CardForm title='Reset Password' error={displayError}
        controls={{
          previous: {name: 'back', call: toggleChangePwd},
          next: {
            call: () => fetch({username: username, email: email}),
            name: loading ? 'Loading...' : 'Change',
            enable: (username && email && !loading)
          }
        }} className="login box"
      >
        <div className='stack'>
          <p>Enter your Username and Email to reset your password.</p>
          <label htmlFor='username'>Username *</label>
          <input type='text' name='username' id='username' autoComplete='username' required onChange={(e) => setUsername(e.target.value)}/>
          <label htmlFor='email' >Email *</label>
          <input type='email' name='email' id='email' autoComplete='current-email' required onChange={(e) => setEmail(e.target.value)}/>
        </div>
      </CardForm>
    </div>
  );
}

export default Login;