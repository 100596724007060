/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  DirectDropFreight.js
Author:    Jordan R. Bock
Version:   0.01
Description:
Drop Freight in.

History:
0.01  14-02-24 JRB   Renamed Delivery Services to Additional Services
0.00	18-01-24 JRB   Created from ConvertQuote.js
----------------------------------- */

import React, { useState, useEffect, useReducer } from "react";
import { useLocation, useNavigate as useRouterNavigate } from 'react-router-dom';
import useNavigate from '../Navigation/useNavigate';
import useAuthenticate from '../Session/useAuthenticate';
import useRest from "../Session/useRest";
import { getStore, removeStore, setStore } from '../Session/Storage';
import { compactStore, mergeDestinations } from "../Common/utils";
import PageAccounts, * as Accounts from "../NewPickup/PageAccounts";             // from NewPickup
import PageDetails, * as Details from "../NewPickup/PageDetails";                // from NewPickup
import PageDG, * as DG from "../NewPickup/PageDG";                               // from NewPickup
import DropFreightSchedule, * as Schedule from "./PageSchedule";             
import PageReview, * as Review from "./PageReview";                  
import PageConsignmentDownload, * as Consignment from './PageConsignmentDownload';    // from NewPickup
import PageFreight, * as Freight from "../NewPickup/PageFreight";                // from NewPickup
import PageInstructions, * as Instructions from "./PageInstructions"; 
import { freightCardValidation as validation } from "../Configuration/Config";
import DropFreightConsignment, * as dfConsignment from "./PageConsignmentNumber";
import PageServices, * as Services from "./PageServices";
// import DropFreightSchedule from "./PageSchedule";

const menu = [{name: 'Accounts', click: true},
              {name: 'Enter Consignment Number', click: false},
              {name: 'Details', click: false},
              {name: 'Check Freight', click: false},
              {name: 'Additional Services', click: false},
              {name: 'Dangerous Goods', click: false},
              {name: 'Schedule Drop In', click: false},
              {name: 'Special Instructions', click: false},
              {name: 'Review Dropfreight', click: false},
              {name: 'Create Consignment', click: false}];
              
const getOptions = () => {
  let options = getStore('options');
  return (options !== null && Object.keys(options).length) ? options : null;
}

class init {
  constructor(options) {
    let accounts = new Accounts.init();
    let details = new Details.init();
    let freight = new Freight.init();
    let services = new Services.init();
    let dg = new DG.init();
    let schedule = new Schedule.init(options ? options.customerDefaults.defaultOpen : null, options ? options.customerDefaults.defaultClose : null); // Schedule is the only field not enterable in a quote so only load this when coming from a quote.
    let instructions = new Instructions.init();
    let review = new Review.init();
    let consignment = new Consignment.init();
    let dfconsignment = new dfConsignment.init()
    return {...accounts, ...details, ...freight, ...services ,...dg, ...schedule, ...instructions, ...review, ...consignment, ...dfconsignment};
  }
}

function reducer(store, action) {
  //console.log('Convert Quote Reducer: store, action'); console.log(store); console.log(action);

  // pass down the entry to update and the action, adding the updated entry into the store
  
  switch (action.page) {
    case 'Accounts':
      return {...store, ...Accounts.reducer(store, action)};
    case 'dfConsignment':
      return {...store, ...dfConsignment.reducer(store, action)};
    case 'Details':
      return {...store, ...Details.reducer(store, action)};
    case 'Freight':
      return {...store, ...Freight.reducer(store, action)};
    case 'Services':
      return {...store, ...Services.reducer(store, action)};
    case 'DG':
      return {...store, ...DG.reducer(store, action)};
    case 'Schedule':
      return {...store, ...Schedule.reducer(store, action)};
    case 'Instructions':
      return {...store, ...Instructions.reducer(store, action)};
    case 'Review':
      return {...store, ...Review.reducer(store, action)};
    // case 'Consignment':
    //   return {...store, ...Consignment.reducer(store, action)};
    case 'loadStore':
      return { // load an existing consignment or load from the local storage
        progress: action.progress,
        ...Accounts.reducer(store, action),
        ...dfConsignment.reducer(store, action),
        ...Details.reducer(store, action),
        ...Freight.reducer(store, action),
        ...Services.reducer(store, action),
        ...DG.reducer(store, action),
        ...Schedule.reducer(store, action),
        ...Instructions.reducer(store, action),
        ...Consignment.reducer(store, action),
        ...Review.reducer(store, action)
      }
    case 'reset':
      return new init(getOptions())
    
    default:
      throw new Error(`Drop Freight Page (${action.page}) is not supported`);
  }
}

function DirectDropFreight() {
  const { navState, changeType, changeState } = useNavigate();
  const navigate = useRouterNavigate();
  const location = useLocation();
  const { loggedIn, customer, authModal } = useAuthenticate();
  const [ store, dispatcher ] = useReducer(reducer, new init(getOptions()));
  const [ fetchOptions, options, optionsError ] = useRest('GET', 'directdropfreight/new');
  const [ fetchConnote, connoteData, connoteError ] = useRest('GET', 'directdropfreight/edit');
  const [ myOptions, setMyOptions ] = useState({});
  const [ storeLoaded, setStoreLoaded ] = useState(false); // can this join isLoaded
  const skipStep = ((location.pathname === '/directdropfreight/new') ? false : true);

  // useEffect(() => { console.log('Store:'); console.log(store) }, [store]); // debugging the Store

  // on first load, set the navigation type and initial state
  useEffect(() => {
    if (loggedIn) {
      if (location.state !== null &&
        location.state.hasOwnProperty('consignment')
      ) { // we were passed an existing drop freight to view
        if (customer.permissions.Consignments.edit === true && location.state.consignment.status === 2)
          loadExistingConsignment(location.state.consignment, true);
        else if (customer.permissions.Consignments.view === true)
          loadExistingConsignment(location.state.consignment, false);
        else
          permissionDenied('You do not have access to view consignments');
      }
      else { // new drop freight
        changeType('stepper', {stepArray: menu, stepCurrent: 1});
        if (customer.permissions.Consignments.add === true) {
          fetchOptions();
          let loadedStore = getStore(`Consignment-${customer.company}`);
          if (loadedStore
            && loadedStore.status === 2
            && JSON.stringify(compactStore(new init())) !== JSON.stringify(loadedStore)
          )
            askAboutDraft(loadedStore);
          else setStoreLoaded(true);
        }
        else
          permissionDenied('You do not have access to create a consignment.');
      }
    }
  }, [loggedIn]); // eslint-disable-line

  const loadExistingConsignment = (consignment, canEdit) => {
    const goto = (consignment && consignment.progress) ? consignment.progress : 9;
    const newMenu = menu.map((item, index) =>
      canEdit && index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
    );
    changeType('stepper', { stepArray: newMenu, stepCurrent: goto });
    fetchOptions({consignment: consignment.conNumber});

    let loadedStore = getStore(`Consignment-${customer.company}`);
    if (loadedStore && canEdit
      && loadedStore.conNumber === consignment.conNumber
      && loadedStore.status === 2
      && JSON.stringify(compactStore(new init(getOptions()))) !== JSON.stringify(loadedStore)
    )
        askAboutDraft(loadedStore, consignment);
    else {
      removeStore(`Consignment-${customer.company}`)
      dispatchConNote(consignment);
      setStoreLoaded(consignment);
    }
  };

  const askAboutDraft = (loadedStore, existingConsignment) => {
    setStoreLoaded(loadedStore);
    if (existingConsignment || (loadedStore.conNumber && location.pathname !== '/directdropfreight/new')) {
      const conNumber = existingConsignment ? existingConsignment.conNumber : loadedStore.conNumber;
      authModal({
        title: 'Drop Freight in Progress',
        description: `Dropfreight ${conNumber} has a local copy, would you like to pickup where you left off?`,
        yes: {name: 'Yes, open local copy', call: () => {
          dispatchConNote(loadedStore);
          // dispatchPickup(loadedStore);
          const goto = loadedStore.progress ? loadedStore.progress : 8;
          changeState({ ...navState, stepCurrent: goto, stepArray: menu});
        }},
        no: {name: 'No, reload drop freight', call: () => {
          removeStore(`Consignment-${customer.company}`); // delete the existing store object
          fetchConnote({conNumber: conNumber}); // get the pickup record from Omnis to reload
        }}
      });
    }
    else {
      authModal({
        title: 'Drop Freight in Progress',
        description: 'This account has an outstanding drop freight, would you like to complete the drop freight?',
        yes: {name: 'Yes', call: () => {
          // dispatchPickup(loadedStore);
          dispatchConNote(loadedStore);
          const goto = loadedStore.progress ? loadedStore.progress : 1;
          changeState({ ...navState, stepCurrent: goto, stepArray: menu});
        }},
        no: {name: 'No, new drop freight', call: () => {
          removeStore(`Consignment-${customer.company}`);
          // dispatchPickup();
          dispatchConNote();
        }}
      });
    }
  };

  // when the options arrive, load the store
  useEffect(() => {
    if (options) {
      const newOptions = {
        ...options,
        suburbs: mergeDestinations(options.suburbs, options.depots)
      };
      setMyOptions(newOptions);
      if (storeLoaded && storeLoaded.sender && storeLoaded.receiver) //If we have the object and the object has sender and receiver.
      // console.log(storeLoaded, newOptions)
        dispatcher({page: 'Details', name: 'loadAddresses', value: {
          sender: newOptions.suburbs.find(s => s.value === `${storeLoaded.sender.suburb}, ${storeLoaded.sender.state}, ${storeLoaded.sender.postCode}`),
          receiver: newOptions.suburbs.find(s => s.value === `${storeLoaded.receiver.suburb}, ${storeLoaded.receiver.state}, ${storeLoaded.receiver.postCode}`),
          depots: newOptions.depots
      }})
    }
  }, [options, storeLoaded])

  const dispatchConNote = connote => {
    if (connote) dispatcher({page: 'loadStore', name: 'loadStore', value: { ...connote }, validation: validation.default});
    else dispatcher({page: 'reset', name: 'reset', value: {}, validation: validation.default});
    setStoreLoaded(connote);
  };

  // When reloading a consignment, we get data back from Omnis; once we do, send dipatch that data and flag the store as loaded.
  useEffect(() => {
    if (connoteData && !connoteError) {
      dispatchConNote(connoteData);
      setStoreLoaded(connoteData);
    }
  }, [connoteData, connoteError])

    
  // Issue loading the options, bail out. Most likely permission denied due to already being converted
  useEffect(() => {if (optionsError) permissionDenied(optionsError)}, [optionsError]) // eslint-disable-line

  const permissionDenied = (description) => {
    authModal({
      title: 'Permission Denied',
      description: description,
      yes: {name: 'Go Back', call: () => navigate('/')} // go home (ld0011792)
    });
  };

  // default previous click
  const previousClick = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent - 1;
      const newMenu = navState.stepArray.map((item, index) =>
        index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  };

  // default next click and enable the next menu item
  const nextClick = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent + 1;
      const newMenu = navState.stepArray.map((item, index) =>
        index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  }

  const skipNext = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent + 2;
      const newMenu = navState.stepArray.map((item, index) =>
        index + 2 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  }

  const skipPrev = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent - 2;
      const newMenu = navState.stepArray.map((item, index) =>
        index - 2 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  }

  // send all controls to each page and they can decide what to use
  let controls = {
    next: {call: nextClick, enable: false},
    previous: {call: previousClick, enable: true}
  };

  useEffect(() => { // Never allow the user to edit consignment number if editing!
    if (skipStep && navState && navState.stepArray ) {
      if (navState.stepArray[1].click) {
        const newMenu = navState.stepArray
        newMenu[1].click = false;
        changeState({...navState, stepArray: newMenu});
      }
    }
  }, [navState]); 

  // Switch the card forms
  if (navState)
    switch (navState.stepCurrent) {
      case 1:
        return (
          <div className='hideOverflow-x'>
            <PageAccounts store={store} dispatcher={dispatcher} controls={{...controls, next: {call: (skipStep ? skipNext : nextClick), enable: false}}} options={myOptions} />
          </div>
        );
      case 2:
        return (
          <div className='hideOverflow-x'>
            <DropFreightConsignment store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 3:
        return (
          <div className='hideOverflow-x'>
            <PageDetails store={store} dispatcher={dispatcher} controls={{...controls, previous: {call: (skipStep ? skipPrev : previousClick), enable: true}}} options={myOptions} />
          </div>
        );
      case 4:
        return (
          <div className='hideOverflow-x'>
            <PageFreight store={store} dispatcher={dispatcher} controls={controls} options={myOptions} validation={validation} />
          </div>
        );
      case 5:
        return (
          <div className='hideOverflow-x'>
            <PageServices store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 6:
        return (
          <div className='hideOverflow-x'>
            <PageDG store={store} dispatcher={dispatcher} controls={controls} />
          </div>
        );
      case 7:
        return (
          <div className='hideOverflow-x'>
            <DropFreightSchedule store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 8:
        return (
          <div className='hideOverflow-x'>
            <PageInstructions store={store} dispatcher={dispatcher} controls={controls} />
          </div>
        );
      case 9:
        return (
          <div className='hideOverflow-x'>
            <PageReview store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 10:
        return (
          <div className='hideOverflow-x'>
            <PageConsignmentDownload store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      default: return null;
    }
  else
    return null;
}

export default DirectDropFreight;
