/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  ProgressSteps.js
Author:    Dean B. Leggo
Version:   0.10
Description:
Add progress steps within an element for people to navigate to different steps
stepArray -
stepCurrent -
stepControl - function that'll take no arguments and returns true or false if the progressClick can occur.


History:
0.03  09-05-22 DBL   Added new function `control`
0.02  04-05-22 DBL   Added class `clickable` to the steps
0.01  13-03-22 ADM   Updated the Font Awesome imports. Added conditional application of classes to get desired circle colours
0.00	24-02-22 DBL   Created.
----------------------------------- */

import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as circleRegular } from '@fortawesome/free-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import useNavigate from './useNavigate';
import './ProgressSteps.css';

function ProgressSteps() {
  const { navType, navState, changeState } = useNavigate();
  if (navType !== 'stepper')
    return new Error(`ProgressSteps can only be used in the 'stepper' Navigate Context. Found ${navType}`);

  let elementClass = 'progress-element';

  const progressClick = (goto) => {
    // block if stepControl retuns false
    if ( (navState.hasOwnProperty('control') && navState.stepControl()) || !navState.hasOwnProperty('control') ) {
      let newMenu = navState.stepArray.map( (item, index) =>
        index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  };

  return (
    <ul className='progress-steps'>
      {navState.stepArray.map((value, index) => {
        index += 1;
        return (
          <li className='progress-step' key={value.name}>
            <div className='progress-part'>
              <div className={
                navState.stepCurrent === index
                  ? elementClass + ' progress-active'
                  : (value.click ? elementClass + ' clickable' : elementClass)
                }
                tabIndex={navState.stepCurrent === index ? 0 : -1}
                aria-current={navState.stepCurrent === index && 'step'}
                onClick={value.click ? (() => progressClick(index)) : undefined}
              >
                <div className='progress-element-icon' aria-hidden='true'>
                    <FontAwesomeIcon
                      size='2x'
                      icon={navState.stepCurrent === index ? circleRegular : faCircle}
                      className={
                        navState.stepCurrent === index
                        ? "circleBlack"
                        : undefined
                      }
                    />
                </div>
                <span className='progress-element-text' >{value.name}</span>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default ProgressSteps;