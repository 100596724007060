/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageInstructions.js
Author:    Dean B. Leggo, John D. Kohl, Jordan R. Bock, Aaron D. Muilne
Version:   0.20
Description:
The quotes special instructions page.

History:
0.20  29-08-24 JRB  LD0012951 Added validation import, Dispatch to freight list to update our freight IDs. Removed unnecessary useEffect() dependancies.
0.19  15-08-24 JRB  LD0012951 - Get an updated list of freight items once we have saved and store it for if we immediately go on.
0.18  28-08-23 JDK  LD0012210 - Initialise new quotes with customer specific defaults.
0.17  23-11-22 DBL
0.16  29-06-22 JRB
0.15  28-06-22 DBL
0.14  24-06-22 DBL
0.13  24-06-22 JRB
0.12  23-06-22 DBL
0.11  10-06-22 JRB
0.10  11-05-22 DBL
0.09  04-05-22 DBL
0.08  29-04-22 JRB
0.07  27-04-22 DBL
0.06  21-04-22 DBL
0.05  20-04-22 JRB
0.04  13-04-22 DBL
0.03  15-03-22 DBL
0.02  15-03-22 ADM
0.01  14-03-22 DBL
0.00	09-03-22 DBL   Created.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CardForm from '../Common/CardForm';
import { compactStore } from '../Common/utils';
import useRest from '../Session/useRest';
import { freightCardValidation as validation } from "../Configuration/Config";

class init {
  constructor (defaultInstructions) {
    this.instructions = {value: defaultInstructions ? defaultInstructions : '', error: null};
    this.quoteNumber = {value: '', error: null};
    this.quotePrice = {value: '', error: null};
  }
}

function reducer(store, action) {
  //console.log('Instructions Reducer: store, action'); console.log(store); console.log(action);
  let error = null;
  let newValue = action.value;

  // validate the action
  switch (action.name) {
    case 'instructions':
      if (newValue.length > 850) // 150 characters are reserved from the pickup schedule
        error = 'Special instructions are too long'
      break;

    case 'quoteNumber':
      break; //No validation required

    case 'quotePrice':
      if (newValue !== '') {
        newValue = Number(newValue);
        if (isNaN(newValue) || !isFinite(newValue) || newValue < 0) {
          newValue = NaN;
          error = 'Issue retrieving the quotePrice';
        }
      } // else they do not have permission to view the quote price
      break;

    case 'loadStore':
      return {
        ...reducer(store, {name: 'instructions', value: newValue.instructions}),
        ...reducer(store, {name: 'quoteNumber', value: newValue.quoteNumber}),
        ...reducer(store, {name: 'quotePrice', value: newValue.quotePrice})
      }

    default:
      throw new Error(`Instructions Reducer (${action.name}) is not supported`);
  };

  return { [action.name]: {value: newValue, error: error} };
}


function PageInstructions({store, dispatcher, controls}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [ fetch, data, saveError, loading ] = useRest('POST', 'quoteNew');
  const [ error, setError ] = useState(null);
  
  // when the quoteNew data is returned update
  useEffect(() => {
    if (data) {
      if (data.quoteNumber) {
        dispatcher({page: 'Instructions', name: 'quoteNumber', value: data.quoteNumber});
        dispatcher({page: 'Instructions', name: 'quotePrice', value: data.quotePrice});
        dispatcher({page: 'Details', name: 'freightList', value: { name: 'updateIDs', value: data.freightItemIDs, validation: validation }}); // Update the ids of our items
          let myFreight = {};
        let myFreightList = [];
        for (const x in data.freightList) {
          for (const t in data.freightList[x]) {
            myFreight = {...myFreight, [t]:{value: data.freightList[x][t], error: null}}
          }
          myFreightList.push(myFreight);
          myFreight = {};
        }
        controls.next.call(true);
      } else if (data.errorMsg)
        setError(data.errorMsg);
    }
  }, [data, dispatcher, controls]);

  // Display any errors on the screen
  useEffect(() => {
    if (store.instructions.error !== null) setError(store.instructions.error)
    if (store.quotePrice.error !== null) setError(store.quotePrice.error)
    if (saveError !== null) setError(saveError)
  }, [saveError, store, setError])
  
  // When next is clicked save the quote
  const save = (enabled) => {
    if (enabled) fetch(compactStore(store));
    if(location.pathname === "/quote/new") navigate("/quote/existing");
  };

  const handleInput = (inputName, classes) => {
    return ({
      name: inputName,
      value: store[inputName].value,
      className: store[inputName].error ? classes + ' error-outline' : classes,
      onChange: (e) => dispatcher({page: 'Instructions', name: e.target.name, value: e.target.value})
    });
  };

  return (
    <CardForm title='Special Instructions' className="workflow" error={error}
      controls={{
        ...controls,
        next: {
          call: save,
          enable: loading ? false : (store.instructions.error ? false : true),
          name: loading ? 'Saving' : 'Next'
        }
      }}
    >
      <div className="stack">
        <label htmlFor="instructions">Are there any special instructions?</label>
        <textarea {...handleInput('instructions')} />
      </div>
    </CardForm>
  )
}

export default PageInstructions;
export { init, reducer };