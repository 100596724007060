/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  ExistingPickups.js
Author:    Jordan R. Bock
Version:   0.09
Description:
The existing pickups page.

History:
0.09 29-08-24 JRB   Corrected key of pickup list from pickup to pickup.pickupNum
0.08 11-07-22 JRB   Added scanned check and changed consignment link to only apply when scanned.
0.07 16-05-22 JRB   Removed No. Items, weight, cubic weight
0.06 11-05-22 DBL   Changed links to use navigate and named the quote columns
0.05 29-03-22 JRB   Added authentication
0.04 17-03-22 JRB   Added Navigation, removed click listeners
0.03 15-03-22 JRB   Added click listeners to navigation fields
0.02 14-03-22 JRB   Added Date
0.01 11-03-22 JRB   Added data loading
0.00 03-03-22 JRB   Created
----------------------------------- */

import React, { useEffect, useState }  from 'react';
import useNavigate from './Navigation/useNavigate';
import CardForm from './Common/CardForm';
import useRest from './Session/useRest';
import useAuthenticate from './Session/useAuthenticate';
import { useNavigate as useRouterNavigate } from 'react-router-dom';

function ExistingPickup() {
  const { changeType } = useNavigate();
  const { loggedIn } = useAuthenticate();
  const [ fetch, data, fetchError ] = useRest('GET', 'pickups/list');
  const [ fetchQuote, quoteData, quoteError, quoteLoading ] = useRest('GET', 'quote');
  const [ fetchPickup, pickupData, pickupError, pickupLoading ] = useRest('GET', 'pickups/edit');
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const navigate = useRouterNavigate();

  var curDate = new Date();
  var preDate = new Date();
  preDate.setDate(curDate.getDate() - 30); // Need to calculate the date diff before the HTML block

  // on first load, set the navigation type and initial state
  useEffect(() => { 
    if(loggedIn) {
      changeType('default', 'Existing Pickups');
      fetch()
    }
  }, [loggedIn]); // eslint-disable-line

  // switch page when the data arrives
  useEffect(() => { if (pickupData) navigate('./existing', {state: {pickup: pickupData}}) }, [pickupData, navigate]);
  useEffect(() => { if (quoteData) navigate('/quote/existing', {state: {quotation: quoteData}}) }, [quoteData, navigate]);

  // Display any errors fetching data
  useEffect(() => {
    if (fetchError) setError(fetchError);
    if (quoteError) setError(quoteError);
    if (pickupError) setError(pickupError);
  }, [fetchError, quoteError, pickupError]);

  // Lock buttons when something is loading
  useEffect(() => {
    if (quoteLoading || pickupLoading) setLoading(true)
    else setLoading(false)
  }, [setLoading, quoteLoading, pickupLoading] )

  const cellButton = (item, link) => (<>
    {item &&
      <button type="button" className='btn__link' disabled={loading} onClick={link}>
        {item}
      </button>
    }
  </>);

  return (
    <CardForm title="Existing Pickups" className="margin-block-start no-measure" error={error}>
      {data === null ?
        <p className="h1">Loading Data...</p>
      :
        <div>
          <p className="h3">Pickups from {
            preDate.toLocaleDateString(undefined, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})
          }</p>
          <table>
            <thead>
              <tr>
                <th>Pickup #</th>
                <th>Date Created</th>
                <th>Sender <br />(Company)</th>
                <th>Sender <br />(Suburb)</th>
                <th>Receiver <br />(Company)</th>
                <th>Receiver <br />(Suburb)</th>
                {/* <th>No.Items</th>
                <th>Deadweight</th>
                <th>Cubic Weight</th> */}
                <th>Quote #</th>
                <th>Consignment #</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data && data.pickups.map((pickup) =>
                <tr key={pickup.pickupNum}>
                  <td>
                    {cellButton(pickup.pickupNum, () => fetchPickup({pickupNumber: pickup.pickupNum}))}
                  </td>
                  <td>{pickup.date}</td>
                  <td>{pickup.senderCompany}</td>
                  <td>{pickup.senderSuburb}</td>
                  <td>{pickup.receiverCompany}</td>
                  <td>{pickup.receiverSuburb}</td>
                  {/* <td>{pickup[quantity]}</td>
                  <td>{pickup[weight]}</td>
                  <td>{pickup[volumn]}</td> */}
                  <td>
                    {cellButton(pickup.quoteNum, () => fetchQuote({quote: pickup.quoteNum}))}
                  </td>
                  <td>
                    {pickup.scanned === true ? cellButton(pickup.conNum,
                      () => navigate('/consignment', {state: {consignmentNumber: pickup.conNum}})
                    ) : pickup.conNum}
                  </td>
                  <td>{pickup.status}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      }
    </CardForm>
  );
}

export default ExistingPickup;