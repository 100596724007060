/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  PageAccounts.js
Author:    John D. Kohl
Version:   0.01
Description:
At this point, the portal only accepts pickups from paying accounts.
Also used in ConvertQuote/ConvertQuote.js

History:
0.00	21-03-22 JDK   Created.
----------------------------------- */

import React from 'react';
import useAuthenticate from '../Session/useAuthenticate';
import CardForm from '../Common/CardForm';
import { callError } from '../Configuration/Config';

class init {
  constructor () {
    this.account = { value: '', error: null };
  }
}

// Receives the store property from the NewPickup store and the action
function reducer(store, action) {
  let error = null;
  let newValue = action.value;

  // validate the action
  switch (action.name) {
    case 'account':
      // only paying accounts are allowed to log on. 
      error = (newValue === true || newValue === 'true') ? null : callError[0].toUpperCase() + callError.substring(1);
      break;

    case 'loadStore':
      return { ...reducer(store, {name: 'account', value:
        typeof newValue.account === 'boolean'
          ? (newValue.account ? 'true' : 'false')
          : newValue.account // should be a string <input> returns strings
      }) };

    default:
      throw new Error(`Accounts Reducer (${action.name}) is not supported`);
  }

  return { [action.name]: { value: newValue, error: error } };
}

function PageAccounts({store, dispatcher, controls}) {
  const { loggedIn, customer } = useAuthenticate();
  const handleChange = e => dispatcher({page: 'Accounts', name: 'account', value: e.target.value});

  return (
    <CardForm title='Accounts'
      controls={{ next: {...controls.next, enable: store.account.value === 'true' ? true : false }}}
      className="workflow"
      error={store.account.error}
    >
      <section className='stack'>
        <p>Is {loggedIn && customer.company} paying for this freight?</p>
        <div className='label-input__align' >
          <input type='radio' name='acct' id='acct_t' onChange={handleChange} value={true} checked={store.account.value==='true'} required />
          <label htmlFor='acct_t'>Yes</label>
          <input type='radio' name='acct' id='acct_f' onChange={handleChange} value={false} checked={store.account.value==='false'} required />
          <label htmlFor='acct_f'>No</label>
        </div>
      </section>
    </CardForm>
  );
}

export default PageAccounts
export { init, reducer }