/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  Settings.js
Author:    Dean B. Leggo
Version:   0.03
Description:
The user's settings page.

History:
0.03  26-04-22 PWM   Added Omnis interface, added entry fields
0.02  22-04-22 ADM   MyUsers Screen and Change Password Screen added and updated class names
0.01  16-03-22 ADM   Initial content.
0.00	22-02-22 DBL   Created.
----------------------------------- */

import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate as useRouterNavigate, Link } from 'react-router-dom';
import CardForm from '../Common/CardForm';
import useRest from '../Session/useRest';
import useAuthenticate from '../Session/useAuthenticate';
import useNavigate from '../Navigation/useNavigate';
import { compactStore } from '../Common/utils';
import './Settings.css'

// Setup state information
class init {
  constructor () {
    // The fields on this form
    this.name = {value: '', error: null};
    this.username = {value: '', error: null};
    this.role = {value: '', error: null};
    this.phone = {value: '', error: null};
    this.email = {value: '', error: null};
  }
}

function reducer(store,action) {
  // Reducer does two things: allows us to validate fields, and saves the entry fields into the store.
  let error = null;
  let value = action.value;
  
  // Validation
  switch (action.name) {
    case 'name':
      if (value === '') error = 'Name cannot be blank';
      break;

    case 'username': // This should never change!
    case 'role': // This should never change!
    case 'phone': // No validation on phone
    case 'email': // No validation on email
      break;

    case 'loadStore':
      // Shortcut to save everything - call ourselves to save ourselves
      return  {      
        ...reducer({}, {name:'name', value:value.name}),
        ...reducer({}, {name:'username', value:value.username}),
        ...reducer({}, {name:'role', value:value.role}),
        ...reducer({}, {name:'phone', value:value.phone}),
        ...reducer({}, {name:'email', value:value.email})
      }

    default:
      throw new Error(`Settings Page (${action.name}) is not supported`);
  }
  // Save into the store
  return { ...store, [action.name]: { value: value, error: error } };
}

export function Settings() {
  // Display the particular user's settings
  const { loggedIn } = useAuthenticate();
  const { changeType } = useNavigate();
  const [ fetchSettings, settings, settingsError ] = useRest('GET', 'settings');    // Loading the settings
  const [ saveSettings, savedOK, saveError, saving ] = useRest('POST', 'settings'); // Saving the settings
  const [ store, dispatcher ] = useReducer(reducer, new init());
  const [ error, setError ] = useState(null);                                       // Display error messages
  const navigate = useRouterNavigate();                                             // When we're done, we use this to go back to the dashboard.

  useEffect(() => {
    changeType('default', 'My Settings');
    if (loggedIn)
      fetchSettings(); // Load the settings from the server
  }, [loggedIn]); // eslint-disable-line

  useEffect(() => {
    if(settings)
      // Shorcut to set everything in the one place.
      dispatcher({name: 'loadStore', value: settings});
  }, [settings]);

  useEffect(() => {
    // display error messages found during validation
    let error = []
    // Any validations found per field in the store
    Object.keys(store).forEach(key => {
      if (store[key].error != null) 
        error.push(store[key].error)
    })
    // If saving caused an error, display that.
    if (saveError) error.push(saveError);
    if (settingsError) error.push(settingsError);

    // If any validation failed, display that.  If there are no errors, set it to null so we don't render it.
    if (error.length === 0) error = null;
    setError(error);
  }, [store, saveError, settingsError]);

  useEffect(() => {
    // once successfully updated on the server, go back to the dashboard.
    if (savedOK) {    
      if (savedOK.success) navigate('/');
      else setError(savedOK.message);     // Didn't save, something went wrong at the server, report that!
    }
  }, [savedOK, navigate]);

  const handleSave = (enabled) => {
    // User clicked Save
    if (enabled)
      saveSettings(compactStore(store));
  }

  const controls = {
    // Saving var allows us to make the save button say "saving" while it is being saved on the server. Disable button while saving.
     next: { call: handleSave, name:saving?'Saving':'Save', enable: (saving||error)?false:true },
     // Cancel takes us back to the dashboard.
     previous: { call: () => navigate('/'), name:'Cancel', enable: true }
  };

  // Shortcut to define everything we need for each entry field.
  const handleInput = (inputName, classes) => ({
    name: inputName,
    value: store[inputName].value,
    className: store[inputName].error ? classes + ' error-outline' : classes,
    onChange: (e) => dispatcher({page: 'Settings', name: e.target.name, value: e.target.value})
  });

  return (
    <CardForm
      title='Settings'
      controls={controls}
      className="margin-block-start user-form set-width-35vw"
      error={error}
    >
      {settings&&
      <div className='stack'>
        <div className='cluster cluster-outer'>
          <label htmlFor='name'>Name: </label>
          <input {...handleInput('name')}/>
        </div>
        <div className='cluster cluster-outer'>
          <label htmlFor='username'>User Name: </label>
          <input {...handleInput('username')} disabled/>
        </div>
        <div className='cluster cluster-outer'>
          <label htmlFor='role'>Role: </label>
          <input {...handleInput('role')} disabled/>
        </div>
        <div className='cluster cluster-outer'>
          <label htmlFor='phone'>Phone: </label>
          <input {...handleInput('phone')}/>
        </div>
        <div className='cluster cluster-outer'>
          <label htmlFor='email'>Email: </label>
          <input {...handleInput('email')}/>          
        </div>
        <div className='cluster cluster-outer'>
          <p>Change Password</p>
          <Link to={{pathname:'/settings/changepassword'}} className="btn">Change Password</Link>
        </div>
      </div>}
    </CardForm>
  );
}

export default Settings;