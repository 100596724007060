/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageServices.js
Author:    Jordan R. Bock
Version:   0.05
Description:
The Services page for the Drop Freight workflow.

History:
0.05  29-08-24 JRB ld0012951   Updated disable logic to now be an if else based on quote number.
0.04  07-06-24 JRB ld0012770   Prevent deselecting services when quoted
0.03  05-06-24 JRB ld0012770   Disable buttons if we are quoted!
0.02  14-02-24 JRB ld0012403   Removed Delivery h3, Changed text under removed h3
0.01	08-02-24 JRB ld0012403   Created.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faD } from '@fortawesome/free-regular-svg-icons';
import { faHandHoldingBox, faForklift, faDolly } from '@fortawesome/pro-regular-svg-icons';
import CardForm, { CardButton } from '../Common/CardForm';

class init {
  constructor () {
    this.deliveryRequired = {value: 0, error: null};
  }
}

function reducer(store, action) {
  // console.log('Services Reducer: store, action', store, action);
  let error = null;
  let newValue = action.value;

  // validate the action
  switch (action.name) {
    case 'deliveryRequired':
      if (store.quoteNumber.value === '') { // If there is a quote number then this option may not be changed
        let myStore = store.deliveryRequired; // We are now passing in the full store
        if (newValue === 3 && myStore.value === 3) newValue = 2; // deselecting forklift
        else if (newValue === 2 && myStore.value === 3) newValue = 0; // deselecting required on forklift
        else if (newValue === myStore.value ) newValue = 0; // deselecting either of the other two
      }
      break;

    case 'loadStore':
      if (newValue.deliveryRequired < 0 || newValue.deliveryRequired > 3) newValue.deliveryRequired = 0;
      return {
        deliveryRequired: {value: newValue.deliveryRequired, error: null}
      }

    case 'loadRequired':
      // (ld0012127) If pickup/delivery is not allowed select the only option
      return {
        deliveryRequired: newValue.deliveryAllowed
          ? store.deliveryRequired
          : {value: 1, error: null}
      }

    default:
      throw new Error(`Services Reducer (${action.name}) is not supported`);
  };

  return { [action.name]: {value: newValue, error: error} };
}


function PageServices({store, dispatcher, controls, options}) {
  // event handler for onChange checkbox
  const handleChange = value => e => dispatcher({page: 'Services', name: e.target.name, value});
  const [dlFork, setDlFork] = useState(false);
  const [dlPick, setdlPick] = useState(false);

  // forklift selection is independent of rates. See #12040 & #11487 specification.
  
  useEffect(() => {
    if (options) {
      options.forkliftDelivery && setDlFork(options.forkliftDelivery);

      // Check if we are allowed pickup or delivery for the selected rate
      if (store.freightList.value.length > 0
        &&  store.receiver._rates.value instanceof Array
        && store.receiver._rates.value.length > 0
      ) {
        // find the specific freight rate from the list of rates for the destination pair
        const rate = store.receiver._rates.value.find(r => r.freight === store.freightList.value[0].type.value)
        setdlPick(rate.deliveryAllowed)

        dispatcher({page: 'Services', name: 'loadRequired', value: {
          deliveryAllowed: rate.deliveryAllowed
        }})
      }
    }
  }, [options, store.receiver._rates.value, store.freightList.value]); // eslint-disable-line

  return (
    <CardForm title='Additional Services' className="workflow"
      controls={{ ...controls, next: {...controls.next, enable: store.deliveryRequired.value !== 0 ? true : false} }}
    >
      {/* <h3 className='accent'>Delivery</h3> */}
      <p>Please select whether freight will be collected from our depot or requires delivery.</p>
      <div className='cluster cluster-outer cluster-no-wrap'>
        <CardButton
          name='deliveryRequired' className='stack' onChange={handleChange(1)}
          title='Collection from Depot'
          icon={<FontAwesomeIcon icon={faHandHoldingBox} size="3x" />}
          checked={store.deliveryRequired.value === 1}
          disabled={store.quoteNumber.value !== '' ? store.deliveryRequired.value !== 1 : false} // If we have a quote number disabled is based on what is selected. Otherwise always enable
        />
        <CardButton
          name='deliveryRequired' className='stack' onChange={handleChange(2)}
          title='Delivery Required'
          icon={<FontAwesomeIcon icon={faDolly} size="3x" />}
          checked={(store.deliveryRequired.value === 2) || (store.deliveryRequired.value === 3)}
          disabled={store.quoteNumber.value !== '' ? store.deliveryRequired.value !== 2 : !dlPick} // If we have a quote number disabled is based on what is selected. Otherwise we must be allowed to charge delivery
        />
        <CardButton
          name='deliveryRequired' className='stack' onChange={handleChange(3)}
          title='Forklift Required'
          icon={<FontAwesomeIcon icon={faForklift} size="3x" />}
          checked={store.deliveryRequired.value === 3}
          disabled={store.quoteNumber.value !== '' ? store.deliveryRequired.value !== 3 : (!dlPick || !dlFork)} // If we have a quote number disabled is based on what is selected. Otherwise we must be allowed to charge delivery and delivery forklift.
        />
      </div>
      <h3>Services may incur additional charges</h3>
    </CardForm>
  );
}

export default PageServices;
export { init, reducer };