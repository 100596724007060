/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageInstructions.js
Author:    Jordan R. Bock
Version:   0.04
Description:
The Special Instructions page for the drop freight workflow

History:
0.04  29-08-24 JRB  Determine if this consignment has been created yet using _created.
0.03  29-08-24 JRB  Added validation import, Dispatch to freight list to update our freight IDs. Removed unnecessary useEffect() dependancies.
0.02  27-08-24 JDK  Store the consignment item IDs against the each item, overwriting quote item IDs (if they exist); removed unnecessary dependency for performance.
0.01	05-06-24 JRB   Created.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import CardForm from '../Common/CardForm';
 import { compactStore } from '../Common/utils';
 import useRest from '../Session/useRest';
import * as newInstructions from '../DirectDropFreight/PageInstructions';
import { freightCardValidation as validation } from "../Configuration/Config";

class init {
  constructor () {
    let instructions = new newInstructions.init(); //instructions, status
    return {
      ...instructions,
      _created: {value: false}
    } 
  }
}

function reducer(store, action) {
  // console.log(store, action)
  let newValue = action.value;
  if (action.name === '_created') return { [action.name]: {value: newValue} };
  else return newInstructions.reducer(store, action);
}

function PageInstructions({store, dispatcher, controls}) {
  const [ send, data, saveError, loading ] = useRest('POST', 'dropfreight');
  const [ update, updateSuccess, updateError, updateLoading] = useRest('POST', 'directdropfreight/edit');
  const [ error, setError ] = useState(null);

  // // when the pickup is saved, move to the next screen
  useEffect(() => {
    if (data) {
      if (data.success === true) {
        // dispatcher({page: 'Instructions', name: 'pickupNumber', value: data.pickupNumber});
        // dispatcher({page: 'Review', name: 'scheduleDay', value: data.scheduleDay});
        dispatcher({page: 'Instructions', name:'status', value: 2});
        dispatcher({page: 'Instructions', name:'_created', value: true}); // We have created the consignment. Further actions are updates.
        dispatcher({page: 'dfConsignment', name:'conNumber', value: data.recordNumber});
        dispatcher({page: 'Freight', name: 'freightList', value: { name: 'updateIDs', value: data.freightItemIDs, validation: validation } });
        controls.next.call(true);
      } else
        setError(data.message);
    }
  }, [data, controls]);

  useEffect(() => {
    if (updateSuccess) {
      if (updateSuccess.success === true) {
        // dispatcher({page: 'Instructions', name: 'pickupNumber', value: updateSuccess.pickupNumber});
        // dispatcher({page: 'Review', name: 'scheduleDay', value: updateSuccess.scheduleDay});
        dispatcher({page: 'Freight', name: 'freightList', value: { name: 'updateIDs', value: updateSuccess.freightItemIDs, validation: validation } });
        controls.next.call(true);
      } else
        setError(updateSuccess.message);
    }
  }, [updateSuccess, controls]); // Remove unnecessary dependency.

  // Display any errors on the screen
  useEffect(() => {
    if (store.instructions.error !== null) setError(store.instructions.error)
    if (saveError !== null) setError(saveError)
    if (updateError !== null) setError(updateError)
   }, [saveError, updateError, store, setError])


  // When next is clicked save the pickup
   const save = (enabled) => {
     if (enabled) {
      if (store._created.value === false) {
        send(compactStore(store));
      } else if (store._created.value === true) {
        update(compactStore(store));
      } else {
        setError('Bad Request'); // User must have edited the path in some way!
      };
     }
   };


  const handleInput = (inputName, classes) => {
    return ({
      name: inputName,
      value: store[inputName].value,
      className: store[inputName].error ? classes + ' error-outline' : classes,
      onChange: (e) => dispatcher({page: 'Instructions', name: e.target.name, value: e.target.value})
    });
  };

  return (
    <CardForm
      title='Special Instructions'
      className="workflow"
      error={error}
      controls={{...controls, next: {
        ...controls.next,
        call: save,
        enable: error === null && !loading && !updateLoading,
        name: loading || updateLoading ? 'Saving' : 'Next'
      }}}
    >
      <div className="stack">
        <label htmlFor="instructions">Are there any special instructions?</label>
        <textarea {...handleInput('instructions')} />
      </div>
    </CardForm>
  )
}

export default PageInstructions;
export { init, reducer };