/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  DropFreightConsignment.js
Author:    Jordan R. Bock
Version:   0.04
Description:
The Details page for the New Drop Freight workflow.

History:
0.04  30-08-24 JRB   Changed order of loadStore so conType isn't overwritten.
0.03  27-08-24 JDK   Prevent "update depth exceeded" error.
0.02  05-06-24 JRB   Handle Quote Number
0.01	19-01-24 JRB   Created.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import CardForm, { CardButton } from '../Common/CardForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardListCheck, faDesktopArrowDown } from '@fortawesome/pro-solid-svg-icons';
import useRest from '../Session/useRest';
import useNavigate from '../Navigation/useNavigate';

class init {
  constructor () { 
    this.conType = {value: '', error: null} // the type of consignment being generated
    this.conNumber = {value: '', error: null} // consignment number
    this.quoteNumber = {value: '', error: null} // Unused by Drop freight workflow but required to prevent crash in PageDetails
  }
}

// Receives the store property from the NewQuote store and the action
function reducer(store, action) {
  let error = null;
  let newValue = action.value;
  // console.log(newValue)

  switch(action.name) {
    case 'conType':
      switch(newValue) {
        case 'manual':
          break;
        case 'electronic':
          return { [action.name]: { value: newValue, error: error } , conNumber: {value: '', error: null} }; 
        default:
          newValue = null;
      }
      break;
    
    case 'quoteNumber':
    case 'conNumber':
      break;

    case 'loadStore':
    if (Array.isArray(newValue)) newValue = newValue[9]; // Drop freight passes an Array
      return {
        ...reducer(store, {name: 'conNumber', value: newValue.conNumber ? newValue.conNumber : ''}),
        ...reducer(store, {name: 'quoteNumber', value: newValue.quoteNumber ? newValue.quoteNumber : ''}),
        conType: {value: newValue.conType, error: null}, // assign this manually as the case of clearing the number when electronic is valid normally but not when loading.
      };

    default: {
      throw new Error(`Consignment Number Reducer (${action.name}) is not supported`);
    }
  }
    
  return { [action.name]: { value: newValue, error: error } };
}


function DropFreightConsignment({store, dispatcher, controls}) { // isPickup parameter deprecated

  // Hooks, custom or otherwise.
  const { navState, changeState } = useNavigate()

  // REST definition
  const [send, sendData, sendError, sendLoading] = useRest('GET', 'directdropfreight/validate'); // Check if a consignment number used

  // stateful constants
  const [display, setDisplay] = useState(0);
  const [customCtrls, setCustCtrls] = useState({});
  const [error, setError] = useState(null);
  
  const handleSubmit = () => {
    if(!error && !sendLoading) { // We don't care if we have bad sent data. We want to be able to try again if we have cleared the error by changing our number. was !sendData && 
      setCustCtrls({ ...controls, // Set the controls to show saving instead of submit and disable the button. Let other values remain as they were.
        next: {
          ...controls.next,
          name: 'Saving',
          enable: false
        }
      });
      send({conNumber: store.conNumber.value});
    }
  };

  // process the data when it returns from Omnis
  useEffect (() => {
    if (sendData && sendData.success === true) {
      const moveTo = navState.stepCurrent +1
      // const newMenu =  navState.stepArray.map(item => ({ ...item, click: true }));
      changeState({ ...navState, stepCurrent: moveTo});
      if (store.conType.value==='manual') dispatcher({page: 'dfConsignment', name: 'conNumber', value: sendData.message}) // Only set connumber here if we are using manual consignment.
    } else if (sendData && sendData.success === false) {
      setError(sendData.message);
    }
  }, [sendData]) // eslint-disable-line

  useEffect (() => {
    if (!sendError) {
      switch (store.conType.value) {
        case 'manual':
          setCustCtrls({ ...customCtrls,
            next: {
              ...customCtrls.next,
              call: handleSubmit,
              name: sendLoading ? 'Saving' : 'Submit',
              enable: ( store.conNumber.value === '' | sendLoading) ? false : true,
              hidden:(store.conType.value==='manual' ? false : true)
            }
          });
          setDisplay(1);
          break;

        case 'electronic':
          setCustCtrls({...controls, next: {...controls.next, enable: true}});
          if(!sendData && !sendLoading && store.conNumber.value !== '') { // Do not clear consignment number if already clear (empty string) - prevents recursive useEffect trigger.
            dispatcher({page: 'dfConsignment', name: 'conNumber', value: ''})
          }
          break;

        default:
          setCustCtrls({ ...controls,
            next: {
              ...controls.next,
              call: handleSubmit,
              name: sendLoading ? 'Saving' : 'Submit',
              enable: (error | store.conNumber.value === '' | sendLoading) ? false : true,
              hidden:(store.conType.value==='manual' ? false : true)
            }
          });
          setDisplay(0);
      }
    }
  }, [store, sendLoading]) // eslint-disable-line

  useEffect (() => {
    if (store.conType.value === 'manual') setError(null); //When we change the Consignment number and are manual, clear any errors.
  },[store.conNumber.value]); // eslint-disable-line

  return (
    <CardForm title="Enter Consignment Number" className='workflow'
      controls={customCtrls} error={error ? error : sendError}
    >
      {display < 2 && <>
      <div className='stack'>
        <p>Please select a consignment number option.</p>
      </div>
      <div className='cluster cluster-outer' style={{justifyContent: 'center', alignItems: 'stretch'}}>
        <CardButton
          name='conType'
          title='I have a manual connote'
          id={true}
          icon={<FontAwesomeIcon icon={faClipboardListCheck} size='3x'/>}
          onChange={(e) => dispatcher({page: 'dfConsignment', name: e.target.name, value: 'manual'})}
          checked={store ? store.conType.value === 'manual' : false}
          className='stack'
        />
        <CardButton
          name='conType'
          title='I want an electronic connote'
          id={false}
          icon={<FontAwesomeIcon icon={faDesktopArrowDown} size='3x'/>}
          onChange={(e) => dispatcher({page: 'dfConsignment', name: e.target.name, value: 'electronic'})}
          checked={store ? store.conType.value === 'electronic': false} // We now want to navigate instead.
          className='stack'
        />
      </div></>}
      {display === 1 && <ManualConsignment store={store} dispatcher={dispatcher} />}
    </CardForm>
  )
}


function ManualConsignment({store, dispatcher}) {
  const handleChange = e => {
    dispatcher({page: 'dfConsignment', name: e.target.name, value: e.target.value})
  }
  return (
    <section className='accent'>
      <div className='stack'>
        <p className='h3'>Manual Consignment</p>
        <p>Please enter your consignment number:</p>
        <input name='conNumber' type='text' onChange={handleChange} onInput={handleChange} value={store.conNumber.value} required/>
      </div>
    </section>
  )
}


export default DropFreightConsignment;
export { init, reducer };