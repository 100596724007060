/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  Dashboard.js
Author:    John D. Kohl
Version:   0.07
Description:
Show POD numbers and display selected PODs.

History:
0.07  25-11-22 DBL   (ld0011792) Go home and not back
0.06  23-08-22 DBL   Change setModel to use authModal
0.05  07-06-22 ADM   Added Magnifying Glass to search bar
0.04  04-05-22 DBL   Moved to Documents and added check for loaction and permissions
0.03  14-04-22 JDK   Final Draft
0.02  10-03-22 JDK   Removed useEffect monitoring to prevent recursive data retrieval
0.01  09-03-22 ADM   Reordered the table cells and buttons in the listCons. Added class "table-narrow"
0.00	01-03-22 JDK   Created.
----------------------------------- */

import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate as useRouterNavigate } from 'react-router-dom';
import useNavigate from '../Navigation/useNavigate';
import CardForm from '../Common/CardForm';
import useRest from '../Session/useRest';
import './Documents.css';
import useAuthenticate from '../Session/useAuthenticate';
import JsFileDownloader from 'js-file-downloader';
import print from 'print-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

function ProofOfDelivery() {
  const { loggedIn, customer, authModal } = useAuthenticate();
  const navigate = useRouterNavigate();
  const location = useLocation();
  const { changeType } = useNavigate();
  const [ fetch, data, error, loading ] = useRest('GET', 'documents/list');
  const [ generate, status, , docLoading ] = useRest('GET', 'documents/generate');
  const [ retrieve, docURL ] = useRest('PDF', 'documents/pdf');
  const [ podNum, setNum ] = useState(null);
  const [ genError, setGenError ] = useState(null);
  const [ searchFilter, setSearchFilter ] = useState(null);

  const handlePDF = useCallback((podNum) => {
    // console.log(podNum, status, docError, docLoading);
    if (podNum && !docLoading) { // always need a podNum, and don't send a request if one already in progress
        if (status && status.success) {
          if (status.message === podNum)
            retrieve({docRN: podNum, docType: 'POD', print: false}); // get the PDF.
          else 
            generate({docRN: podNum, docType: 'POD', print: false});
        }
        else if (status && !status.success) {
          if (status.message !== `${podNum} not found`)
            generate({docRN: podNum, docType: 'POD', print: false});
          else
            setGenError(`${podNum} not found`);
        }
        else if (!status)
          generate({docRN: podNum, docType: 'POD', print: false});
      }
  }, [status, docLoading, retrieve, generate])

  // on first load, set the navigation type and initial state
  useEffect(() => {
    changeType('default', 'Proof of Delivery');
    if (loggedIn) {
      if (customer.permissions.POD.view === true) {
        fetch({docType: 'POD'})
        if (location.state !== null && location.state.hasOwnProperty('podNumber'))
          setNum(location.state.podNumber) // we were passed a pod
      }
      else
        permissionDenied('You do not have access to view PODs.');
    }
  }, [loggedIn]); // eslint-disable-line

  useEffect(() => {
    if (error && error.toLowerCase() === 'permission denied')
      permissionDenied('You do not have access to view PODs.');
  }, [error]) // eslint-disable-line

  const permissionDenied = (description) => {
    authModal({
      title: 'Permission Denied',
      description: description,
      yes: {name: 'Go Back', call: () => navigate('/')} // go home (ld0011792)
    });
  };

  useEffect(() => { // load the first item in the list
    if (data && !podNum) setNum(data.documents[0].docRN);
  }, [data, podNum])

  useEffect(() => { // load pdf when search narrows list down to one
    if (data && searchFilter) {
      let results = data.documents.filter(doc => doc.docRN.includes(searchFilter));
      if (results.length === 1) setNum(results[0].docRN);
    }
  }, [data, searchFilter])

  // when the data arrives, check if we automatically loaded a document
  useEffect(() => {
    if (data && location.state !== null && location.state.hasOwnProperty('podNumber')) {
      // Grab all the Buttons and create an array. Grab the first button with the document number and scroll into view.
      let button = Array
        .from(document.querySelectorAll('button'))
        .find(button => button.innerHTML === location.state.podNumber)

      if (button !== undefined) {
        button.scrollIntoView()
      } else {
        authModal({
          title: 'Proof of Delivery',
          description: `Could not locate scanned original for ${location.state.podNumber}`,
          yes: {name: 'OK', call: () => {}}
        });
      } 
    }
  }, [data, location, authModal]);

  // fetch the pdf URL when the customer clicks on a POD
  useEffect(() => {
    podNum && handlePDF(podNum);
  }, [podNum, handlePDF]);
  
  // open the browser print dialogue using the print-js module, if enabled
  const handlePrint = (enabled) => {
    if (enabled) print(docURL)
  }

  // open the browser save dialogue using the js-file-downloader module
  // here we can override the automatic naming with the actual document type and number
  const handleSave = (enabled) => {
    if (enabled) {
      const download = new JsFileDownloader({
        url: docURL,
        autoStart: false,
        forceDesktopMode: true,
        filename: `POD_${podNum}.pdf`
      });
      download.start()
    }
  }

  // custom and next appear next to each other (left to right); rename to be print and save
  // only enable when a POD is selected (podNum !== null)
  let controls = {
    custom: { call: handlePrint, name:'Print', enable: podNum !== null },
    next: { call: handleSave, name:'Save', enable: podNum !== null }
  };

  return (
    <CardForm title='Proof of Delivery' className="margin-block-start" controls={controls} error={error} genError={genError}>
      <label
        htmlFor='search-bar'
        className="cluster cluster-no-wrap no-measure search"
        style={{paddingInlineStart: "1rem"}}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
        <input type='text' id='search-bar'
          placeholder='Search…'
          onChange={(e) => setSearchFilter(e.target.value)}
          className="no-measure"
          style={{border: "none"}}
        />
      </label>
      <div className='flex_container'style={{marginBlockStart: '0rem'}}>
        <div className='stack'>
          <p className='h3 table-heading' style={{textAlign: "center"}}>PODs</p>
          <div className='stack table'>
            { loading ? 'Loading…'
              : (data && data.documents
                .filter(doc => searchFilter ? doc.docRN.includes(searchFilter) : true)
                .map((doc) =>
                  <button type='button'
                    key={`POD${doc.id}`}
                    onClick={() => {
                      setNum(doc.docRN);
                      setGenError(null);
                    }}
                    className={doc.docRN === podNum ? 'checked' : 'not clicked'}
                  >
                    {doc.docRN}
                  </button>
                )
              )
            }
          </div>
        </div>
        <object data={!genError ? docURL : undefined} type="application/pdf" name='POD' width="100%">
          <p className="h3" style={{textAlign:"center", marginBlockStart: "0.5rem"}}>
            {docURL ? (genError ? `Document #${genError}.` : `Error loading ${podNum}`) : 'Please select a POD'}
          </p>
        </object>
      </div>
    </CardForm>
  );
}
 
export default ProofOfDelivery;