/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  DGCards.js
Author:    Dean B. Leggo, John D. Kohl
Version:   0.07
Description:
All functionality to handle and validate customer input of a number of dangerous goods details

History:
0.07  16-10-23 JDK   Updated UN Code validation to be current.
0.06  24-11-22 DBL   Process UN Code and Class as a pair to be validated and validate on initial load.
0.05  17-11-22 JDK   Handle UN Code changes where the "changed" input is identical, by re-using the data from the last REST call.
0.04  18-10-22 JRB   Clear the previous UNCode when we select N/A so it loads the details correctly if we go back to the same class and number.
0.03  09-08-22 JRB   Changed HandleInput so that subrisk and package group are optional.
0.02  09-08-22 JRB   Fixed reducer, changed dgProps foreach from checking if dgStore[name] to store[name] as dgStore isn't defined at that point
0.01  15-06-22 JRB   Added loadStore to reducer
0.00	23-03-22 JDK   Adapted from FreightCards.js
----------------------------------- */

import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import useRest from '../Session/useRest';
import { checkNumber } from './utils';

class cardInit {
  constructor() {
    this.dgClass = {value: '', error: null};
    this.dgUNCode = {value: '', error: null};
    this.dgTechName = {value: '', error: null};
    this.dgSubRisk = {value: '', error: null};
    this.dgPackGroup = {value: '', error: null};
    this.dgPackType = {value: '', error: null};
    this.dgPackCount = {value: '', error: null};
    this.dgQty = {value: '', error: null};
    this._dgError = null;
  }
}

class init {
  constructor() {
    this._dgError = null;
  }
}

const dgProps = [
  'dgClass',
  'dgUNCode',
  'dgTechName',
  'dgSubRisk',
  'dgPackGroup',
  'dgPackType',
  'dgPackCount',
  'dgQty'
]

const nullItem = { value: '', error: null };

function validateUNCode(unCode) {
  if (new RegExp("^[0-3][0-9]{3}$").test(unCode)) // UN codes must be four digits, and cannot be greater than 3999 (as at 16-10-2023)
    if (Number(unCode) <= 3550) // as at 16-10-2023, UN codes above 3550 are not in use. 
      return true;
  return false;
}

function initProcess(list) {
  let error = []
  list.forEach((key, index) => {
    if (key._dgError !== null) {
      error.push(`Item ${index + 1}: ${key._dgError}`);
      error.push(<br />);
    }
  });

  if (error.length === 0) error = null; // clear string if no errors were found
  return { _dgError: error }
} 


function reducer(store, action) {
  // console.log('DGCards Reducer: store, action', store, action);
  let newValue = action.value;
  let error = null;
  let dgError = '';

  // reduce the store to only dg items
  let dgStore = {};
  dgProps.forEach(name => {if (store[name]) dgStore[name] = store[name]})

  switch (action.name) {
    case 'dgUNCode':
      dgStore.dgTechName = nullItem;
      error = validateUNCode(newValue) ? error : 'Please enter a valid 4-digit UN Code.';
      break;

    case 'dgClass':
      newValue = newValue ? newValue.replace('dgClass','').trim() : '';
      if (newValue === 'N/A') dgProps.forEach(name => dgStore[name] = nullItem); // clear everything
      break;

    case 'dgPackCount':
      if (newValue !== '')
        [newValue, error] = checkNumber(newValue, 'Count', {lower: 1})
      break;

    case 'dgQty':
      if (newValue !== '')
        [newValue, error] = checkNumber(newValue, 'Quantity', {lower: 0.1, digits: 1})
      break;
      
    case 'dgDesc':
      return {
        ...store,
        dgTechName: {value: newValue, error: null},
        dgUNCode: {value: dgStore.dgUNCode.value, error: action.error},
        _dgError: action.error
      }

    case 'dgClear':
      return {
        ...store,
        dgUNCode: nullItem,
        dgClass: nullItem,
        dgTechName: nullItem,
        dgPackType: nullItem,
        dgPackGroup: nullItem,
        dgSubRisk: nullItem,
        dgPackCount: nullItem,
        dgQty: nullItem,
        _dgError: null
      }

    case 'dgTechName':
    case 'dgPackType':
    case 'dgPackGroup':
    case 'dgSubRisk':
      break;

    case 'loadStore':
      if (newValue.dgUNCode)
        return {
          ...reducer({}, {name: 'dgClass', value: newValue.dgClass}),
          ...reducer({}, {name: 'dgUNCode', value: newValue.dgUNCode}),
          ...reducer({}, {name: 'dgTechName', value: newValue.dgTechName}),
          ...reducer({}, {name: 'dgPackType', value: newValue.dgPackType}),
          ...reducer({}, {name: 'dgPackGroup', value: newValue.dgPackGroup}),
          ...reducer({}, {name: 'dgSubRisk', value: newValue.dgSubRisk}),
          ...reducer({}, {name: 'dgPackCount', value: newValue.dgPackCount}),
          ...reducer({}, {name: 'dgQty', value: newValue.dgQty})
        }
      else
        return {
          dgUNCode: nullItem,
          dgClass: newValue.dgClass === 'N/A' ? {value: newValue.dgClass, error: null} : nullItem,
          dgTechName: nullItem,
          dgPackType: nullItem,
          dgPackGroup: nullItem,
          dgSubRisk: nullItem,
          dgPackCount: nullItem,
          dgQty: nullItem,
          _dgError: null
        }
        

    default:
      throw new Error(`DG Card (${action.name}) is not supported`);
  }

  // Bubble up any errors from the store into the dgCard, including the actioned error
  Object.keys(dgStore).forEach(key => {
    if (key === action.name) {
      if (error) dgError += error + ', ';
    }
    else if (dgStore[key].error)
      dgError += dgStore[key].error + ', ';
  })
  if (!dgError) dgError = null; // clear string if no errors were bubbled
  else dgError = dgError.slice(0, -2); // remove the last ', '

  // return the entire row with the updated action
  return { ...store, ...dgStore, [action.name]: { value: newValue, error: error }, _dgError: dgError };
}



/*
 * a single dg card (child)
 */
function DGCard({dgClasses, dgItem, index, dispatcher, dispatcherAction}) {
  const previousUNCode = useRef(); // UN Code and Class pair
  const itemNum = index + 1;
  const [ getDGDesc, dgDesc, getError ] = useRest('GET','dg/description');


  // on load check the state is valid, if not clear everything
  useEffect(() => {
    if (dgItem.dgClass.value !== 'N/A' && !dgClasses.includes(dgItem.dgClass.value)) // the selected class is no longer in the list
      dispatcher({ ...dispatcherAction, value: {page: index, name: 'dgClear'} });
  }, []) // eslint-disable-line


  // When the UN Code or Class changes validate and fetch the tech name
  useEffect(() => {
    const pair = `${dgItem.dgUNCode.value}${dgItem.dgClass.value}`;
    // Is it the same pair and valid UN Code
    if (previousUNCode.current === pair && validateUNCode(dgItem.dgUNCode.value)) {
      if (dgDesc) { // don't try and extract or dispatch data if the dgDesc variable is not initialised.
        let desc = dgDesc.description;
        let msg = dgDesc.result.message;
        dispatcher({ ...dispatcherAction, value: {page: index, name: 'dgDesc', value: desc, error: msg}}); // resend the description and message from the last rest call (IF AND ONLY IF the UN code is the same)
      }
    }
    // Is it a new pair and valid UN Code
    else if (previousUNCode.current !== pair && validateUNCode(dgItem.dgUNCode.value)) {
      previousUNCode.current = pair;
      getDGDesc({class: dgItem.dgClass.value, code: dgItem.dgUNCode.value});
    }
    // Have we cleared the class
    else if (!pair || pair === 'N/A') {
      previousUNCode.current = '';
    }
  }, [dgItem.dgUNCode.value, dgItem.dgClass.value]) // eslint-disable-line

  
  // When the description returns, load it in the store
  useEffect(() => {
    if (dgDesc && dgItem.dgUNCode.value) {
      let desc = dgDesc.description;
      let msg = dgDesc.result.message;
      dispatcher({ ...dispatcherAction, value: {page: index, name: 'dgDesc', value: desc, error: msg}});
    }
  }, [dgDesc]) // eslint-disable-line

  
  // If an error returns, load it in the store
  useEffect(() => {
    getError && dispatcher({ ...dispatcherAction, value: {page: index, name: 'dgDesc', value: '', error: getError}});
  }, [getError]) // eslint-disable-line


  const handleInput = (inputName, classes) => {
    return {
      id: `${itemNum}inputName`,
      name: inputName,
      value: dgItem[inputName].value,
      className: dgItem[inputName].error ? classes + ' error-outline' : classes,
      required: dgItem.dgClass.value !== 'N/A' && (inputName !== 'dgSubRisk' && inputName !== 'dgPackGroup'), // Sub risk and Package group are not required. So we need to return false for them both.
      onChange: e => dispatcher({ ...dispatcherAction, value: {page: index, name: e.target.name, value: e.target.value}}),
      disabled: inputName !== 'dgClass' && (dgItem.dgClass.value === '' || dgItem.dgClass.value === 'N/A')
    }
  };


  return (
    <div className='dgCard stack'>
      <div className="cluster">
        <h3>Item {itemNum}</h3>
        <div className="cluster">
            {dgItem.error && <div id="warning" className="error-msg" style={{visibility: dgItem.error ? '' : 'hidden'}}>
              <FontAwesomeIcon icon={faTriangleExclamation} size="2x" />
            </div>}
      </div>
          {dgItem && <div className="grid six-col">

            <div className="stack span-2">
              <p className='label label-bg'>Freight Type</p>
              <p style={{paddingInline: "1rem"}}>{dgItem.type.value}</p>
            </div>

            <div className="stack span-4">
              <p className='label label-bg'>Description</p>
              <p style={{paddingInline: "1rem"}}>{dgItem.description.value}</p>
            </div>

            <div className="stack">
              <label htmlFor={`${itemNum}dgUNCode`} className="label-bg">UN Code</label>
              <input
                type='number'
                pattern='[0-9]{4}'
                onKeyPress={(event) => { // only allow entry of numberals.
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                {...handleInput('dgUNCode', 'input-number-no-controls')} // we want a number control for validation, but we don't want the arrows.
              />
            </div>

            <div className="stack">
              <label htmlFor={`${itemNum}dgClass`} className="label-bg">Class</label>
              {dgItem && <select {...handleInput('dgClass')} >
                <option key = 'default' value='' hidden disabled ></option>
                <option value='N/A' >N/A</option>
                {dgClasses && dgClasses.map(item => <option key={item} value={item}>{item}</option>)}
              </select>}
            </div>

            <div className="stack span-4">
              <label htmlFor={`${itemNum}dgTechName`} className="label-bg">Technical Name</label>
              <input type='text' maxLength='50' {...handleInput('dgTechName', 'grid-area_techNameInfo no-measure')} disabled />
            </div>

            <div className="stack">
              <label htmlFor={`${itemNum}dgSubRisk`} className="label-bg">Sub Risk</label>
              <input
                type='number'
                step='0.1'
                onKeyPress={(event) => {
                  if (!/[0-9]|\./.test(event.key)) { // only allow entry of numerals and decimal point.
                    event.preventDefault();
                  }
                }}
                {...handleInput('dgSubRisk', 'input-number-no-controls')} // we want a number control for validation, but we don't want the arrows.
              /> 
            </div>

            <div className="stack">
              <label htmlFor={`${itemNum}dgPackGroup`} className="label-bg">Package Group</label>
              <input type='text' maxLength='10' {...handleInput('dgPackGroup')} />
            </div>

            <div className="stack span-2">
              <label htmlFor={`${itemNum}dgPackType`} className="label-bg">Package Type</label>
              <input type='text' maxLength='30' {...handleInput('dgPackType')} />
            </div>

            <div className="stack">
              <label htmlFor={`${itemNum}dgPackCount`} className="label-bg">Count</label>
              <input type='number' step='1' min='0' {...handleInput('dgPackCount')} />
            </div>

            <div className="stack">
              <label htmlFor={`${itemNum}dgQty`} className="label-bg">Quantity (kg|L)</label>
              <input type='text' title='Quantity (kg or litres)' maxLength='10' {...handleInput('dgQty')} />
            </div>
          </div>}
      </div>
    </div>
  );
}


/*
 * Display a list of 
 * store - Object containing 
 * dgClasses - Array of all possible DG classes
 * dispatcherAction - DGCards will be nested and DGCards will make its dispatch as the value
 *                    of the dispatcherAction.
 */
function DGCards({store, dgClasses, dispatcher, dispatcherAction, title}) {

  return (
    <section className="dgDetails accent stack">
      <h3>{title}</h3>
      <p>For each item that contains Dangerous Goods, please select the UN Code.</p>
      <div className='stack'>
        <div className="cluster">
          <div className="stack">
            {store.map((item, index) => (
              <DGCard
                dgClasses={dgClasses.map(i => i.replace('dgClass', ''))}
                dgItem={item}
                index={index}
                dispatcher={dispatcher}
                dispatcherAction={dispatcherAction}
                key={`DG${index}`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}


export default DGCards;
export { reducer, init, cardInit, initProcess };