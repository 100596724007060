/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  PageDGInfo.js
Author:    John D. Kohl
Version:   0.10
Description:
The Dangerous Goods Information page for the New Quote workflow.

History:

0.10  17-11-22 JDK   Errors are now accumulated here rather than in DG Cards. LD0011758
0.01	30-03-22 JDK   Created.
----------------------------------- */

import React, { useState, useEffect } from 'react';
import CardForm from '../Common/CardForm';
import DGCards from '../Common/DGCards';
import { callError } from '../Configuration/Config';

function DGInfo({store, dispatcher, controls}) {
  // console.log('DG Info store'); console.log(store);
  const [error, setError] = useState(null);

  useEffect(() => {
    let error = []
    if(store.freightList._dgError) {
      error.push(store.freightList._dgError);
    }
    if (store && store.dgClasses.value && store.freightList.value) {
      let classes = store.dgClasses.value.map(item => item.replace('dgClass', ''));
      let items = store.freightList.value.map(item => item.dgClass.value);

      if (!classes.every(value => items.includes(value)))
        error.push('You must specify at least one freight item corresponding to each dangerous goods class selected.');
    }
    if(!error.length)
      error = null;
    else {
      error.push(<br/>);
      error.push(`Fix the issue, or ${callError}`);
    }
    setError(error);
  }, [store])

  return (
    <CardForm
      title='Dangerous Goods'
      className="workflow"
      controls={{...controls, next: {...controls.next, enable: error ? false : true} }}
      error={error}
    >
      <p>
        <strong style={{fontSize: "inherit", fontWeight: "bold"}}>Disclaimer: </strong>
        It is the
        <em style={{fontSize: "inherit", fontWeight: "bold"}}> consignor's responsibility </em>
        to ensure that the identification of Dangerous Goods is accurate.
      </p>
      <DGCards
        title='Dangerous Goods Information'
        store={store.freightList.value}
        dgClasses={store.dgClasses.value}
        dispatcher={dispatcher}
        dispatcherAction={{ page: 'Freight', name: 'freightList'}}
      />
    </CardForm>
  )
}

export default DGInfo;