/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  AcceptQuote.js
Author:    Jordan R. Bock
Version:   0.14
Description:
The options page after accepting a quote.

History:
0.14  29-08-24 JRB   (ld0012951) Rolled back previous version.
0.13  20-08-24 JRB   (ld0012845) Make sure to compact the quote before trying to do the pickup.
0.12  15-08-24 JRB   (ld0012951) If we have the freightArray, replace the freightList for drop ins.
0.11  14-06-23 DBL   (ld0012126) Changed Link to CardLink
0.10  08-06-23 JRB   (ld0012126) Disable pickup button if pickup was not a selected service. Warning now goes between 2 different messages to match this behaviour.
0.09  03-03-23 JRB   (ld0011931) Added support number.
0.08  02-03-23 JRB   (ld0011931) Disabled the drop freight in button if we have selected this is a pickup item.
0.07  06-10-22 PWM   (ld0011664) Quotes valid from 30 -> 14 days.
0.06  15-06-22 JRB   Added compact to quote
0.05  03-05-22 JRB   Added store state to dropfreight
0.04  02-05-22 JRB   Correctly linked to convert quote
0.03  30-03-22 JRB   Added icons to buttons and changed card buttons to links
0.02  28-03-22 JRB   Renamed to AcceptQuote, also fixed click calls added call back and navigation.
0.01	22-03-22 JRB   Added switch in click handling.
0.00	21-03-22 JRB   Created.
----------------------------------- */

import React from 'react';
import { useNavigate as useRouterNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardForm, { CardLink } from '../Common/CardForm';
import { faFloppyDisk, faTruck, faTruckRampBox } from '@fortawesome/free-solid-svg-icons';
import { compactStore } from '../Common/utils';
import { callError } from '../Configuration/Config';

function AcceptQuote({quote}) {
  const navigate = useRouterNavigate();
  const handleCick = e => {
    switch (e.target.name) {
      case 'save':
        navigate('/')
        break;

      case 'pickup':
        navigate('/quote/convert', {state: {quote: compactStore(quote)}})
        break;  

      case 'dropin':
        navigate('/quote/dropfreight', {state: {quote: compactStore(quote)}})
        break;
    }
  }
  
  return (
    <CardForm 
      title='Accept Quote'className="workflow" 
      controls={{}} 
      warning={quote.pickupRequired.value > 1 
        ? 'As pickup was selected as part of the quoting process, drop freight in is not available at this time. If you wish to drop freight in, ' + callError 
        : 'As pickup was not selected as part of the quoting process, it is not available at this time. If you wish to arrange a pickup, ' + callError }
    >
      <p>All Saved Quotes are valid for 14 days.</p>
      <div className='grid' style={{justifyContent:"space-evenly", gridTemplateColumns: "repeat(auto-fit, 200px)"}}>
        <CardLink
          title='Save' name='save'
          className='stack' onClick={handleCick}
          icon={<FontAwesomeIcon icon={faFloppyDisk} size='3x' />}
        />
        <CardLink
          title='Convert to Pickup' name='pickup'
          className='stack' onClick={handleCick}
          icon={<FontAwesomeIcon icon={faTruckRampBox} size='3x' />}
          disabled={quote.pickupRequired.value <= 1}
        />
        <CardLink
          title='Drop Freight in' name='dropin'
          className='stack' onClick={handleCick}
          icon={<FontAwesomeIcon icon={faTruck} size='3x' />}
          disabled={quote.pickupRequired.value > 1}
        />
      </div>
    </CardForm>
  );
}

export default AcceptQuote;