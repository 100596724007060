/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  Dashboard.js
Author:    Dean B. Leggo
Version:   0.10
Description:
Dashboard to provide a menu.

History:
0.11  28-03-24 JRB   Changed Pickups icon to faTruck, Drop freight in to faParachuteBox and existing drop freight in to faBoxTaped.
0.05  02-03-23 JRB   Disable buttons without permission instead of not loading them.
0.04  29-04-22 DBL   Updated to permissions array to object
0.03  13-04-22 ADM   Updated to new class system.
0.02  04-03-22 ADM   Updated icons to match current design
0.01	24-02-22 DBL   Added icons to each card.
0.00	22-02-22 DBL   Created.
----------------------------------- */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAuthenticate from './Session/useAuthenticate';
import useNavigate from './Navigation/useNavigate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar,
        faFileSignature, faFileLines, faBoxesStacked } from '@fortawesome/free-solid-svg-icons';
import { faBoxTaped, faCabinetFiling, faParachuteBox, faTruck } from '@fortawesome/pro-solid-svg-icons';
import { dashboard } from './Configuration/Config.js';
import './Dashboard.css'

// This can be stored somewhere else and 'access control' will pass a limited array
const cards = [{id: 'btnQuote', name: 'New Quote', link: '/quote/new', tooltip: 'Create a new quotation',
                permission: {name: 'Quotations', allowed: 'add'}, iconName: faFileInvoiceDollar},

              {id: 'btnPickup', name: 'New Pickup', link: '/pickup/new', tooltip: 'Create a new pickup',
                permission: {name: 'Pickups', allowed: 'add'}, iconName: faTruck},

              {id: 'btnDropFreight', name: 'New Drop Freight In', link: '/directdropfreight/new', tooltip: 'Create a new drop freight in',
                permission: {name: 'Consignments', allowed: 'add'}, iconName: faParachuteBox},
                
              {id: 'btnPODList', name: 'PODs', link: '/pod', tooltip: 'View your proof of deliveries',
                permission: {name: 'POD', allowed: 'view'}, iconName: faFileSignature},

              {id: 'btnQuoteList', name: 'Existing Quotes', link: '/quote', tooltip: 'View current quotations',
                permission: {name: 'Quotations', allowed: 'view'}, iconName: faCabinetFiling},

              {id: 'btnPickupList', name: 'Existing Pickups', link: '/pickup', tooltip: 'View current pickups',
                permission: {name: 'Pickups', allowed: 'view'}, iconName: faBoxesStacked},

              {id: 'btnDropFreightList', name: 'Existing Drop Freight In', link: '/dropfreight', tooltip: 'View current drop freights',
                permission: {name: 'Consignments', allowed: 'view'}, iconName: faBoxTaped},

              {id: 'btnConsignmentList', name: 'Consignments', link: '/consignment', tooltip: 'View all consignments',
                permission: {name: 'Consignments', allowed: 'view'}, iconName: faFileLines},
              ];

function cardBuilder(customer, {name, id, link, tooltip, permission, iconName }) {
  // if allowed to access, continue
  if (customer.permissions[permission.name][permission.allowed] === true)
  return (
    <Link id={id} to={link} title={tooltip} className='card box stack' key={link}>
      <FontAwesomeIcon icon={iconName} size='5x' />
      <h2>{name}</h2>
    </Link>
  );
  else
    return (
      <Link to="/" title={tooltip} className='card box stack disabled' key={link}>
        <FontAwesomeIcon icon={iconName} size='5x' />
        <h2>{name}</h2>
      </Link>
    );
}

function Dashboard() {
  const { customer, loggedIn } = useAuthenticate();
  const { changeType } = useNavigate();

  useEffect(() => {
    changeType('dashboard', null);
  }, [changeType]) ;

  return (
    <div className="box center stack dashboard">
      <h1>{dashboard}</h1>
      <div className='grid'>
        {loggedIn && cards.map(card => (cardBuilder(customer, card)))}
      </div>
    </div>
  );
}

export default Dashboard;