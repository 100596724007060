/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageInstructions.js
Author:    Dean B. Leggo
Version:   0.04
Description:
The Special Instructions page for the Convert to Pickup workflow.

History:
0.04  29-08-24 JRB   LD0012951 Added validation import, Dispatch to freight list to update our freight IDs. Removed unnecessary useEffect() dependancies.
0.03  09-11-22 DBL   LD0011685 Update scheduleDay
0.02  01-11-22 JRB   Save the pickup when we go past and also allow for editing.
0.01  13-09-22 JDK   Removed REST call, as we don't want to generate a pickup record until the workflow is complete.
0.00	20-04-22 DBL   Created.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import CardForm from '../Common/CardForm';
 import { compactStore } from '../Common/utils';
 import useRest from '../Session/useRest';
import * as newInstructions from '../NewPickup/PageInstructions';
import { freightCardValidation as validation } from "../Configuration/Config";

class init {
  constructor () {
    return new newInstructions.init(); //instructions, quoteNumber, pickupNumber
  }
}

function reducer(store, action) {
  return newInstructions.reducer(store, action);
}

// Copied from NewPickup/PageInstructions. The only difference is the useRest.
function PageInstructions({store, dispatcher, controls}) {
   const [ send, data, saveError, loading ] = useRest('POST', 'quoteConvert');
   const [ update, updateSuccess, updateError, updateLoading] = useRest('POST', 'pickups/edit');
  const [ error, setError ] = useState(null);

  // // when the pickup is saved, move to the next screen
  useEffect(() => {
    if (data) {
      if (data.success === true) {
        dispatcher({page: 'Instructions', name: 'pickupNumber', value: data.pickupNumber});
        dispatcher({page: 'Review', name: 'scheduleDay', value: data.scheduleDay});
        dispatcher({page: 'Freight', name: 'freightList', value: { name: 'updateIDs', value: data.freightItemIDs, validation: validation }}); // Update the ids of our items
        controls.next.call(true);
      } else
        setError(data.message);
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    if (updateSuccess) {
      if (updateSuccess.success === true) {
        dispatcher({page: 'Instructions', name: 'pickupNumber', value: updateSuccess.pickupNumber});
        dispatcher({page: 'Review', name: 'scheduleDay', value: updateSuccess.scheduleDay});
        dispatcher({page: 'Freight', name: 'freightList', value: { name: 'updateIDs', value: updateSuccess.freightItemIDs, validation: validation }}); // Update the ids of our items
        controls.next.call(true);
      } else
        setError(updateSuccess.message);
    }
  }, [updateSuccess]); // eslint-disable-line

  // Display any errors on the screen
  useEffect(() => {
    if (store.instructions.error !== null) setError(store.instructions.error)
    if (saveError !== null) setError(saveError)
    if (updateError !== null) setError(updateError)
   }, [saveError, updateError, store, setError])


  // When next is clicked save the pickup
   const save = (enabled) => {
     if (enabled) {
      if (store.pickupNumber.value === '') {
        send({...compactStore(store), pickupOrConsignment: 0});
      } else {
        update(compactStore(store));
      }
     }
   };


  const handleInput = (inputName, classes) => {
    return ({
      name: inputName,
      value: store[inputName].value,
      className: store[inputName].error ? classes + ' error-outline' : classes,
      onChange: (e) => dispatcher({page: 'Instructions', name: e.target.name, value: e.target.value})
    });
  };

  return (
    <CardForm
      title='Special Instructions'
      className="workflow"
      error={error}
      controls={{...controls, next: {
        ...controls.next,
        call: save,
        enable: error === null && !loading && !updateLoading,
        name: loading || updateLoading ? 'Saving' : 'Next'
      }}}
    >
      <div className="stack">
        <label htmlFor="instructions">Are there any special instructions?</label>
        <textarea {...handleInput('instructions')} />
      </div>
    </CardForm>
  )
}

export default PageInstructions;
export { init, reducer };