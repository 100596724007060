/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  AuthContext.js
Author:    Dean B. Leggo
Version:   0.01
Description:
Handle Idle detection and logging the customer out on session timeout.

History:
0.01  23-08322 DBL   Change setModel to use Modal
0.00	25-07-22 DBL   Created.
----------------------------------- */

import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate as useRouterNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import useAuthenticate from '../Session/useAuthenticate';
import Modal from './Modal';
import useRest from '../Session/useRest';
import { sessionPrompt } from '../Configuration/Config';
import { clearStore } from '../Session/Storage';

const leeway = 2000; // set timeouts x milliseconds before the server will close the connection

function Idle() {
  const { customer, onLogout } = useAuthenticate();
  const navigate = useRouterNavigate();            // go to dashboard to reset location state.
  const [ timerId, setTimerId ] = useState(0);     // id of the main timer for tracking when the server session will expire
  const [ tickerId, setTickerId ] = useState(0);   // id of the second ticker to display the remaining time
  const [ remaining, setRemaining ] = useState(0); // time remaining to display
  const [ isOpen, setIsOpen ] = useState(false);   // Idle modal is open
  const [ sendLogout, logoutData, logoutError ] = useRest('DELETE', 'login');
  const [ sendStayActive, activeData, activeError ] = useRest('GET', 'login');

  const onIdle = () => {
    clearInterval(tickerId);
    setTickerId(0);
    setIsOpen(false);
    timedOut();
  }

  const onActive = () => {
    sendStayActive();
    clearInterval(tickerId);
    setTickerId(0);
    setIsOpen(false);
  }

  const onPrompt = () => {
    if (customer) {
      setRemaining((sessionPrompt - leeway) / 1000)
      setTickerId(setInterval(ticker, 1000))
      setIsOpen(true);
    }
  }

  const idleTimer = useIdleTimer({
    startManually: true,
    stopOnIdle: true,
    startOnMount: false,
    timeout: customer ? customer.timeout - sessionPrompt - leeway : 60*1000,
    promptTimeout: sessionPrompt - leeway,
    events: [
      'keydown',
      'mousedown',
      'touchstart',
      'touchmove',
      'visibilitychange'
    ],
    onIdle,
    onActive,
    onPrompt
  });

  const ticker = () => setRemaining(Math.ceil(idleTimer.getRemainingTime() / 1000));

  // when the timer ends and the customer is idle, logout
  const timedOut = useCallback(() => {
    if (idleTimer.isIdle()) {
      if (customer) sendLogout();
      navigate('/'); // navigate back to dashboard to protect state sharing if logging back in with a different account
      clearStore();
      clearTimeout(timerId);
      setTimerId(0);
      idleTimer.pause();
    }
    else {
      sendStayActive(); // local and server timer will be set back to customer.timeout
    }
  }, [ customer, idleTimer, sendLogout, sendStayActive, timerId, navigate])


  // When the customer data changes, start or stop the timer
  // Should only change when there are server requests!
  useEffect(() => {
    if (customer) {
      if (!isOpen) {
        clearTimeout(timerId); // stop old timer
        setTimerId(setTimeout(timedOut, customer.timeout - leeway)); // start new timer
        clearInterval(tickerId); setTickerId(0); // stop ticker
        setIsOpen(false);
        idleTimer.start(); // start idle check
      }
      // else the modal was open while the screen refreshed, let the timer go.
    }
    else {
      // should be on login screen, disable everything
      clearTimeout(timerId); setTimerId(0);
      clearInterval(tickerId); setTickerId(0);
      setIsOpen(false);
      idleTimer.pause(); // stop idle check
    }
    return () => clearTimeout(timerId); // cleanup function to prevent memory leaks on logout.
  }, [ customer ]) // eslint-disable-line

  useEffect(() => {
    if (logoutData || logoutError)
      onLogout('Session expired');
  }, [onLogout, logoutData, logoutError])

  useEffect(() => {
    if (activeError)
      onLogout(activeError);
  }, [onLogout, activeData, activeError])

  return (isOpen &&
      <Modal
        title={'Session'}
        description={`You will be automaticaly logged out in ${remaining} seconds`}
        yes={{name: 'Continue Session', call: onActive}}
      />
  )
}


export default Idle;