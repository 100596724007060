/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageReview.js
Author:    John D. Kohl
Version:   0.11
Description:
The Review Pickup page for the New Pickup workflow.

History:
0.11  17-07-24 JRB (ld0012843) Added Print Label Button when a consignment is linked.
0.10  30-10-23 JRB  (ld001318) Get BookedAt time from desktop
0.09  08-06-23 JRB  (ld0012128) Made the next button text always "Book Pickup"
0.08  19-05-23 JRB   Added extra message from config if pickup is completed.
0.07  21-03-23 JDK   Sender and Reciever now include company names
0.06  06-01-23 JRB   Removed the schedule date field as requested by prash.
0.05  21-11-22 DB    Removed comma on address line
0.04  08-11-22 DBL   LD0011685 Added scheduleDay
0.03  13-10-22 JRB   Removed all the extra paragraph breaks. Only break if we show data.
0.02  13-10-22 JRB   Removed the setShow state and instead display pickup number if it has a value. Use same logic for quote number.
0.01	23-03-22 JDK   Created.
----------------------------------- */

import React, { useState } from 'react';
import CardForm from '../Common/CardForm';
import { legal } from '../Configuration/Config';
import { useLocation } from 'react-router-dom';
import useNavigate from '../Navigation/useNavigate';
import useAuthenticate from '../Session/useAuthenticate';
import { displayPkpMsg } from '../Common/utils';
import { FreightSummary } from '../Common/FreightCards'
import { useNavigate as useRouterNavigate } from 'react-router-dom';

class init {
  constructor () {
    this.pickupRequired = {value: 0, error: null};
    this.deliveryRequired = {value: 0, error: null};
    this.scheduleDay = {value: null, error: null}; // Date object
    this.bookedAt = {value: null, error: null}
  }
}

// Receives the store property from the NewQuote store and the action
function reducer(store, action) {
  let newValue = action.value;
  switch (action.name) {
    case 'scheduleDay':
      return {
        scheduleDay:  {value: newValue ? new Date(newValue) : null, error: null}
      }

    case 'dateBooked':
      if (newValue) {
        const bookedDate = new Date(newValue)
        return {
          bookedAt: {
            value: bookedDate.toISOString(),
            error: null
          }
        }
      } else {
        return {
          bookedAt: {
            value: null,
            error: null
          }
        }
      }

    case 'loadStore':
      if (newValue.pickupRequired < 0 || newValue.pickupRequired > 3) newValue.pickupRequired = 0;
      if (newValue.deliveryRequired < 0 || newValue.deliveryRequired > 3) newValue.deliveryRequired = 0;
      return {
        ...reducer(store, {name: 'scheduleDay', value: newValue.scheduleDay}),
        ...reducer(store, {name: 'dateBooked', value: newValue.dateBooked}),
        pickupRequired: {value: newValue.pickupRequired, error: null},
        deliveryRequired: {value: newValue.deliveryRequired, error: null}

        // bookedAt: {value: newValue.dateBooked, error: null}
      }

    default:
      throw new Error(`Services Reducer (${action.name}) is not supported`);
  };
}

function PageReview({store, controls}) {
  const location = useLocation();
  const { loggedIn, customer } = useAuthenticate();
  const { navState, changeState } = useNavigate();
  const [ error, setError ] = useState(null)
  const navigate = useRouterNavigate();

  // There is no interaction on the page so do not need useEffects, re-rendering everything is okay.

  const nextCall = (enabled) => {
    // time may have lapsed, validate the scheduled time
    if (store.when.value === 'later' && Date.parse(store.readyAt.value) <= Date.now())
      setError('Scheduled pickup time cannot be in the past');
    else if (enabled)
      controls.next.call(enabled);
  }

  const doLabel = (enabled) => {
    if (enabled) {
      navigate('/consignments/generatelabel', {state: {conNumber: store.conNumber.value}})
    }
  }

  // go directly to the page number passed
  const gotoDetails = (pageNum) => {
    const newMenu = navState.stepArray.map((item, index) => ({ ...item, click: index + 1 <= pageNum })); 
    changeState({ ...navState, stepCurrent: pageNum, stepArray: newMenu});
  }

  const sender = store.sender;
  const receiver = store.receiver;

  const senderAddrLine1 = sender.streetAddress.value.trim();
  const senderAddrLine2 = `${sender.suburb.value}, ${sender.state.value.trim()} ${sender.postCode.value.trim()}`.trim();

  const receiverAddrLine1 = receiver.streetAddress.value.trim();
  const receiverAddrLine2 = `${receiver.suburb.value}, ${receiver.state.value.trim()} ${receiver.postCode.value.trim()}`.trim();
  
  const canEdit = location.pathname === '/quote/convert' || store.status.value === 0;

  const pickupMessage = displayPkpMsg(store.readyAt.value, store.closedAfter.value, null, store.bookedAt.value ? store.bookedAt.value : undefined); // Use undefined so we don't pass anything in if not booked yet.

  return (
    <CardForm
      title='Review Your Pickup'
      controls={canEdit
        ? { ...controls,
            next: {
              ...controls.next,
              name: 'Book Pickup',
              call: nextCall,
              enable: error === null
            }
          }
        : {
          next: {
            ...controls.next,
            name: 'Print Label',
            call: doLabel,
            enable: true
          }
        }
      }
      className="workflow"
      error={error}
    >
      <div className='summary-row'>
        <div className='stack'>
          {canEdit && <div style={{alignSelf: "flex-end"}}>
            <button className='btn__link' onClick={() => gotoDetails(2)}>Edit</button>
          </div>}
          <div className="box" style={{blockSize: "100%"}}>
            <h3 style={{marginBlockEnd: "1rem"}}>Location details</h3> {/* Match spacing with Account */}
            <p className='h3'>Sender</p>
            <p>{`${store.sender.company.value}`}</p>
            <p>{`${senderAddrLine1}`}<br/>{`${senderAddrLine2}`}</p>
            <br/>
            <p className='h3'>Receiver</p>
            <p>{`${store.receiver.company.value}`}</p>
            <p>{`${receiverAddrLine1}`}<br/>{`${receiverAddrLine2}`}</p>
          </div>
        </div>

        <div className='stack'>
          {canEdit && <div style={{alignSelf: "flex-end"}}>
            <button className='btn__link' onClick={() => gotoDetails(3)}>Edit</button>
          </div>}
          <div className="box" style={{blockSize: "100%"}}>
            <h3>Account</h3>
            <p>{loggedIn && customer.company}</p>
            <br/>
            {store.pickupNumber.value !== ''
              ? <><p className='h3'>Pickup Number</p><p>{store.pickupNumber.value}</p><br/></>
              : undefined}
            
            {store.quoteNumber.value !== ''
              ? <><p className='h3'>Quote Number</p><p>{store.quoteNumber.value}</p><br/></>
              : undefined}
            
            {(store.pickupRequired.value === 3 || store.deliveryRequired.value === 3)
              ? <><p>Forklift Required</p><br/></>
              : undefined}
            
            {/* <p style={{marginBlockStart: "auto"}} className='h3' >Scheduled Pickup Date</p>
            <p>{store.scheduleDay.value !== null ? store.scheduleDay.value.toLocaleDateString() : ''}</p> */}

            {pickupMessage.scheduleMsg.value
              ? <><p className='h3'>Please note</p><p>{pickupMessage.scheduleMsg.value}</p></>
              : pickupMessage.scheduleMsg.error
                ? <><p className='h3'>Please note</p><p>{pickupMessage.scheduleMsg.error}</p></>
                : undefined
            }
          </div>
        </div>
      </div>
      
      <FreightSummary store={store.freightList} title={<h3>Freight Details</h3>} edit={canEdit ? () => gotoDetails(3) : null} />
      {store.instructions.value ? <h3 className='accent'>Special Instructions</h3> : ''}
      <p>{store.instructions.value}</p>
      <section className='accent stack'>
        {legal.body}{store.status.value !== 0 ? legal.pickupComplete : '' }
      </section>
    </CardForm>
  )
}

export default PageReview;
export { init, reducer };