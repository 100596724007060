/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  Storage.js
Author:    Dean B. Leggo
Version:   0.02
Description:
Functions to access the local storage on the browser.

History:
0.02  27-03-23 DBL   Add function to clear the session storage
0.01  12-04-22 DBL   Added options to save any data to the session storage
0.00	22-02-22 DBL   Created.
----------------------------------- */

import { companyName } from '../Configuration/Config';
const sessionKey = `ConNote-${companyName.replace(/[^a-zA-Z0-9]/g, '')}`; // remove all special characters and spaces



function sanitiseStorageLabel(s) {
  return s.replace(/\s+/g, '');
}

// get the store object saved in the location (name)
function getStore(name) {
  // console.log(`${sessionKey}-${name}`);
  const store = sessionStorage.getItem(`${sessionKey}-${sanitiseStorageLabel(name)}`);
  if (store) return JSON.parse(store);
  else return null;
}

// save the object (store) into the location (name)
function setStore(name, store) {
  sessionStorage.setItem(`${sessionKey}-${sanitiseStorageLabel(name)}`, JSON.stringify(store));
}

// delete the object saved in the location (name)
function removeStore(name) {
  sessionStorage.removeItem(`${sessionKey}-${sanitiseStorageLabel(name)}`);
}

// delete everything in the store
function clearStore() {
  sessionStorage.clear();
}

// Versions to access the session store
function getSession() {
  return getStore('session');
}

function setSession(connoteCustomer) {
  setStore('session', connoteCustomer);
}

function removeSession() {
  removeStore('session');
}

// update the last active timestamp with the latest ISO8601 string
function updateTimestamp(timestamp) {
  const connoteCustomer = getSession();
  if (connoteCustomer) {
     connoteCustomer.lastActive = timestamp;
     setSession(connoteCustomer);
  }
}

export { getSession, setSession, removeSession, updateTimestamp, getStore, removeStore, clearStore, setStore }