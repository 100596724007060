/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  CardForm.js
Author:    Dean B. Leggo
Version:   0.10
Description:
Container for a paged form.

History:
0.10  14-06-23 DBL   (ld0012126) Created CardLink
0.09  06-10-22 JRB   Added the ability to disable card buttons
0.08  14-06-22 DBL   Added option for warning message and tabbing on a modal.
0.07  19-04-22 ADM   Tweaked the ternary to also check for the presence of error messages. 
0.06  14-04-22 ADM   With help from John, added ternary to conditional add margin-inline-start of auto to the next button when there is not a previous button.
0.05  13-04-22 ADM   Change class from card__form to box card stack. Swapping classes out for stack. Swapped out 'fl-row' for 'cluster cluster-outer' and btn--pair for a nested cluster
0.04  02-03-22 ADM   Updated row to be fl-row.
0.03  02-03-22 DBL   Moved error from controls to the main line.
0.02  01-03-22 ADM   Shift error message, added classNames to the divs wrapping the buttons inside the Nav Div
0.01  01-03-22 DBL   Added error message
0.00	25-02-22 DBL   Created.
----------------------------------- */

import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

/*
 * Card Form takes 4 variables, three are for each button;
 * - title - to be placed in the header.
 * - controls
 *     - previous (optional, defaults to not displayed)
 *     - next     (optional, defaults to not displayed)
 *     - custom   (optional, defaults to not displayed)
 * - error - error text to display in the footer.
 * - warning - Displayed the same as error.
 * - className - string of any class names to 
 * 
 * Each controls button has the following 3 variables
 * name - name to display on the button (optional, defaults to variable name,
 *                                       mandatory for the customState button)
 * enable - have the button clickable or greyed out (optional, defaults to enabled)
 * call - the onClick function to call back (mandatory)
 */
function CardForm({children, title, controls, error, warning, className} ) {
  const [ previousState, setPrevious ] = useState(null);
  const [ nextState, setNext ] = useState(null);
  const [ customState, setCustom ] = useState(null);
  const titleRef = useRef(null);

  useEffect(() => titleRef.current.focus(), []) // On load set the focus on the title

  // track any changes to controls and update the displayed buttons
  // need useState or react will not update the buttons after the first load
  useEffect(() => {
    if (controls) {
      const { previous, next, custom } = controls;
      if (previous) setPrevious(previous);
      else setPrevious(null);

      if (next) setNext(next);
      else setNext(null);

      if (custom) setCustom(custom);
      else setCustom(null);
    }
    else {
      setPrevious(null);
      setNext(null);
      setCustom(null);
    }
  }, [controls]);

  // set the defaults for the optional variables
  if (previousState) {
    if (!previousState.hasOwnProperty('name')) setPrevious({...previousState, name: 'Previous'});
    if (!previousState.hasOwnProperty('enable')) setPrevious({...previousState, enable: true});
    if (!previousState.hasOwnProperty('call')) throw new Error('The previous button needs a call function');
  }

  if (nextState) {
    if (!nextState.hasOwnProperty('name')) setNext({...nextState, name: 'Next'});
    if (!nextState.hasOwnProperty('enable')) setNext({...nextState, enable: true});
    if (!nextState.hasOwnProperty('call')) throw new Error('The next button needs a call function');
  }
  
  if (customState) {
    if (!customState.hasOwnProperty('enable')) setCustom({ ...customState, enable: true });
    if (!customState.hasOwnProperty('name')) throw new Error('The custom button needs a name');
    if (!customState.hasOwnProperty('call')) throw new Error('The custom button needs a call function');
  }

  // WARNING!! Any buttons inside the form will trigger onSubmit, please add type='button' to all buttons
  const handleSubmit = (e) => {
    e.preventDefault();
    nextState.call(nextState.enable);
  };

  return (
    <div className="center">
      <div className={className ? "card stack cardForm-container " + className : "card stack cardForm-container"}>
        <h2 tabIndex='-1' ref={titleRef}>{title}</h2>
        <form id='CardForm' className='stack' onSubmit={handleSubmit}>
          {children}

          {(previousState || nextState || customState || error || warning) &&
            <nav className='accent'>
              <div className="cluster cluster-outer cluster-no-wrap">
                {previousState &&
                  <button 
                    type='button'
                    id={previousState.id}
                    className={previousState.enable ? 'btn' : 'btn disabled'}
                    aria-disabled={previousState.enable ? undefined : "true"}
                    aria-describedby={previousState.enable ? undefined : "please complete the required actions"}
                    onClick={() => previousState.call(previousState.enable)}
                  >
                    {previousState.name}
                  </button>
                }
                {(error || warning) &&
                  <div className='cluster cluster-no-wrap'>
                    <div className='error-msg'>
                      <FontAwesomeIcon icon={faTriangleExclamation} size='2x' />
                    </div>
                    <div className='stack'>
                      {error && <span className="error-msg">{error}</span>}
                      {warning && <span className="warning-msg" style={{marginBlockStart: '0'}}>{warning}</span>}
                    </div>
                  </div> 
                }
                <div className={previousState || error || warning ? 'cluster cluster-no-wrap' : 'cluster cluster-no-wrap margin-inline-start-auto'}>
                  {customState &&
                    <button
                      type='button'
                      className={customState.enable ? 'btn' : 'btn disabled'}
                      aria-disabled={customState.enable ? undefined : "true"}
                      aria-describedby={customState.enable ? undefined : "please complete the required actions"}
                      onClick={() => customState.call(customState.enable)}
                    >
                      {customState.name}
                    </button>
                  }
                  {nextState &&
                    <input 
                      type='submit'
                      id={nextState.id ? nextState.id : 'next'}
                      form='CardForm'
                      className={nextState.enable ? 'btn' : 'btn disabled'}
                      aria-disabled={nextState.enable ? undefined : "true"}
                      aria-describedby={nextState.enable ? undefined : "please complete the required actions"}
                      value={nextState.name}
                      hidden={nextState.hidden ? true : undefined}
                    />
                  }
                </div>
              </div>
            </nav>
          }
        </form>
      </div>
    </div>
  );
}


// Large checkbox with an image
// IN: title - text to place on the button
// IN: name - the name of the property to update
// IN: icon - the icon object to place in the button
// IN: onChange - function to call on state change
// IN: checked - the state (bool) of the button
// IN: disabled - The disabled state of the button
function CardButton({title, id, name, icon, onChange, checked, className, disabled}) {
  const [ checkedState, setChecked ] = useState(checked);
  const [ classState, setClass ] = useState(className);
  const [ disabledState, setDisabled ] = useState(disabled ? true : undefined);
  useEffect(() => {
    setChecked(checked);
    setClass(className);
    setDisabled(disabled ? true : undefined);
  }, [checked, className, disabled]);

  return (
    <label id={id} className={disabledState 
        ? classState + ' btn btn__light disabled' 
        : (checkedState 
          ? classState + ' btn btn__light checked' 
          : classState + ' btn btn__light')
    }>
      <input type='checkbox'
        className='checkbox__hidden'
        name={name}
        onChange={onChange}
        checked={checkedState}
        disabled={disabledState}
      />
      {icon}
      {title}
    </label>
  );
}

// Large Button with an image
// IN: title - text to place on the button
// IN: name - the name of the property to update
// IN: icon - the icon object to place in the button
// IN: onClick - function to call on state change
// IN: disabled - The disabled state of the button
function CardLink({title, name, icon, onClick, className, disabled}) {
  const [ classState, setClass ] = useState(className);
  const [ disabledState, setDisabled ] = useState(disabled ? true : undefined);
  useEffect(() => {
    setClass(className);
    setDisabled(disabled ? true : undefined);
  }, [className, disabled]);

  return (
    <label className={disabledState 
        ? classState + ' btn btn__light disabled' 
        : classState + ' btn btn__light'
    }>
      <input type='button'
        className='checkbox__hidden'
        name={name}
        onClick={onClick}
        disabled={disabledState}
      />
      {icon}
      {title}
    </label>
  );
}

export default CardForm;
export { CardButton, CardLink };