/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  utils.js
Author:    Dean B. Leggo, John D. Kohl
Version:   0.08
Description:
Random utility functions.

History:
0.10  11-03-24 JRB   LD0012659 - Validate Delivery Schedule.
0.09  01-11-23 JRB   LD0012318 - Calulate a "currentTime" based off the booked time and pass it to displayPkpMsg
0.09  27-10-23 JRB   LD0012318 - Added bookedAt to displayPkpMsg
0.08  28-08-23 JDK   LD0012210 - Added helper function for loading customer specific defaults.
0.07  14-06-23 DBL   Added timeZones and convertTimeZone for use with NT
0.06  17-05-23 JDK   LD0012035 - Added check for parameter value to getRates() to allow it to 'bail out'.
0.05  17-05-23 JDK   LD0012035 - Altered getRates() to filter out default rates if a customer's rate is available.
0.04  02-11-22 JRB   LD0011807 changed customSelect to work on available instead of depot
0.03  08-11-22 DBL   LD0011685 Added dateConstruct
0.02  14-06-22 DBL   Added email regex
0.01  19-04-22 DBL   Moved in isDev and ImportAll
0.00	13-04-22 DBL   Created.
----------------------------------- */

import { createFilter, components } from 'react-select';
import { pickupValidation, deliveryValidation } from '../Configuration/Config';

/*
 * Prepares a store for transferring the store to Omnis, by removing all error and _ properties 
 */
function compactStore(store) {
  if (typeof store === 'object' && !Array.isArray(store) && store !== null) {
    // we have an object
    if (store.hasOwnProperty('value')) {
      return compactStore(store['value']); // only return the value property
    }
    else {
      let object = {};
      Object.keys(store)
        .filter(key => key !== 'error' && !key.startsWith('_'))
        .forEach(key => object[key] = compactStore(store[key]));
      return object;
    }
  }
  else if (Array.isArray(store)) {
    // we have an array
    return store.map(item => compactStore(item));
  }
  // we have a value, null, or undefined
  return store;
}


/*
 * Create a date object using the current date and the provided time string (in format "12:34:56", seconds optional)
 */
function dateFromTimeString(time) {
  if (time) {
    let myDate = new Date(Date.now())
    let [h, m, s] = time.split(':')
    s = s ? s : 0; // if seconds exist, fine, otherwise we get null, so set seconds to 0
    return(myDate.setHours(h, m, s));
  } else
    return null
}


/*
 * return human readable string of current time, e.g. 13:57
 */
const currentTimeString = () => new Date(Date.now())
  .toTimeString()
  .split(' ')[0] // only get the time, not the timezone
  .split((':'))
  .filter((_, i) => i < 2) // only get hours and minutes, not seconds
  .reduce((p, c, i) => i === 0 ? c : `${p}:${c}`);


/*
 * return current seconds since midnight (00:00:00)
 */
const currentSeconds = () => new Date(Date.now())
  .toTimeString()
  .split(' ')[0] // only get the time, not the timezone
  .split(':')
  .reduce((p, c) => p*60 + c*1)/60;


/* 
 * return a string formated by TYPE from the DATE object
 * full = YYYY-MM-DD HH:MM
 * date = YYYY-MM-DD
 * time = HH:MM
 */
function dateConstruct(type, date) {
  const d = new Date(date)
  if (type === 'full')
    return `${String(d.getFullYear()).padStart(4,'0')}-${String(d.getMonth()+1).padStart(2,'0')}-${String(d.getDate()).padStart(2,'0')} ${String(d.getHours()).padStart(2,'0')}:${String(d.getMinutes()).padStart(2,'0')}`
  if (type === 'date')
    return `${String(d.getFullYear()).padStart(4,'0')}-${String(d.getMonth()+1).padStart(2,'0')}-${String(d.getDate()).padStart(2,'0')}`
  if (type === 'time')
    return `${String(d.getHours()).padStart(2,'0')}:${String(d.getMinutes()).padStart(2,'0')}`
}


/*
 * returns 0 if the two dates are on the same day. sameDay(22 Jul 22 17:00, 22 Jul 22 6:40). returns 0
 * returns -ve if date1 is after date2. sameDay(01 Aug 22 13:00, 26 Jan 22 13:00). returns a negative number
 * returns +ve if date1 is before date2. sameDay(14 Nov 22 14:30, 16 Nov 22 10:00). returns a positive number
 */
function sameDay(date1, date2) {
  let d1 = new Date(date1.getTime()) // copy
  d1.setHours(0, 0, 0, 0) // set to midnight

  let d2 = new Date(date2.getTime()) // copy
  d2.setHours(0, 0, 0, 0) // set to midnight

  return d2.getTime() - d1.getTime()
}


/*
 * Import all files (during build) from the Webpack's require.contents()
 * https://webpack.js.org/guides/dependency-management/#requirecontext
 */
const importAll = (requirements) => {
  let object = {};
  requirements.keys().forEach(key => object[key.replace('./', '')] = requirements(key))
  return object;
};


/*
 * Check if we are running in a development environment
 */
const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';


/*
 * Converts A String To Title Case
 */
const titleCase = (string) => string
  .toLowerCase()
  .split(' ')
  .map(word => (word.charAt(0).toUpperCase() + word.slice(1)))
  .join(' ')
  .split("'")
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join("'")
  .split("-")
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join("-");


/*
 * Email regex is from http://emailregex.com/
 * !emailRegex.test(myString)
 */
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/*
 * Returns the depot matching the item
 * IN:  item {suburb, postCode}
 * IN:  depots Array[{depot, postFrom, postTo}]
 * OUT: the matching depot or null
 */
function matchingDepot(item, depots) { /* dead code? */
  let depot = undefined
  if (item) {
    depot = depots.find(d => item.suburb === d.depot) // matching suburb
    if (depot === undefined)
      depot = depots.find(d => // post code is within a depot range
        d.postfrom !== null && d.postto !== null ? d.postfrom <= item.postcode && item.postcode <= d.postto : false
      )
  }
  return depot ? depot : null
}

/*
 * Checks if the entered string is within the bounds, if so it returns the number or
 * the incorrect string with an error message.
 * value: will be entered as a string and may be incorrect during typing
 * name:  to display at the start of an error message
 * bound: the number should be between `lower`, `upper`, and be rounded to `digits`
 *        (all optional)
 * units: on the end of the bound error message (optional)
 * returns - an array of number and error
 */
function checkNumber(value, name, bound, units) {
  const { lower, upper, digits = 0 } = bound || {}
  const trimRX = new RegExp(digits ? `(?<=\\..{${digits}}).+` : `\\..*`); // If digits is zero, truncate input to integer (no '.' or digits thereafter). Otherwise, truncate input to number of digits after '.'
  let error = null

  if (typeof value === 'string') {
    if (value !== '0' && value.includes('.') && value.endsWith('.')) // decimal number
      error = `${name} is not a complete number`
    value = value.replace(trimRX,'')
  }
  
  let number = Number(value)

  if (isFinite(number)) { // is a valid number
    number = parseFloat(number.toFixed(digits)) // round the number

    if (lower && number < lower)
      error = `${name} cannot be less than ${lower.toLocaleString()}${units ? ' ' + units : ''}`
    else if (upper && number > upper)
      error = `${name} cannot exceed ${upper.toLocaleString()}${units ? ' ' + units : ''}`
  }
  else
    error = `${name} is not a number`

  if (error) 
    return [value, error] // return the original value
  else
    return [number === Number(value) ? value: number, null] // return the converted value
}

/*
 * Filters an object by calling the callback on every property.
 * callback - (name, property) => return boolean
 * Not tested
 */
function filterObject(object, callback) {
  return Object.fromEntries(Object.entries(object).filter(([key, val]) => callback(key, val)));
}

/*
 * Applies a map to an object by calling the callback on every property.
 * callback - (name, property) => return newProperty
 * Not tested
 */
function mapObject(object, callback) {
  return Object.fromEntries(Object.entries(object).map(([key, val]) => [key, callback(key, val)]));
}

/*
 * Applies a foreach to an object by calling the callback on every property.
 * callback - (name, property) => ()
 * Not tested
 */
function foreachObject(object, callback) {
  Object.entries(object).forEach(([key, val]) => callback(key, val));
  return;
}

function sortSuburbs(a, b) { // custom sort function for suburbs object. used in NewQuote.js, ConvertQuote.js, and NewPickup.js.
  let suburb_a = a.suburb;
  let state_a = a.state;
  let suburb_b = b.suburb;
  let state_b = b.state;

  
  // // sort on state first.
  if (state_a !== state_b) return state_a > state_b ? 1 : -1; // only sort by state if the compared states are different.
  else return suburb_a > suburb_b ? 1 : ((suburb_a < suburb_b) ? -1 : 0 ) // otherwise, sort normally
}

const customSelect = {
  // the react select object must contain depot
  filterOption: createFilter({ignoreAccents: false, stringify: option => option.label}), // search label only
  components: {Option: (props) => {
    delete props.innerProps.onMouseMove
    delete props.innerProps.onMouseOver
    return <components.Option {...props}>
      <div className={props.data.available ? 'valid-depot' : 'no-depot' }>
        {props.children}
      </div>
      
    </components.Option>
  }},
  isClearable: true,
  isSearchable: true,
  placeholder: 'Search…',
  classNamePrefix: 'ReactSelect',
}
Object.freeze(customSelect)


const timePattern = "2[0-3]|([0-1])?[0-9]:[0-5][0-9]";  // sane 24Hr time strings only


// takes a time input value (e.g. "12:34:56"), use utility function "currentSeconds" in utils.js to operate on a Date object
function time2Seconds(t) {
  const timeRegex = new RegExp(timePattern);
  if (timeRegex.test(t)) {
    const l = t.split(':').length;
    const a = t.split(':').reduce((a,b) => a*60 + b*1); // hours to seconds, plus minutes to seconds, plus seconds (if they exist)
    if (l < 3) { // hours and minutes only, so we need to multiply again to get seconds
      return(a*60);
    } else {
      return(a);
    }
  } else {
    return(null); // null if regex fails, if we are in the middle of entering this could happen
  }
}


function displayPkpMsg(timeReady, timeClosed, error, bookedAt) {
  // timeReady - ISO String
  // timeClosed - HH:MM

  if (timeReady && timeClosed && error === null) {
    const readyAt = timeReady === 'NOW' ? new Date() : new Date(timeReady);
    const middnight = new Date(readyAt).setHours(0,0,0,0);
    const closingTime = new Date(middnight + time2Seconds(timeClosed)*1000);

    // console.log(bookedAt)
    let currentTime = undefined
    if  (bookedAt !== undefined) {
      // currentTime = new Date(middnight + time2Seconds(bookedAt)*1000) 
      currentTime = new Date(bookedAt)
    }

    const [ message, error ] = pickupValidation.pickupTimeValidation(closingTime, readyAt, currentTime);
    return ({scheduleMsg: {value: message, error: error}});
  } else {
    return ({scheduleMsg: {value: '', error: null}});
  }
}


function mergeDestinations(suburbList, depots) {
  // Returns a suburb list, removing duplicate suburbs, concatenating the suburbs' depots
  // console.log('doing a merge')
  const length = suburbList.length;
  let list = [];
  const suburbs = suburbList.map(item => ({
    ...item,
    label: `${titleCase(item.suburb)}, ${item.postCode}, ${item.state}`,
    value: `${item.suburb}, ${item.state}, ${item.postCode}`, // no longer adding the depot
    available: depots.findIndex(item2 => item2.depotFrom === item.depot) !== -1,
    depot: item.depot ? [item.depot] : []
  }));

  for (let i = 0; i < length; i++) {
    let suburb = suburbs[i];
    while(suburbs[i+1] && suburb.label === suburbs[i+1].label) {
      // Merge, we have a duplicate suburb but different depot.
      if (suburbs[i+1].depot.length !== 0) 
        suburb.depot.push(suburbs[i+1].depot[0]); // copy depot
      suburb.available = suburb.available || suburbs[i+1].available; // any depot is a from depot
      i++; // skip duplicate
    }
    list.push(suburb);
  }

  return list.sort(sortSuburbs);
}


function matchDestinations(fromSuburb, suburbList, depotList) {
  // Returns a list of suburbs the fromSuburb travels to.
  let depots = depotList // all depots this suburb's depots go to
    .filter(d => fromSuburb.depot.includes(d.depotFrom))
    .map(d => d.depotTo)
  depots = [...new Set(depots)] // remove duplicates
  
  let list = suburbList.filter(s =>
    s.suburb !== fromSuburb.suburb && // cannot go to the same suburb
    s.depot.findIndex(d => depots.includes(d)) > -1
  )
  return list.map(item => ({...item, available: true}))
}


function depotExists(suburb, depots, type) {
  // Returns true if one of the suburb's depots is in the depot's list.
  // type = 'depotFrom', 'depotTo'
  return suburb.depot.findIndex(sd => // find a suburb's depot
    depots.findIndex(d => d[type] === sd)  > -1 // that is in the depots list
  ) > -1
}

function getRates(fromSuburb, toSuburb, depots) { // sender THEN receiver. 
  // Returns a list of rates if one of the suburbs' depot pairs exist in the depots list
  // This needs to match oDestination.$getRate()
  // console.log('getRates', toSuburb, fromSuburb, depots);
  let result = null; // Define our output now, so we only need one "return".
  if (fromSuburb && toSuburb && depots) {
    let depot = undefined;
    fromSuburb.depot.findIndex(fsd =>
      toSuburb.depot.findIndex(tsd => {
        depot = depots.find(d => {
          return d.depotFrom === fsd && d.depotTo === tsd;
        }) 
        return depot !== undefined // found the highest priority depot
      }) > -1
    )
    if (depot) {
      result = depots.filter(d => d.depotFrom === depot.depotFrom && d.depotTo === depot.depotTo); // All rates matching this combination.
      if (result.some(d => d.default === false)) // Are any filtered rates from the customer pricelist?
        result = result.filter(d => d.default === false);
    }
  }
  return result;
}

function parseOptions(options) {
  if (options) {
    if (Object.keys(options).length) {
      let modifiedOptions = {
        ...options,
        suburbs: mergeDestinations(options.suburbs, options.depots)
      }
      let sender = modifiedOptions.customerDefaults.defaultSender;
      modifiedOptions = {
        ...modifiedOptions,
        customerDefaults: {
          ...modifiedOptions.customerDefaults,
          defaultSender: sender.id ? {
            ...sender,
            cityState: {
              ...modifiedOptions.suburbs.find(s => ['suburb','state','postCode'].every(key => s[key].localeCompare(sender[key]) === 0) ),
              depots: modifiedOptions.depots
            }
          } : null
        }
      }
      return modifiedOptions
    }
  }
  else return {}
}

const timeZones = [
  // array of states and their IANA timezone name
  {state: 'WA',  name: 'Australia/Perth'},
  {state: 'NT',  name: 'Australia/Darwin'},
  {state: 'SA',  name: 'Australia/Adelaide'},
  {state: 'QLD', name: 'Australia/Brisbane'},
  {state: 'NSW', name: 'Australia/Sydney'},
  {state: 'ACT', name: 'Australia/Canberra'},
  {state: 'VIC', name: 'Australia/Melbourne'},
  {state: 'TAS', name: 'Australia/Hobart'}
]

function convertTimeZone(date, state) {
  // IN: date - date-time string or Date object
  // IN: state - name of the state to convert the date to.
  //             It will use daylight savings.
  // OUT: Date object in the state's timezone
  const tz = timeZones.find(tz => tz.state === state);
  let newDate = typeof date === "string" ? new Date(date) : date;
  if (tz)
    newDate = new Date(newDate.toLocaleString("en-US", {timeZone: tz.name}))

  return newDate
}

function displayDelivMsg(dateReady, rate, error) {
  if (dateReady && error === null) {
    const readyAt = new Date(dateReady);
    const [ message, error ] = deliveryValidation.deliveryTimeValidation(readyAt,rate);
    return ({scheduleMsg: {value: message, error: error}});
  } else {
    return ({scheduleMsg: {value: '', error: null}});
  }
}

export {
  compactStore,
  dateFromTimeString,
  dateConstruct,
  importAll,
  isDev,
  currentTimeString,
  currentSeconds,
  titleCase,
  emailRegex,
  matchingDepot,
  filterObject,
  mapObject,
  foreachObject,
  checkNumber,
  sortSuburbs,
  customSelect,
  sameDay,
  timePattern,
  time2Seconds,
  displayPkpMsg,
  mergeDestinations,
  matchDestinations,
  depotExists,
  getRates,
  parseOptions,
  timeZones,
  convertTimeZone,
  displayDelivMsg
};
