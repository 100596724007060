/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  Layout.js
Author:    Dean B. Leggo
Version:   0.03
Description:
The static components of the screen that the different pages will be rendered into.

History:
0.03  06-10-22 PWM   (ld0011661) Changed how the Contact Us link works. Now goes to external site set in config.
0.02  24-06-22 DBL   Added the Modal
0.01  24-02-22 DBL   Changed to use useAuthenticate and customer, added useNavigate
0.00	22-02-22 DBL   Created.
----------------------------------- */

import React, { useEffect } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/pro-regular-svg-icons';
import useAuthenticate from '../Session/useAuthenticate';
import useNavigate from './useNavigate';
import useRest from '../Session/useRest';
import { contactUs, companyLogo, companyName } from '../Configuration/Config';
import ProgressSteps from './ProgressSteps';
import './Layout.css';
import Idle from './Idle';

// The order of the elements for layout
function Layout() {
  return (
    <>
      <Idle />
      <Header />
      <Outlet />
      {/* <Footer /> */}
    </>
  );
}

function Header() {
  const { navType } = useNavigate();

  switch (navType) {
    case 'default': return (<DefaultHeader />);
    case 'dashboard': return (<DashboardHeader />);
    case 'stepper': return (<ProgressHeader />);
    default: return (<></>);
  }
}


function DefaultHeader() {
  const { customer, loggedIn } = useAuthenticate();
  const { navState } = useNavigate();

  return (
    <header>
      <div className="header__primary">
        <div className="container cluster cluster-outer" id='topHeader'>
          <img src={companyLogo} alt={'Shipping with ' + companyName} className='companyLogo' />
          <div className='cluster'>
            <div className='stack' style={{"--space": "0rem"}}>
              {loggedIn ?
                <>
                  <span>Logged in:
                    {customer.name != null ? ' ' + customer.name : ''}
                  </span>
                  <span>Company:
                    {customer.company != null ? ' ' + customer.company : ''}
                  </span>
                </>
                : <span>Not logged in</span>
              }
            </div>
            <Link to='/settings' className="btn" aria-label='Settings'>
              <FontAwesomeIcon icon={faGear} title='Access your settings' size='2x'/>
            </Link>
          </div>
        </div>
      </div>
      <div className="header__secondary">
        <div className="container">
          <nav className='cluster cluster-outer'>
            <h1>{navState}</h1>
            <div id='nav-aside' className='cluster'>
              <Link to='/' className='btn'>Dashboard</Link>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}


function DashboardHeader() {
  const { customer, loggedIn, onLogout } = useAuthenticate();
  const [ sendLogout, logoutData, logoutError ] = useRest('DELETE', 'login');
  let activeClassName = 'activeButton';
  let linkClassName = ' btn';

  useEffect(() => {
    if (logoutData || logoutError) onLogout('');
  }, [onLogout, logoutData, logoutError])

  return (
    <header>
      <div className="header__primary">
        <div className="container cluster cluster-outer" id='topHeader'>
          <img src={companyLogo} alt={'Shipping with ' + companyName} className='companyLogo' />
          <div className='cluster'>
            <div className='stack' style={{"--space": "0rem"}}>
              {loggedIn ?
                <>
                  <span>Logged in:
                    {customer.name != null ? ' ' + customer.name : ''}
                  </span>
                  <span>Company:
                    {customer.company != null ? ' ' + customer.company : ''}
                  </span>
                </>
                : <span>Not logged in</span>
              }
            </div>
            <Link to='/settings' className="btn" aria-label='Settings'>
              <FontAwesomeIcon icon={faGear} title='Access your settings' size='2x'/>
            </Link>
          </div>
        </div>
      </div>
      <div className="header__secondary">
        <div className="container">
          <nav className='cluster cluster-outer'>
            <h2 className='h1'>Dashboard</h2>{/* LD0011667 This is the display name for the dashboard. JDK 02-11-22*/}
            <div id='nav-aside' className='cluster'>
              <button type='button' className='btn button' onClick={() => window.open(contactUs, '_blank')}>Contact Us</button>
              {loggedIn === true && customer.isAdmin &&
                <NavLink to='/settings/myusers' className={({ isActive }) => isActive ? activeClassName + linkClassName : linkClassName}>Users</NavLink> /* LD0011667 This is the button name for the MyUsers.js component. JDK 02-11-22*/
              }
              <button type='button' id="logout" className='btn button' onClick={() => sendLogout()}>Logout</button>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}


function ProgressHeader() {
  const { customer, loggedIn } = useAuthenticate();

  return (
    <header>
      <div className="header__primary">
        <div className="container cluster cluster-outer" id='topHeader'>
          <img src={companyLogo} alt={'Shipping with ' + companyName} className='companyLogo' />
          <div className='cluster'>
            <Link to='/' className='btn btn__db'>Return to Dashboard</Link>
            <div className='stack' style={{"--space": "0rem"}}>
              {loggedIn ?
                <>
                  <span>Logged in:
                    {customer.name != null ? ' ' + customer.name : ''}
                  </span>
                  <span>Company:
                    {customer.company != null ? ' ' + customer.company : ''}
                  </span>
                </>
                : <span>Not logged in</span>
              }
            </div>
            <Link to='/settings' className='btn' aria-label='Settings'>
              <FontAwesomeIcon icon={faGear} title='Access your settings' size='2x'/>
            </Link>
          </div>
        </div>
      </div>
      <div className="header__secondary">
        <ProgressSteps />
      </div>
    </header>
  );
}

export default Layout;