/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageConsignment.js
Author:    Jordan R. Bock
Version:   0.02
Description:
The Consignment download page for the Drop Freight workflow.

History:
0.02  11-03-24 JRB  LD0012666 Inform user to print and bring along connote when a eConNote has been booked.
0.01	05-02-24 JRB   Created.
----------------------------------- */

import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate as useRouterNavigate } from 'react-router-dom'
import CardForm, { CardButton } from '../Common/CardForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faPrint, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { faClipboardListCheck, faDesktopArrowDown } from '@fortawesome/pro-solid-svg-icons';
import useRest from '../Session/useRest';
import JsFileDownloader from 'js-file-downloader';
import print from 'print-js';
import { BeatLoader } from 'react-spinners';
import useAuthenticate from '../Session/useAuthenticate';
import useNavigate from '../Navigation/useNavigate';
import { companyName } from '../Configuration/Config';
import { compactStore } from '../Common/utils';

class init {

}

function reducer(store, action) {
  let error = null;
  let newValue = action.value;

  switch(action.name) {
     case 'loadStore':
      return {
      };

    default: {
      throw new Error(`Schedule Pickup Reducer (${action.name}) is not supported`);
    }
  }
    
  // return { [action.name]: { value: newValue, error: error } };
}

function PageConsignmentDownload({store, dispatcher, controls}) {
  const consignNumber = (store.conNumber && store.conNumber.value) ? store.conNumber.value : null;
  const readyDate = (store._readyAtDate && store._readyAtDate.value) ? store._readyAtDate.value : null;
  // Hooks, custom or otherwise.
  // const { authModal } = useAuthenticate()
  // const { navState, changeState } = useNavigate()
  const navigate = useRouterNavigate();
  const display = (store.conType.value === 'electronic') ? 2 : 1;
  // stateful constants
  // const [customCtrls, setCustCtrls] = useState({});

  const modal = {
      title: 'Drop Freight In booked',
      description: [
        `Your consignment number is ${consignNumber}`,
        <br />,
        `Your drop freight has been booked for ${readyDate}`,
        <br />,
        `Please print this con note and bring in with your freight.`
      ],
      yes: {name: 'Return to Dashboard', call: () => navigate('/')}
    }

  return (
    <CardForm title="Booked Dropfreight" className='workflow'
      controls={{}} 
    >
      {display === 1 && <ManualConsignment store={store}  />}
      {display === 2 && <ElectronicConsignment store={store} navigate={navigate} modal={modal}/>}
    </CardForm>
  )
}

function ManualConsignment({store}) {
  return (
    <section className='accent'>
      <div className='stack'>
        {/* <p className='h3'>Manual Consignment</p> */}
        <p>Thank you for using the Bishops Transport Customer Portal.</p>
        <p>Your drop in has been booked for {store._readyAtDate.value}.</p>
      </div>
    </section>
  )
}

function ElectronicConsignment({store, setError, modal}) {
  const { authModal } = useAuthenticate();
  const [ retrieve, url ] = useRest('PDF', 'documents/pdf'); // no error handling, as useREST only returns the URL for the PDF.
  const [ generate, status, , docLoading ] = useRest('GET', 'documents/generate');

  const [ saveChecked, setSaveChecked ] = useState(false);
  const [ printChecked, setPrintChecked ] = useState(false);

  const [ disableSavePDF, setDisableSavePDF ] = useState(false);
  const [ disablePrintPDF, setDisablePrintPDF ] = useState(false);
  // const [ error, setError ] = useState();


  const handlePDF = useCallback((conNum) => {
    // console.log(conNum, status, docError, docLoading);
    let print = true;
    if (conNum && !docLoading) { // always need a conNum, and don't send a request if one already in progress
        if (status && status.success) {
          if (status.message === conNum)
            retrieve({docRN: conNum, docType: 'CON', print: print}); // get the PDF.
          else 
            generate({docRN: conNum, docType: 'CON', print: print});
        }
        else if (status && !status.success) {
          // if (status.message !== `${conNum} not found`)
            generate({docRN: conNum, docType: 'CON', print: print});
          // else
          //   setGenError(`${conNum} not found`);
        }
        else if (!status)
          generate({docRN: conNum, docType: 'CON', print: print});
      }
  }, [status, docLoading, retrieve, generate])

  useEffect(() => {
    store.conNumber.value && handlePDF(store.conNumber.value);
  }, [store, status, handlePDF]);

  const handlePrint = (enabled) => {
    if (enabled) {
      setPrintChecked(true);
      setDisablePrintPDF(true); // Only allow one click for now
      print({ // onLoadingStart, onLoadingEnd
        printable: url,
        onError: () => {
          setError('Failed to download the PDF');
          setDisablePrintPDF(false);
          setPrintChecked(false);
        },
        onLoadingEnd: () => { setPrintChecked(false); setError(null); authModal(modal); }
      });
      // TODO: indicate it is loading and if there are any errors.
    }
  }
  
  const handleSave = (enabled) => {
    if (enabled) {
      setSaveChecked(true);
      setDisableSavePDF(true); // Only allow one click for now
      const download = new JsFileDownloader({
        url: url,
        autoStart: false,
        forceDesktopMode: true,
        filename: `Consignment ${store.conNumber.value}.pdf`
      });
      download.start()
        .then(() => { setError(null) })
        .catch(() => {
          setError('Failed to download the PDF')
          setDisableSavePDF(false) // re-enable on error.
        })
        .finally(() => { setSaveChecked(false); authModal(modal) })
    }
  }

  return (
    <section className='accent'>
      <div className='stack'>
        <p className='h3'>Electronic Consignment Note</p>
        {store.conNumber.value
          ? <>
              <p>An electronic Consignment Number {store.conNumber.value} has been generated for this freight.</p>
              {url
                ? <div className='cluster cluster-outer' style={{justifyContent: 'center', alignItems: 'stretch'}}>
                    <CardButton
                      name='eConsignment'
                      title={saveChecked ? 'Downloading PDF' : 'Save as PDF'}
                      id={true}
                      icon={<FontAwesomeIcon icon={faFloppyDisk} size='4x'/>}
                      checked={saveChecked}
                      onChange={handleSave}
                      className='stack'
                      disabled={disableSavePDF}
                    />
                    <CardButton
                      name='eConnote'
                      title={printChecked ? 'Printing PDF' : 'Print connote' }
                      id={false}
                      icon={<FontAwesomeIcon icon={faPrint} size='4x'/>}
                      checked={printChecked}
                      onChange={handlePrint}
                      className='stack'
                      disabled={disablePrintPDF}
                    />
                  </div>
                : <div className='cluster cluster-outer' style={{justifyContent: 'center'}}>
                    <p>Loading document</p>
                    <BeatLoader className='react-spinner' loading={true} aria-label="Loading Document" data-testid="loader" />
                  </div>
              }
            </>
          : <>
              <p>Generating consignment</p>
              <BeatLoader className='react-spinner' loading={true} aria-label="Loading Consignment" data-testid="loader" />
            </>
        }
        {/* {error && <p className="error-msg">{error}</p>} */}
      </div>
    </section>
  )
}


export default PageConsignmentDownload;
export { init, reducer };