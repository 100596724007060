/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  PageSchedule.js
Author:    John D. Kohl
Version:   0.19
Description:
The Schedule page for the New Pickup workflow.

History:
0.19  28-08-23 JDK   LD0012210 Initialise new pickups with customer specific defaults.
0.18  26-05-23 DBL   LD0012112 Ready At now sets an error message for invalid dates and times
0.17  09-11-22 JRB   LD0011685 Added a check to validation to skip empty strings (safari tried to validate empty.)
0.16  08-11-22 DBL   LD0011685 Added ReadAtDate and ReadyAtTime
0.15  04-11-22 DBL   Removed dateCreated as it is never used
0.14  23-09-22 ADM   Added (HH:MM:AM/PM) to entire the business hours key
0.13  14-09-22 DBL   Changed time to not include seconds and to pre fill ready at time
0.12  16-08-22 JRB   Changed loadstore to force set the closedAfter value for openFrom. This fixes an issue on loading from session storage.
0.11  16-08-22 JRB   Fixed the issue with openFrom and closedAfter using separate validation and doubling up on errors.
0.02  28-06-22 DBL   Moved the pickup message into the new warning properity of CardForm
0.01	23-03-22 JDK   Created.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import CardForm from '../Common/CardForm';
import useAuthenticate from '../Session/useAuthenticate';
import { dateConstruct, timePattern, time2Seconds, displayPkpMsg } from '../Common/utils';

class init {
  constructor (defaultOpen, defaultClose) {
    this.when = {value: '', error: null};
    this.readyAt = {value: '', error: null};      // ISO String
    this._readyAtDate = {value: '', error: null}; // YYYY-MM-DD
    this._readyAtTime = {value: '', error: null}; // HH:MM
    this.openFrom = {value: defaultOpen ? defaultOpen : '', error: null};     // HH:MM
    this.closedAfter = {value: defaultClose ? defaultClose : '', error: null};  // HH:MM
    this.bookedBy = {value: '', error: null};
    this.scheduleMsg = {value: '', error: null};
  }
}

// Receives the store property from the NewQuote store and the action
function reducer(store, action) {
  // console.log('Schedule Reducer: store, action', store, action);
  let error = null;
  let newValue = action.value;
  let timeMsg = {scheduleMsg: {value: '', error: null}};

  switch (action.name) {
    case 'readyAt': {
      if (newValue === 'NOW') {
        return {
          _readyAtDate: { value: '', error: null },
          _readyAtTime: { value: '', error: null },
          readyAt: { value: 'NOW', error: null },
          when: { value: 'NOW', error: null }
        }
      }
      else if (newValue !== '') {
        const date = new Date(newValue) // Allow the date object to validate the string
        return {
          _readyAtDate: { value: dateConstruct('date', date), error: null },
          _readyAtTime: { value: dateConstruct('time', date), error: null },
          when: { value: 'later', error: null },
          readyAt: {
            value: date.toISOString(),
            error: date.getTime() <= (Date.now()-(1000*60*5)) ? 'Cannot be ready in the past' : null // now + five minutes grace.
          }
        }
      }
      else return {} // loading from storage and not set.
    }

    case '_readyAtDate': {
      // newValue is in YYYY-MM-DD
      const dateParts = newValue.split('-', 3)
      if (dateParts.length !== 3) {
        newValue = ''
        error = 'Invalid ready at date'
      } else {
        const year = dateParts[0]
        const month = dateParts[1] - 1
        const day = dateParts[2]
        
        let date = new Date(store.readyAt.value)
        date.setFullYear(year, month, day) // Allow the date object to validate the string
        if (date.valueOf())
          newValue = date.toISOString()
        else {
          newValue = ''
          error = 'Invalid ready at date'
        }
      }

      action.name = 'readyAt'
      // the _readyAtDate field will be correctly populated at the end of the reducer
    } break;

    case '_readyAtTime':
      const hours = newValue.substr(0, 2)
      const minutes = newValue.substr(newValue.includes(':')?3:2, 2) // newValue is in HHMM or HH:MM

      let date = new Date(store.readyAt.value)
      date.setHours(hours, minutes, 0, 0)
      if (date.valueOf())
        newValue = date.toISOString()
      else {
        newValue = ''
        error = 'Invalid ready at time'
      }

      action.name = 'readyAt'
      // the _readyAtTime field will be correctly populated at the end of the reducer
      break;

    case 'openFrom': {
      // newValue is in HHMM or HH:MM
      if (!newValue || newValue === '')
        break;

      if (!newValue.includes(':'))
        newValue =`${newValue.slice(0,2)}:${newValue.slice(2,4)}`;

      let openHrs = time2Seconds(newValue);
      let closeHrs = time2Seconds(store.closedAfter.value);
      if (store.closedAfter.error === null) error = validateOpeningHrs(openHrs, closeHrs); // Only set error if the other time field does not have an error
    } break;

    case 'closedAfter': {
      // newValue is in HHMM or HH:MM
      if (!newValue || newValue === '')
        break;

      if (!newValue.includes(':'))
        newValue =`${newValue.slice(0,2)}:${newValue.slice(2,4)}`;

      let openHrs = time2Seconds(store.openFrom.value);
      let closeHrs = time2Seconds(newValue);
      if (store.openFrom.error === null) error = validateOpeningHrs(openHrs, closeHrs); // Only set error if the other time field does not have an error
    } break;
    
    case 'bookedBy':
      if (newValue === '') error = '"Freight booked by" field cannot be empty.';
      break;

    case 'disclaimer':
    case 'scheduleMsg':
    case 'when':
      break;

    case 'loadStore':
      return {
        ...(new init()), // blank store
        ...reducer(store, {name: 'when', value: newValue.when}), // if from loaction this is not set and will be overwritten from readyAt
        ...reducer({...store, closedAfter: {value: newValue.closedAfter, error: null} }, {name: 'openFrom', value: newValue.openFrom}), // Force place the value. We only need 1 to have an error so only change this one.
        ...reducer(store, {name: 'closedAfter', value: newValue.closedAfter}),
        ...reducer(store, {name: 'bookedBy', value: newValue.bookedBy}),
        ...reducer(store, {name: 'scheduleMsg', value: newValue.scheduleMsg}),
        ...reducer(store, {name: 'readyAt', value: newValue.readyAt})
      }

    default: {
      throw new Error(`Schedule Pickup Reducer (${action.name}) is not supported`);
    }
  }

  switch (action.name) {
    case 'readyAt':
      // either _readyAtDate or _readyAtTime was changed
      if (store.when.value !== 'NOW' && Date.parse(newValue) <= (Date.now()-(1000*60*5))) // five minutes grace for time <= NOW
        error = 'Cannot be ready in the past';
      else {
        // midnight 14 days from today
        let future = new Date();
        future.setHours(0, 0, 0, 0);
        future.setDate(future.getDate() + 15)
        if (new Date(newValue) > future)
          error = 'Pickups cannot be scheduled beyond 2 weeks'
      }
      
      timeMsg = displayPkpMsg(newValue, store.closedAfter.value, error);
      return {
        readyAt: { value: newValue, error: error },
        _readyAtDate: { value: dateConstruct('date', newValue), error: null },
        _readyAtTime: { value: dateConstruct('time', newValue), error: null },
        ...timeMsg
      }
    case 'closedAfter':
      timeMsg = displayPkpMsg(store.readyAt.value, newValue, error);
      break;
    default:
      timeMsg = displayPkpMsg(store.readyAt.value, store.closedAfter.value, error);
  }
  
  return { [action.name]: { value: newValue, error: error }, ...timeMsg };
}


function PageSchedule({store, dispatcher, controls}) {
  const { loggedIn, customer } = useAuthenticate();
  const [ error, setError ] = useState(null);

  useEffect(() => {
    if (store.readyAt.value && !store.when.value) {
      dispatcher({page: 'Schedule', name: 'when', value: 'later'})
    }
    let error = [];
    ['when','readyAt','openFrom','closedAfter','bookedBy','scheduleMsg']
      .forEach(key => {
        if(store[key].error) {
          error.push(store[key].error);
          error.push(<br />);
        }
    });
    if (error.length === 0) error = null;
    setError(error);
  }, [store, dispatcher]);

  useEffect(() => {
    if (loggedIn) {
      if (store.when.value === 'later') // on load, validate the readyAt
        dispatcher({page: 'Schedule', name: 'readyAt', value: store.readyAt.value});
      if (store.bookedBy.value === '') 
        dispatcher({page: 'Schedule', name: 'bookedBy', value: customer.name ? customer.name : ''});
    }
  }, [loggedIn]) // eslint-disable-line

  const handleInput = (e) => {
    dispatcher({page: 'Schedule', name: e.target.id, value: e.target.value});
    // Check if we changed a linked field and if so validate the other field.
    if (e.target.id === 'openFrom') dispatcher({page: 'Schedule', name: 'closedAfter', value: store.closedAfter.value});
    if (e.target.id === 'closedAfter') dispatcher({page: 'Schedule', name: 'openFrom', value: store.openFrom.value});
  }

  return (
    loggedIn &&
    <CardForm
      title='Schedule Your Pickup'
      className="workflow"
      controls={{ ...controls, next: {...controls.next, enable: (store.when.value === '' || error) ? false : true} }}
      store={store}
      error={error}
      warning={store.scheduleMsg.value}
    >
      <section className='stack'>
        <div className="label-input__align">
          <div className='label-input__align'>
            <input type="radio" name="sch-toggle"
              onChange={() => {
                dispatcher({page: 'Schedule', name: 'when', value: 'NOW'});
                dispatcher({page: 'Schedule', name: 'readyAt', value: 'NOW'});
              }}
              checked={store.when.value === 'NOW'}
              required
            />
            <label htmlFor='yes'>Ready Now</label>
            <input type="radio" name="sch-toggle"
              onChange={() => {
                let now = new Date(Date.now()); now.setSeconds(0, 0); // 1 hour from now
                dispatcher({page: 'Schedule', name: 'when', value: 'later'});
                dispatcher({page: 'Schedule', name: 'readyAt', value: now.toISOString()});
              }}
              checked={store.when.value === 'later'}
              required
            />
            <label htmlFor='yes'>Ready At:</label>
          </div>
            {(store.when.value === 'later') && <div className='cluster' style={{width:'100%'}}>
              {/* The datetime-local input type is not supported very well */}
              <input
                type="date"
                id='_readyAtDate'
                onChange={handleInput}
                placeholder='YYYY-MM-DD'
                value={store._readyAtDate.value}
                className = {store.readyAt.error ? 'error-outline ' : ''}
                style={{width:'min(100%, 17rem)'}}
                required
              />
              <input
                type="time"
                id='_readyAtTime'
                onChange={handleInput}
                placeholder='e.g. 10:00'
                pattern={timePattern}
                value={store._readyAtTime.value}
                className = {store.readyAt.error ? 'error-outline' : ''}
                style={{width:'min(100%, 17rem)'}}
                required
              />
            </div>}
        </div>
        {(store.when.value !== '') && <div className="stack">
          <p>Please Enter Your Business Hours (HH:MM AM/PM):</p>
          <div className='cluster'>
            <div className='stack'>
              <div className='cluster'>
                <label htmlFor='openFrom'>Open From:</label>
                <input 
                  type="time" 
                  id='openFrom' 
                  onChange={handleInput} 
                  placeholder='e.g. 08:30' 
                  pattern={timePattern} 
                  value={store.openFrom.value} 
                  className = {(store.openFrom.error || store.closedAfter.error) ? 'error-outline' : ''} 
                  required
                />
              </div>
            </div>
            <div className='stack'>
              <div className='cluster'>
                <label htmlFor='closedAfter'>Closed After:</label>
                <input 
                  type="time" 
                  id='closedAfter' 
                  onChange={handleInput} 
                  placeholder='e.g. 16:30' 
                  pattern={timePattern} 
                  value={store.closedAfter.value} 
                  className = {(store.openFrom.error || store.closedAfter.error) ? 'error-outline' : ''} 
                  required
                />
              </div>
            </div>
          </div>
        </div>}
        <div className="stack">
          <label htmlFor='bookedBy'>Freight Booked By: </label>
          <input type="text" id='bookedBy' onChange={handleInput} value={store.bookedBy.value} maxLength='20' required />
        </div>
      </section>
    </CardForm>
  )
}


function validateOpeningHrs(openHrs, closeHrs) {
  if (openHrs !== null && closeHrs !== null && openHrs >= closeHrs) {
    return ('Opening time cannot be later than closing time.');
  } else {
    return (null);
  }
}

export default PageSchedule;
export { init, reducer };