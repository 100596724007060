/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  AuthContext.js
Author:    Dean B. Leggo
Version:   0.04
Description:
Setup the Authenticate Context and enable the Login Overlay.

History:
0.04  27-03-23 DBL   Clear storage on login and logout
0.03  23-08-22 DBL   Added authModal
0.02  15-03-22 DBL   Check if the reason is a string
0.01  01-03-22 DBL   useCallback on the handlers.
0.00	22-02-22 DBL   Created.
----------------------------------- */

import React, { useContext, useState, useCallback } from 'react';
import * as Storage from './Storage';
import Login from './Login';
import Modal from '../Navigation/Modal';
import { parseOptions } from '../Common/utils';

const AuthContext = React.createContext();

/* Grab the global Authenticate Context
 * The returned object contains the following items:
 * customer - object containing all the customer session data
 * reason - the reason the customer was logged out (displayed to the customer on the login page)
 * onLogin(customer) - pass in the new customer session data
 * onLogout(reason) - pass in the reason for logging out
 * loggedIn - state change when the customer has successfully logged in or out
 * onActivity - update the customer.lastActivity with now
 * authModal - set a sigle modal if the customer is logged in
 */
function useAuthenticate() {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error('useAuthenticate must be used within an Authenticate Provider');
  return context;
}


function Authenticate({children}) {
  const [customer, setCustomer] =  useState(null);
  const [reason, setReason] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [modal, setModal] = useState(null)

  const handleLogin = useCallback((data) => {
    if (data) {
      // convert the array of permissions into an object
      const customer = JSON.parse((JSON.stringify(data.user))); // copy so react can check for changes
      const options = parseOptions(JSON.parse((JSON.stringify(data.options))));
      let permissions = {};
      customer.permissions.forEach((item) => {
        let name = item.name.replace(/[ \r\n]+/g, '');
        delete item.name;
        permissions[name] = item;
      });
      customer.permissions = permissions;

      Storage.clearStore();
      Storage.setSession(customer);
      Storage.setStore('options',options);
      setCustomer(customer);
      setReason(null);
      if (customer.company !== null) setLoggedIn(true)
      else setLoggedIn(false)
    }
  }, []);

  const handleLogout = useCallback((newReason) => {
    setLoggedIn(false);
    setCustomer(null);
    Storage.clearStore();
    if (typeof newReason === 'string' || newReason instanceof String) setReason(newReason);
    else setReason(null);
  }, []);

  const handleActivity = useCallback(() => {
    const time = new Date().toISOString();
    Storage.updateTimestamp(time);
    setCustomer(c => c ? {...c, lastActive: time} : null);
  }, []);

  const handleModal = useCallback((props) => {
    setModal({...props, removeModal: () => setModal(null)})
  }, []);

  // data within the Authenticate Context
  const value = {
    customer,
    loggedIn,
    reason,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onActivity: handleActivity,
    authModal: handleModal
  };

  return (
    <AuthContext.Provider value={value}>
      <Login />
      {loggedIn && modal && <Modal {...modal} />}
      <div style={{display: customer && customer.company !== null ? '' : 'none' }}>
        {children}
      </div>
    </AuthContext.Provider>
  );
}

export default useAuthenticate;
export { Authenticate, AuthContext };