/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename: Modal.js
Author:    Dean B. Leggo
Version:   0.00
Description:
Display a modal on the screen locking everything else

History:
0.01  23-08-22 DBL   Changed to attach to existing div
0.00	15-08-22 DBL   Created.
----------------------------------- */
import React, { useCallback, useEffect, useRef, useContext, useState } from 'react';
import { createPortal } from 'react-dom';

const ModalContext = React.createContext();

/*
 * Modal takes 3 variables;
 * - title - to be placed in the header.
 * - description - the question to ask the customer.
 * - yes, no - buttons, both optional but need at least one.
 * - removeModal - optionalfunction to call on exit.
 * 
 * Each button has the following 2 variables
 * name - name to display on the button (optional, defaults to Yes or No)
 * call - the onClick function to call back (mandatory)
 * 
 */
function Modal(props) {
  const mount = document.getElementById("modal-root")
  const context = useContext(ModalContext)
  if (context === undefined)
    throw new Error('Modal must be used within Modality')

  useEffect(() => {
    context.updateModal()
    return () => {context.updateModal()}
  })

  return createPortal(<RealModal {...props} />, mount)
}


function RealModal({title, description, yes, no, removeModal}) {
  const confirmTitleRef = useRef(null);
  const yesRef = useRef(null);
  const noRef = useRef(null);

  if (!yes && !no) throw Error ('Need at least one button');
  if (yes && !yes.hasOwnProperty('call')) throw Error ('The yes button needs a call');
  if (no && !no.hasOwnProperty('call')) throw Error ('The no button needs a call');

  const processKeys = useCallback(e => {
    switch (e.keyCode) {
      case 9: // TAB - stay inside the modal by looping around
        e.preventDefault()
        if (no && yes) {
          if (e.originalTarget.id === noRef.current.id) yesRef.current.focus()
          else noRef.current.focus()
        }
        break

      case 27: // ESCAPE - leave the modal
        e.preventDefault()
        if (removeModal) removeModal()
        if (no) no.call()
        else yes.call()
        break

      default:
        // do nothing
    }
  }, [ yes, no, yesRef, noRef, removeModal ])

  useEffect(() => confirmTitleRef.current.focus(), [confirmTitleRef])

  return ( 
    <div className="modal contain card stack"
      aria-modal="true" role="alertdialog"
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      onKeyDown={processKeys}
    >
      <h2 tabIndex='-1' ref={confirmTitleRef} id='modal-title'>
        {title}
      </h2>
      <p id='modal-description'>{description}</p>
      <div className="cluster cluster-outer accent">
        { no &&
          <button type='button' className='btn' id='modal-no'
            ref={noRef}
            onClick={() => {
              if (removeModal) removeModal()
              no.call()
            }}
          >
            {no.hasOwnProperty('name') ? no.name : 'No'}
          </button>
        }
        <div className={no ? 'cluster cluster-no-wrap' : 'cluster cluster-no-wrap margin-inline-start-auto'}>
          { yes &&
            <button type='button' className='btn' id='modal-yes'
              ref={yesRef}
              onClick={() => {
                if (removeModal) removeModal()
                yes.call()
              }}
            >
              {yes.hasOwnProperty('name') ? yes.name : 'Yes'}
            </button>
          }
        </div>
      </div>
    </div>
  );
}


// Setup the modal context
// Only display the newest modal and chain the other modals underneath.
function Modality({children}) {
  const [ divs, setDivs ] = useState(false)

  const updateModal = useCallback(() => {
    const newDivs = document.querySelectorAll('#modal-root div').length !== 0
    
    if (divs !== newDivs) {
      setDivs(newDivs)
      if (newDivs) {
        document.querySelector("body").classList.add("lockscroll")
        document.getElementById("modal-root").classList.add("overlay")
      }
      else {
        document.querySelector("body").classList.remove("lockscroll")
        document.getElementById("modal-root").classList.remove("overlay")
      }
    }
  }, [divs])

  return (
    <ModalContext.Provider value={{updateModal}}>
      <div
        aria-hidden={divs}
        inert={divs ? 'true' : undefined}
      >
        {children}
      </div> 
    </ModalContext.Provider>
  )
}

export default Modal
export { Modality }