/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  PageServices.js
Author:    Dean B. Leggo
Version:   0.09
Description:
The Services page for the New Quote workflow.

History:
0.09  27-06-23 DBL   (ld0012127) Created loadRequired because the old technique was toggling the option
0.08  16-06-23 DBL   (ld0012127) Added support for pickupAllowed and deliveryAllowed
0.07  29-03-23 DBL   (ld0012009) Allow the two suburb lists be selected by depotFrom and depotTo
0.06  06-01-23 JRB   (ld0011868) Removed "and/or loading"
0.05  15-11-22 JRB   (ld0011671) Added calculations for forklift buttons to try and use store to figure it out instead of the reducer.
0.04  31-10-22 JRB   (ld0011671) Disable forklift options when we don't have a forklift
0.03  06-10-22 PWM   (ld0011662) Removed placeholder text.  GBT advises this is not required.
0.02  19-04-22 ADM   Updating class names to match css refactor
0.01  15-03-22 ADM   Updated the phrasing on the page. Swapped in the forklift & dolly icon. Added fl-row to the row of buttons.
0.00	08-03-22 DBL   Created.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faD } from '@fortawesome/free-regular-svg-icons';
import { faBan, faForklift, faDolly } from '@fortawesome/pro-regular-svg-icons';
import CardForm, { CardButton } from '../Common/CardForm';

class init {
  constructor () {
    this.pickupRequired = {value: 0, error: null};
    this.deliveryRequired = {value: 0, error: null};
  }
}

function reducer(store, action) {
  // console.log('Services Reducer: store, action', store, action);
  let error = null;
  let newValue = action.value;

  // validate the action
  switch (action.name) {
    case 'pickupRequired':
    case 'deliveryRequired':
      store = action.name === 'pickupRequired' ? store.pickupRequired : store.deliveryRequired; // We are now passing in the full store
      if (newValue === 3 && store.value === 3) newValue = 2; // deselecting forklift
      else if (newValue === 2 && store.value === 3) newValue = 0; // deselecting required on forklift
      else if (newValue === store.value ) newValue = 0; // deselecting either of the other two
      break;

    case 'loadStore':
      if (newValue.pickupRequired < 0 || newValue.pickupRequired > 3) newValue.pickupRequired = 0;
      if (newValue.deliveryRequired < 0 || newValue.deliveryRequired > 3) newValue.deliveryRequired = 0;
      return {
        pickupRequired: {value: newValue.pickupRequired, error: null},
        deliveryRequired: {value: newValue.deliveryRequired, error: null}
      }

    case 'loadRequired':
      // (ld0012127) If pickup/delivery is not allowed select the only option
      return {
        pickupRequired: newValue.pickupAllowed
          ? store.pickupRequired
          : {value: 1, error: null},
        deliveryRequired: newValue.deliveryAllowed
          ? store.deliveryRequired
          : {value: 1, error: null}
      }

    default:
      throw new Error(`Services Reducer (${action.name}) is not supported`);
  };

  return { [action.name]: {value: newValue, error: error} };
}


function PageServices({store, dispatcher, controls, options}) {
  // event handler for onChange checkbox
  const handleChange = value => e => dispatcher({page: 'Services', name: e.target.name, value});
  const [dlFork, setDlFork] = useState(false);
  const [pkFork, setPkFork] = useState(false);
  const [dlPick, setdlPick] = useState(false);
  const [pkPick, setpkPick] = useState(false);

  // forklift selection is independent of rates. See #12040 & #11487 specification.
  useEffect(() => {
    if (options) {
      options.forkliftPickup && setPkFork(options.forkliftPickup); 
      options.forkliftDelivery && setDlFork(options.forkliftDelivery);

      // Check if we are allowed pickup or delivery for the selected rate
      if (store.freightList.value.length > 0
        &&  store._rates.value instanceof Array
        && store._rates.value.length > 0
      ) {
        // find the specific freight rate from the list of rates for the destination pair
        const rate = store._rates.value.find(r => r.freight === store.freightList.value[0].type.value)
        setpkPick(rate.pickupAllowed)
        setdlPick(rate.deliveryAllowed)

        dispatcher({page: 'Services', name: 'loadRequired', value: {
          pickupAllowed: rate.pickupAllowed,
          deliveryAllowed: rate.deliveryAllowed
        }})
      }
    }
  }, [options, store._rates.value, store.freightList.value, dispatcher])

  return (
    <CardForm title='Additional Services' className="workflow"
      controls={{ ...controls, next: {...controls.next, enable: store.pickupRequired.value !== 0 && store.deliveryRequired.value !== 0 ? true : false} }}
    >
      <h3>Pickup</h3>
      <p>Does your freight require a pickup?</p>
      <div className='cluster cluster-outer cluster-no-wrap'>
        <CardButton
          id='service_pickup_0'
          name='pickupRequired' className='stack' onChange={handleChange(1)}
          title='No Pickup required'
          icon={<FontAwesomeIcon icon={faBan} size="3x" />}
          checked={store.pickupRequired.value === 1}
        />
        <CardButton
          id='service_pickup_1'
          name='pickupRequired' className='stack' onChange={handleChange(2)}
          title='Pickup required'
          icon={<FontAwesomeIcon icon={faDolly} size="3x" />}
          checked={(store.pickupRequired.value === 2) || (store.pickupRequired.value === 3)}
          disabled={!pkPick}
        />
        <CardButton
          id='service_pickup_2'
          name='pickupRequired' className='stack' onChange={handleChange(3)}
          title='Forklift required'
          icon={<FontAwesomeIcon icon={faForklift} size="3x" />}
          checked={store.pickupRequired.value === 3}
          disabled={!pkPick || !pkFork}
        />
      </div>

      <h3 className='accent'>Delivery</h3>
      <p>Does your freight require delivery?</p>
      <div className='cluster cluster-outer cluster-no-wrap'>
        <CardButton
          id='service_delivery_0'
          name='deliveryRequired' className='stack' onChange={handleChange(1)}
          title='No Delivery required'
          icon={<FontAwesomeIcon icon={faBan} size="3x" />}
          checked={store.deliveryRequired.value === 1}
        />
        <CardButton
          id='service_delivery_1'
          name='deliveryRequired' className='stack' onChange={handleChange(2)}
          title='Delivery required'
          icon={<FontAwesomeIcon icon={faDolly} size="3x" />}
          checked={(store.deliveryRequired.value === 2) || (store.deliveryRequired.value === 3)}
          disabled={!dlPick}
        />
        <CardButton
          id='service_delivery_2'
          name='deliveryRequired' className='stack' onChange={handleChange(3)}
          title='Forklift required'
          icon={<FontAwesomeIcon icon={faForklift} size="3x" />}
          checked={store.deliveryRequired.value === 3}
          disabled={!dlPick || !dlFork}
        />
      </div>
      <h3>Services may incur additional charges</h3>
    </CardForm>
  );
}

export default PageServices;
export { init, reducer };