/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  Pickups.js
Author:    Dean B. Leggo, John D. Kohl
Version:   0.11
Description:
The existing pickups page.

History:
0.11  28-08-23 JDK   (ld0012210) Added loading function for retrieving customer specific defaults.
0.10  15-05-23 JRB   (ld0012035) Added storeLoaded check that now calls loadAddresses. Commented out excessive console logs.
0.09  12-05-23 DBL   (ld0012035) Changed to support Multiple destinations
0.08  29-03-23 DBL   (ld0012009) options suburbs now makes the from depots available
0.07  18-01-23 JDK   (ld0011885) Added location pathname as condition for the 'reload' modal. Changed initial state of 'progress' to 1.
0.06  16-01-23 JDK   (ld0011887) Added 'progress' value to track which page the user is on. Altered loading function to return user to the last active page.
0.05  02-11-22 JRB   (ld0011807) Added available to suburbs list.
0.04  25-11-22 DBL   (ld0011792) Go home and not back
0.03  11-11-22 DBL   Moved loading depot to PageDetails
0.02  13-10-22 JRB   Added Review to loadstore
0.01  23-08322 DBL   Change setModel to use authModal
0.00	25-02-22 DBL   Created.
----------------------------------- */

import React, { useEffect, useState, useReducer } from 'react';
import { useLocation, useNavigate as useRouterNavigate } from 'react-router-dom';
import useRest from '../Session/useRest';
import useNavigate from '../Navigation/useNavigate';
import useAuthenticate from '../Session/useAuthenticate';
import { getStore, removeStore, setStore } from '../Session/Storage';
import { compactStore, mergeDestinations } from '../Common/utils';
import PageAccounts, * as Accounts from './PageAccounts';
import PageDetails, * as Details from './PageDetails';
import PageFreight, * as Freight from './PageFreight';
import PageDG, * as DG from './PageDG';
import PageSchedule, * as Schedule from './PageSchedule';
import PageInstructions, * as Instructions from './PageInstructions';
import PageReview, * as Review from './PageReview';
import PageConsignment, * as Consignment from './PageConsignment';
import { freightCardValidation } from '../Configuration/Config';

const menu = [{name: 'Accounts', click: true},
              {name: 'Details', click: false},
              {name: 'Enter Freight', click: false},
              {name: 'Dangerous Goods', click: false},
              {name: 'Schedule Pickup', click: false},
              {name: 'Special Instructions', click: false},
              {name: 'Review Pickup', click: false},
              {name: 'Create Consignment', click: false}];         

const getOptions = () => {
  let options = getStore('options');
  return (options !== null && Object.keys(options).length) ? options : null;
}

class init {
  constructor(options) {
    let progress = { progress: 1 };
    let accounts = new Accounts.init();
    let details = new Details.init(options ? options.customerDefaults.defaultSender : null);
    let freight = new Freight.init();
    let dg = new DG.init();
    let schedule = new Schedule.init(options ? options.customerDefaults.defaultOpen : null, options ? options.customerDefaults.defaultClose : null);
    let instructions = new Instructions.init(options ? options.customerDefaults.defaultInstructions : null);
    let consignment = new Consignment.init();
    let review = new Review.init();
    return {...progress,...accounts, ...details, ...freight, ...dg, ...schedule, ...instructions, ...consignment, ...review};
  }
}

// When you call dispatcher with your action, it'll call reducer.
// The reducer is a function that'll take the current store and action,
// and return the newly updated store.
// Action - page - the page the dispatcher was called from
//          name - the store property to update
//         value - the new property value, can also be a nested action!
function reducer(store, action) {
  // console.log('New Quote Reducer: store, action'); console.log(store); console.log(action);
  if (action.name === 'progress') return {...store, progress: action.value};

  // pass down the entry to update and the action, adding the updated entry into the store
  switch (action.page) {
    case 'Accounts':
      return {...store, ...Accounts.reducer(store, action)};
    case 'Details':
      return {...store, ...Details.reducer(store, action)};
    case 'Freight':
      return {...store, ...Freight.reducer(store, action)};
    case 'DG':
      return {...store, ...DG.reducer(store, action)};
    case 'Schedule':
      return {...store, ...Schedule.reducer(store, action)}; // need to access multiple elements.
    case 'Instructions':
      return {...store, ...Instructions.reducer(store, action)};
    case 'Consignment':
      return {...store, ...Consignment.reducer(store, action)};
    case 'Review':
      return {...store, ...Review.reducer(store, action)};
    case 'loadStore':
      return { // load an existing pickup or load from the local storage
        progress: action.progress,
        ...Accounts.reducer(store, action),
        ...Details.reducer(store, action),
        ...Freight.reducer(store, action),
        ...DG.reducer(store, action),
        ...Schedule.reducer(store, action),
        ...Instructions.reducer(store, action),
        ...Consignment.reducer(store, action),
        ...Review.reducer(store, action)
      }
    
    case 'reset':
      return new init(getOptions())
    
    default:
      throw new Error(`New Pickup Page (${action.page}) is not supported`);
  }
}

function NewPickup() {
  const { navState, changeType, changeState } = useNavigate();
  const { loggedIn, customer, authModal } = useAuthenticate();
  const navigate = useRouterNavigate();
  const location = useLocation();
  const [ store, dispatcher ] = useReducer(reducer, new init(getOptions()));
  const [ fetchOptions, options, fetchOptionsError ] = useRest('GET', 'pickups/new');
  const [ fetchPickup, pickupData, pickupError ] = useRest('GET', 'pickups/edit');
  const [ myOptions, setMyOptions ] = useState({});
  const [ storeLoaded, setStoreLoaded ] = useState(false); // can this join isLoaded


  // useEffect(() => { console.log('Store:', store) }, [store]); // debugging
  // useEffect(() => { console.log('options:', options) }, [options]); // debugging
  // useEffect(() => { console.log('myOptions:', (myOptions) }, [myOptions]); // debugging

  // on first load, set the navigation type and initial state
  useEffect(() => {
    if (loggedIn) {
      if (location.state !== null &&
        location.state.hasOwnProperty('pickup')
      ) { // we were passed an existing pick to view
        if (customer.permissions.Pickups.edit === true && location.state.pickup.status === 0)
          loadExistingPickup(location.state.pickup, true);
        else if (customer.permissions.Pickups.view === true)
          loadExistingPickup(location.state.pickup, false);
        else
          permissionDenied('You do not have access to view pickups');
      }
      else { // new pickup
        changeType('stepper', {stepArray: menu, stepCurrent: 1});
        if (customer.permissions.Pickups.add === true) {
          fetchOptions();
          let loadedStore = getStore(`Pickup-${customer.company}`);
          if (loadedStore
            && loadedStore.status === 0
            && JSON.stringify(compactStore(new init())) !== JSON.stringify(loadedStore)
          )
            askAboutDraft(loadedStore);
          else setStoreLoaded(true);
        }
        else
          permissionDenied('You do not have access to create a pickup.');
      }
    }
  }, [loggedIn]); // eslint-disable-line

  const loadExistingPickup = (pickup, canEdit) => {
    const goto = (pickup && pickup.progress) ? pickup.progress : 7;
    const newMenu = menu.map((item, index) =>
      canEdit && index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
    );
    changeType('stepper', { stepArray: newMenu, stepCurrent: goto });
    fetchOptions({pickup: pickup.pickupNumber});

    let loadedStore = getStore(`Pickup-${customer.company}`);
    if (loadedStore && canEdit
      && loadedStore.pickupNumber === pickup.pickupNumber
      && loadedStore.status === 0
      && JSON.stringify(compactStore(new init(getOptions()))) !== JSON.stringify(loadedStore)
    )
        askAboutDraft(loadedStore, pickup);
    else {
      removeStore(`Pickup-${customer.company}`)
      dispatchPickup(pickup)
      setStoreLoaded(pickup);
    }
  };

  const askAboutDraft = (loadedStore, existingPickup) => {
    setStoreLoaded(loadedStore);
    if (existingPickup || (loadedStore.pickupNumber && location.pathname !== '/pickup/new')) {
      const pickupNumber = existingPickup ? existingPickup.pickupNumber : loadedStore.pickupNumber;
      authModal({
        title: 'Pickup in Progress',
        description: `Pickup ${pickupNumber} has a local copy, would you like to pickup where you left off?`,
        yes: {name: 'Yes, open local copy', call: () => {
          dispatchPickup(loadedStore);
          const goto = loadedStore.progress ? loadedStore.progress : 7;
          changeState({ ...navState, stepCurrent: goto, stepArray: menu});
        }},
        no: {name: 'No, reload pickup', call: () => {
          removeStore(`Pickup-${customer.company}`); // delete the existing store object
          fetchPickup({pickupNumber: pickupNumber}); // get the pickup record from Omnis to reload
        }}
      });
    }
    else {
      authModal({
        title: 'Pickup in Progress',
        description: 'This account has an outstanding pickup, would you like to complete the pickup?',
        yes: {name: 'Yes', call: () => {
          dispatchPickup(loadedStore);
          const goto = loadedStore.progress ? loadedStore.progress : 1;
          changeState({ ...navState, stepCurrent: goto, stepArray: menu});
        }},
        no: {name: 'No, new pickup', call: () => {
          removeStore(`Pickup-${customer.company}`);
          dispatchPickup();
        }}
      });
    }
  };

  const permissionDenied = (description) => {
    authModal({
      title: 'Permission Denied',
      description: description,
      yes: {name: 'Go Back', call: () =>  navigate('/')} // go home (ld0011792)
    });
  };

  useEffect(() => {
    if ((fetchOptionsError || pickupError) && location.state !== null && location.state.hasOwnProperty('pickup'))
      permissionDenied(`Pickup ${location.state.pickup ? location.state.pickup.pickupNumber : 'none'} does not exist`)
  }, [fetchOptionsError, pickupError, location.state]) // eslint-disable-line

  // modify the options when they arrive by parsing the options into JSON
  useEffect(() => {
    if (options) {
      const newOptions = {
        ...options,
        suburbs: mergeDestinations(options.suburbs, options.depots)
      };
      setMyOptions(newOptions);
      if (storeLoaded && storeLoaded.sender && storeLoaded.receiver) //If we have the object and the object has sender and receiver.
      // console.log(storeLoaded, newOptions)
        dispatcher({page: 'Details', name: 'loadAddresses', value: {
          sender: newOptions.suburbs.find(s => s.value === `${storeLoaded.sender.suburb}, ${storeLoaded.sender.state}, ${storeLoaded.sender.postCode}`),
          receiver: newOptions.suburbs.find(s => s.value === `${storeLoaded.receiver.suburb}, ${storeLoaded.receiver.state}, ${storeLoaded.receiver.postCode}`),
          depots: newOptions.depots
      }})
    }
  }, [options, storeLoaded])

  const dispatchPickup = pickup => {
    if (pickup) dispatcher({page: 'loadStore', name: 'loadStore', value: { ...pickup }, validation: freightCardValidation.default});
    else dispatcher({page: 'reset', name: 'reset', value: {}, validation: freightCardValidation.default});
    setStoreLoaded(pickup);
  };

  // When reloading a pickup, we get data back from Omnis; once we do, send dipatch that data and flag the store as loaded.
  useEffect(() => {
    if (pickupData && !pickupError) {
      dispatchPickup(pickupData);
      setStoreLoaded(pickupData);
    }
  }, [pickupData, pickupError])

  // Save the store to local storage whenever there is a change
  useEffect(() => {
    if (loggedIn && storeLoaded) { // don't override the storage with the empty initialised store
      setStore(`Pickup-${customer.company}`, compactStore(store))
    }
  }, [store, storeLoaded, loggedIn, customer]);

  // default previous click
  const previousClick = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent - 1;
      const newMenu = navState.stepArray.map((item, index) =>
        index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  };

  // default next click
  const nextClick = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent + 1;
      const newMenu = navState.stepArray.map((item, index) =>
        index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  };

  // send all controls to each page and it can decide what to use
  let controls = {next: {call: nextClick}, previous: {call: previousClick}};

  // Switch the card forms
  // on a fresh load nav will have no state on the first render. After the first render
  // the useEffect above will be called and the navState will be set.
  if (navState && myOptions) {
    if (store && (navState.stepCurrent !== store.progress)) dispatcher({name:'progress', value: navState.stepCurrent})
    switch (navState.stepCurrent) {
      case 1: return (
        <div className='hideOverflow-x'>
          <PageAccounts store={store} dispatcher={dispatcher} controls={controls} />
        </div>);
      case 2: return (
        <div className='hideOverflow-x'>
          <PageDetails store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
        </div>);
      case 3: return (
        <div className='hideOverflow-x'>
          <PageFreight store={store} dispatcher={dispatcher} controls={controls} options={myOptions} validation={freightCardValidation.default}/>
        </div>);
      case 4: return (
        <div className='hideOverflow-x'>
          <PageDG store={store} dispatcher={dispatcher} controls={controls} />
        </div>);
      case 5: return (
        <div className='hideOverflow-x'>
          <PageSchedule store={store} dispatcher={dispatcher} controls={controls} />
        </div>);
      case 6: return (
        <div className='hideOverflow-x'>
          <PageInstructions store={store} dispatcher={dispatcher} controls={controls} />
        </div>);
      case 7: return (
        <div className='hideOverflow-x'>
          <PageReview store={store} controls={controls} />
        </div>);
      case 8: return (
        <div className='hideOverflow-x'>
          <PageConsignment store={store} dispatcher={dispatcher} controls={controls} isPickup={true} />
        </div>);
      default: return null;
    }
  }
  else
    return null;
}

export default NewPickup;