/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  ExistinDropFreight.js
Author:    Jordan R. Bock
Version:   0.01
Description:
The existing drop freight in page.

History:
0.00 17-01-24 JRB  Created.
----------------------------------- */

import React, { useEffect, useState }  from 'react';
import useNavigate from './Navigation/useNavigate';
import CardForm from './Common/CardForm';
import useRest from './Session/useRest';
import useAuthenticate from './Session/useAuthenticate';
import { useNavigate as useRouterNavigate } from 'react-router-dom';

function ExistingDropFreight() {
  const { changeType } = useNavigate();
  const { loggedIn } = useAuthenticate();
  const [ fetch, data, fetchError ] = useRest('GET', 'directdropfreight/existing');
  // const [ fetchQuote, quoteData, quoteError, quoteLoading ] = useRest('GET', 'quote');
  // const [ fetchPickup, pickupData, pickupError, pickupLoading ] = useRest('GET', 'pickups/edit');
  const [fetchDropFreight, dropFreightData, dropFreightError, dropFreightLoading] = useRest ('GET', 'directdropfreight/edit');
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const navigate = useRouterNavigate();

  let curDate = new Date();
  let preDate = new Date();
  preDate.setDate(curDate.getDate() - 30); // Need to calculate the date diff before the HTML block
  
  // on first load, set the navigation type and initial state
  useEffect(() => { 
    if(loggedIn) {
      changeType('default', 'Existing Drop Freight');
      fetch();
    }
  }, [loggedIn]); // eslint-disable-line

  // switch page when the data arrives
  // useEffect(() => { if (quoteData) navigate('./existing', {state: {quotation: quoteData}}) }, [quoteData, navigate]);
  useEffect(() => { if (dropFreightData) navigate('/directdropfreight/existing', {state: {consignment: dropFreightData}}) }, [dropFreightData, navigate]);

  // Display any errors fetching data
  useEffect(() => {
    if (fetchError) setError(fetchError);
    if (dropFreightError) setError(dropFreightError);
    // if (pickupError) setError(pickupError);
  }, [fetchError, dropFreightError]);

  // Lock buttons when something is loading
  useEffect(() => {
    if (dropFreightLoading) setLoading(true)
    else setLoading(false)
  }, [setLoading, dropFreightLoading] )

  const cellButton = (item, link) => (<>
    {item &&
      <button type="button" className='btn__link' disabled={loading} onClick={link}>
        {item}
      </button>
    }
  </>);


  return (
    <CardForm title="Existing Drop Freight" className="margin-block-start no-measure" error={error}>
      { data === null ?
        <p className="h1">Loading Data...</p>
      :
        <div>
          <p className="h3">Consignments from {
            preDate.toLocaleDateString(undefined, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})
          }</p>
          <table>
            <thead>
              <tr>
                <th>Consignment <br/> Number</th>
                <th>Date Created</th>
                <th>Sender <br/> (Suburb)</th>
                <th>Receiver <br/> (Suburb)</th>
                <th>Customer <br/> Reference</th>
                {/* <th>Dead Weight</th>
                <th>Cubic Weight</th> */}
                <th>Document Link</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data && data.quotes.map((quote) => (
                <tr key={quote.quoteNum}>
                  <td>
                    {cellButton(quote.quoteNum, () => fetchDropFreight({consignment: quote.quoteNum}))}
                  </td>
                  <td>{quote.date}</td>
                  <td>{quote.sender}</td>
                  <td>{quote.receiver}</td>
                  <td>{quote.customerRef}</td>
                  {/* <td>{quote.weight}</td>
                  <td>{quote.volumne}</td> */}
                  <td>
                    {quote.scanned === true ? cellButton(quote.quoteNum,
                      () => navigate('/consignment', {state: {consignmentNumber: quote.quoteNum}})
                    ) : quote.quoteNum}
                  </td>
                  <td>{quote.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    </CardForm>
  );
} 

export default ExistingDropFreight;