/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageFreight.js
Author:    Dean B. Leggo
Version:   0.05
Description:
The Check Freight page for the Convert to Pickup workflow.

History:
0.05  17-06-24 JRB   (ld0012770) If quoted inform user that freight may not be modified. Removed the edit button.
0.04  12-05-23 DBL   (ld0012035) Changed to support Multiple destinations
0.03  29-03-23 DBL   (ld0012009) Freight list now filters on depotFrom and depotTo
0.02  22-11-22 DBL   Added useEffect and useMemo to reduce redraws
0.01  01-11-22 JRB   (ld0011671) Added forklift fields to match quotes
0.00	20-04-22 DBL   Created.
----------------------------------- */

import React, { useEffect, useState, useMemo } from 'react';
import CardForm from '../Common/CardForm';
import FreightCards, { FreightSummary } from '../Common/FreightCards';
import * as EditFreight from '../NewPickup/PageFreight';

class init {
  constructor () {
    let freight = new EditFreight.init(); // adds freightList
    return {
      ...freight,
      modifiedFreight: { value: false, error: null }
    }
  }
}

function reducer(store, action) {
  if (action.name === 'modifiedFreight') // remember that the freightList has changed
    return { 'modifiedFreight': {value: action.value, error: null} }
  else
    return EditFreight.reducer(store, action)
}

function PageFreight({store, dispatcher, controls, options, validation}) {
  const [ error, setError ] = useState(null);
  const [ freightTypes, setFreightTypes ] = useState(null);
  const dispatcherAction = useMemo(() => ({ page: 'Freight', name: 'freightList'}), []); // stop FreightCards re-calculating on every redraw

  useEffect(() => {
    let error = []
    if (store.freightList.error) {
      error.push(store.freightList.error)
      error.push(<br />)
    }
    if (store.quoteNumber.value) error.push('Freight may not be modified when quoted.')
    if (error.length === 0) error = null;
    setError(error);
  }, [store, setError]);

  // Stop FreightCards from re-calculating option on ever redraw
  useEffect(()=> {
    if (options && store.receiver._rates.value)
      setFreightTypes({ type: store.receiver._rates.value.sort((a, b) => a.freight > b.freight)})
    else
      setFreightTypes(null)
  }, [store.receiver._rates.value, options])

  return (<>
    {/* {store.modifiedFreight.value ?
      <CardForm title='Edit Freight' className = "workflow"  error={error}
        controls={{ ...controls, next: {...controls.next, enable: (error === null || error.length < 2)} }}
      >
        <FreightCards
          store={store.freightList}
          dispatcher={dispatcher}
          dispatcherAction={dispatcherAction}
          options={freightTypes}
          validation={validation}
        />
      </CardForm>
    : */}
      <CardForm title='Freight Summary' className='workflow' error={error}
      controls={{ ...controls, next: {...controls.next, enable: true} }}
      >
        <FreightSummary
          store={store.freightList}
          title={<p>Please review your freight before proceeding.</p>}
          // edit={() => dispatcher({page: 'Freight', name: 'modifiedFreight', value: true})}
        />
      </CardForm>
    {/* } */}
  </>);
}

export default PageFreight;
export { init, reducer };